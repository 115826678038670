//  MUI components
import Button from '@mui/material/Button';

const CustomButton = ({ label, color = '#14b357', textColor = 'white', onClick, disabled=false, disabledColor="#eeeeee" }) => {
  return (
    <Button
      sx={{
        background: disabled ? disabledColor : color,
        color: textColor,
        fontWeight: 600,
        padding: '8px',
        fontSize: '14px',
        margin: 0,
        width: '100%',
        '&:hover': {
          background: color,
          color: textColor,
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
