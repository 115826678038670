// Default
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

// Services
import { baseURL } from '../../utils/services';

// Custom
import { useLoader } from '../../provider/LoaderContext';
import DetailNavBar from '../../components/DetailNavBar';

// Translation
import Translation from '../../config/Translation';

const TermsPage = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader, isLoading } = useLoader();

  const handleLoad = () => {
    hideLoader(false);
  };

  useEffect(() => {
    showLoader();
  }, []);

  const pageUrl = baseURL + 'Privacy/' + Translation.lang().Language + '/TermsOfUse.html';

  return (
    <div>
      <DetailNavBar label={Translation.lang().w_termOfUse} onClick={() => navigate(-1)} />
      <iframe
        title={Translation.lang().w_termOfUse}
        src={pageUrl}
        width="100%"
        onLoad={handleLoad}
        style={{ display: isLoading ? 'none' : 'flex', height: '100vh' }}
      />
    </div>
  );
};

export default TermsPage;
