import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Stack, Link, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { BorderCard } from '../../components/BorderCard';
import Logo from '../../components/logo/Logo';
import { useLoader } from '../../provider/LoaderContext';
import { fetchGETRequest, fetchPOSTRequest } from '../../utils/services';
import { setAccessToken, setFcmToken, setUser } from '../../redux/features/authSlice';
import Translation from '../../config/Translation';
import { VersionDetails } from '../../components/VersionDetails';
import useAlertDialog from '../../hooks/useAlertDialog';
import CustomButton from '../../forms/button';
import CustomInput from '../../forms/input';
import { SETUP_PAGE } from '../../utils/pageName';


const Login = () => {
  const alertDialog = useAlertDialog();
  const { accessToken, user, fcmToken } = useSelector((state) => state.auth);
  const { showLoader, hideLoader } = useLoader();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(''); // Chavda@1997
  const [emailError, setEmailError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setEmailError(!validateEmail(inputEmail));
  };
  const _loginHandle = async () => {
    showLoader()
    try {
      const data = {
        'grant_type': 'password',
        'username': email,
        'password': password
      }
      const response = await fetchPOSTRequest(`Token`, data);
      if (response.status === 200) {
        const accessToken = response.data.access_token;
        dispatch(setAccessToken(accessToken))
        const resp = await fetchGETRequest(`api/Profile/GetMine`);
        const profileData = resp?.data;
        if (profileData?.id) {
          if (profileData.status == 1) {
            dispatch(setUser(profileData))
            if (profileData.cultureName) {
              if (profileData.cultureName === 'en-US') {
                const language = Translation.getLocale();
                if (language !== Translation.English().Language) {
                  Translation.saveLanguage(Translation.English().Language);
                }
              }
              else if (profileData.cultureName === 'es') {
                const language = Translation.getLocale();
                if (language !== Translation.Spanish().Language) {
                  Translation.saveLanguage(Translation.Spanish().Language);
                }
              }
            }
            if (profileData.isTempPassword == false) {
              navigate('/')
              //navigate(`/${SETUP_PAGE}`)
            }
            else {
              navigate(`/${SETUP_PAGE}`)
            }
          }
          else if (profileData.status == 2) {
            alertDialog({
              title: Translation.lang().w_invalidLoginTitle,
              desc: Translation.lang().w_invalidLoginContent
            })
          }
        }
        else {
          alertDialog({
            title: Translation.lang().w_loginFailed,
            desc: Translation.lang().w_loadProfileFail
          })
        }
      } else {
        alertDialog({
          title: Translation.lang().w_loginFailed,
          desc: Translation.lang().w_usernamePasswordIncorrect
        })
      }
    } catch (error) {
      console.log("🚀 ~ file: LoginPage.js:21 ~ _loginHandle ~ error:", error)

      alertDialog({
        desc: error?.response?.data?.error_description
      })
    }
    hideLoader()
  }

  const handleLogin = () => {
    if (!email || !password) {
      alertDialog({
        title: Translation.lang().w_signin,
        desc: Translation.lang().w_signinRequired
      })
      return;
    }
    _loginHandle();
  };



  return (
    <Container maxWidth="xs" >
      <div>
        <form>
          <Logo sx={{ marginTop: 10 }} />
          <BorderCard>
            <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
              <CustomInput
                type='email'
                value={email}
                onChange={handleEmailChange}
                placeholder={Translation.lang().w_email}
              />
              <CustomInput
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={Translation.lang().w_password}
              />
              {/* <TextField
                size="small"
                fullWidth
                label="Email"
                type='email'
                variant="filled"
                margin="normal"
                value={email}
                error={emailError}
                helperText={emailError ? 'Invalid email format' : ''}
                onChange={handleEmailChange}
              /> */}
              {/* <TextField
                size="small"
                fullWidth
                label="Password"
                variant="filled"
                margin="normal"
                value={password}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>,
                }}
                onChange={(e) => setPassword(e.target.value)}
              /> */}
              <CustomButton onClick={handleLogin} label={Translation.lang().w_signin} />
              <Link href='/forget-password'>
                {Translation.lang().w_forgotPassword}
              </Link>
            </Stack>
          </BorderCard>
        </form>

      </div>
      <VersionDetails />
      {/* <CustomButton onClick={showInstallPrompt} label={"Install App"} /> */}
    </Container>
  );
};

export default Login;
