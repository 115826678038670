// MUI components
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';

// MUI styles
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    background: '#eeeeee',
    width: '100%',
  },
  borderRemove: {
    '& fieldset': { border: 'none' },
  },
  errorBorder: {
    '& fieldset': { borderColor: '#ff4842' },
  },
  input: {
    padding: '12px',
    textAlign:"center",
    '&::placeholder': {
      textAlign: 'center',
      color: '#b7b7b7',
    },
  },
});

const CustomInput = ({ name, disabled, type = 'text', value, onChange, placeholder, sx, formik, inputProps, readOnly=false }) => {
  const classes = useStyles();
  const error = formik?.errors?.[name] && formik?.touched?.[name]
  return (
    <>
      <OutlinedInput
        className={`${classes.root}  ${error ? classes.errorBorder : classes.borderRemove}`}
        classes={{ input: classes.input }}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        id={name}
        type={type}
        value={value}
        defaultValue={value}
        onChange={onChange}
        inputProps={inputProps}
        error={error}
        sx={{ ...sx, display: "flex", justifyContent: "center" }}
        readOnly={readOnly}
      />
      {error && (
        <FormHelperText error sx={{ fontSize: '16px' }}>
          {formik?.errors?.[name]}
        </FormHelperText>
      )}
    </>
  );
};

export default CustomInput;
