import { Shield, Warning } from '@mui/icons-material'
import { Card, CardContent, Dialog, Modal, Stack, Typography } from '@mui/material'
import React from 'react'
import Translation from '../config/Translation'
import CustomButton from '../forms/button'

const AlertNoData = ({ isOpen, closeAlert }) => {
    return (
        <Dialog sx={{ borderRadius: 20 }} open={isOpen}>
            <Stack spacing={2} sx={{ justifyContent: "center", alignItems: "center", paddingBottom: 10, paddingTop: 5, paddingX: 4 }} >
                <Shield sx={{ fontSize: 80 }} color='error' />
                <Typography variant='body1'>
                    {Translation.lang().w_hadnodata}
                </Typography>
                <Typography variant='body2' color={"#666666"}>
                    {Translation.lang().w_dashboardIntroduction}
                </Typography>
                <CustomButton label={"Start Tracking Now"} onClick={closeAlert} />
            </Stack>
        </Dialog>
    )
}

export default AlertNoData