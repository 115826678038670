// ResetPasswordPage.js
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
//Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { TextField, Button, Container, Stack, Typography, InputAdornment, IconButton } from '@mui/material';
import { BorderCard } from '../../components/BorderCard';
import Logo from '../../components/logo/Logo';
import { fetchPOSTRequest } from '../../utils/services';
import { VersionDetails } from '../../components/VersionDetails';
import useAlertDialog from '../../hooks/useAlertDialog';
import Translation from '../../config/Translation';
import { useLoader } from '../../provider/LoaderContext';
import CustomButton from '../../forms/button';
import CustomInput from '../../forms/input';


const ResetPasswordPage = () => {
    const { showLoader, hideLoader } = useLoader();
    const alertDialog = useAlertDialog();
    const navigate = useNavigate();
    const location = useLocation();

    const state = location?.state;

    const _resetPassword = async (values) => {
        showLoader()
        try {
            const body = {
                email: state?.email,
                resetToken: state?.resetToken,
                password: values.confirmPassword,
            }
            const response = await fetchPOSTRequest(`api/ResetPassword/ResetPassword`, body);
            console.log(response,"response");
            if (response.status === 200) {
                const result = response.data;
                if (result && result.isUpdated) {
                    alertDialog({
                        title: Translation.lang().w_updatePassword,
                        desc: Translation.lang().w_passwordUpdated,
                        rightButtonText: Translation.lang().w_ok,
                        rightButtonFunction: () => onSuccess(result)
                    })

                }
                else {
                    alertDialog({
                        title: Translation.lang().w_updatePassword,
                        desc: Translation.lang().w_failed,
                    })

                }
            }
        } catch (error) {
            console.log("🚀 ~ file: ConfirmIdPage.js:52 ~ const_submitAnswer= ~ error:", error.response)

        }
        hideLoader()
    }

    const onSuccess = (data) => {
        navigate('/login');
    }

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('Please enter the new password.')
            .min(5, 'New Password is too short - should be 5 chars minimum.'),
            // .matches(/^[0-9a-zA-Z]+$/, 'New Password can only contain Latin letters.'),
        confirmPassword: Yup.string()
            .required('Please enter the confirm password.')
            .oneOf([Yup.ref('password'), null], 'Confirm Password must match.'),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: _resetPassword
    });

    return (
        <Container maxWidth="xs" >
            <div>
                <form>
                    <Logo sx={{ marginTop: 10 }} />

                    <BorderCard>
                        <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
                            <Typography variant="h6">
                                {Translation.lang().w_updatePassword}
                            </Typography>
                            <CustomInput
                                type={'password'}
                                value={formik.values.password}
                                placeholder={Translation.lang().w_passwordNew}
                                name={'password'}
                                onChange={formik.handleChange}
                                formik={formik}
                            />
                            <CustomInput
                                type={'password'}
                                value={formik.values.confirmPassword}
                                placeholder={Translation.lang().w_passwordConfirmed}
                                name={'confirmPassword'}
                                onChange={formik.handleChange}
                                formik={formik}
                                sx={{ marginTop: "10px" }}
                            />
                            <CustomButton onClick={formik.handleSubmit} label={Translation.lang().w_done} />
                        </Stack>
                    </BorderCard>
                </form>
            </div>
            <VersionDetails />
        </Container >
    );
};

export default ResetPasswordPage;
