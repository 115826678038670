// store

// Default
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

// Redux Persist
import storage from 'redux-persist/lib/storage';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';

// Slice
import authSlice from '../features/authSlice';
import homeSlice from '../features/homeSlice';

const reducers = combineReducers({
  auth: authSlice,
  home: homeSlice,
});

const persistConfig = {
  key: 'counter',
  storage,
  whitelist: ['auth'],
  stateReconciler: autoMergeLevel1,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
