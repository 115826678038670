import React from 'react';
import dayjs from 'dayjs';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers';
import { Done, ArrowBackIos } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router';

// MUI components
import { Button, Container, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Translation
import Translation from '../../config/Translation';
import CustomIcon from '../../components/CustomIcon';
import { useLoader } from '../../provider/LoaderContext';
import useAlertDialog from '../../hooks/useAlertDialog';
import { fetchPOSTRequest } from '../../utils/services';
import CustomButton from '../../forms/button';

const AddReminder = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const alertDialog = useAlertDialog();
    const { showLoader, hideLoader } = useLoader();

    const reminder = state?.reminder;
    let id = null;
    let date = dayjs();
    const selectedDay = [false, false, false, false, false, false, false];

    if (reminder && reminder?.id) {
        id = reminder?.id;
        const [hours, minutes] = reminder.localTime.split(':').map(part => parseInt(part, 10)); // Ensure to use radix 10
        date = dayjs().hour(hours).minute(minutes)
        selectedDay[0] = reminder.day0;
        selectedDay[1] = reminder.day1;
        selectedDay[2] = reminder.day2;
        selectedDay[3] = reminder.day3;
        selectedDay[4] = reminder.day4;
        selectedDay[5] = reminder.day5;
        selectedDay[6] = reminder.day6;
    }
    const DAYS = [
        { day: 0, name: Translation.lang().w_everyDay0 },
        { day: 1, name: Translation.lang().w_everyDay1 },
        { day: 2, name: Translation.lang().w_everyDay2 },
        { day: 3, name: Translation.lang().w_everyDay3 },
        { day: 4, name: Translation.lang().w_everyDay4 },
        { day: 5, name: Translation.lang().w_everyDay5 },
        { day: 6, name: Translation.lang().w_everyDay6 },
    ];
    const DAYSHORTNAMES = [
        Translation.lang().w_dayShort0,
        Translation.lang().w_dayShort1,
        Translation.lang().w_dayShort2,
        Translation.lang().w_dayShort3,
        Translation.lang().w_dayShort4,
        Translation.lang().w_dayShort5,
        Translation.lang().w_dayShort6,
    ];
    const [isDay, setIsDay] = React.useState(false);
    const [title, seTitle] = React.useState("Reminder");
    const [reminderTime, setReminderTime] = React.useState(date);
    const [selectedDays, setSelectedDays] = React.useState(selectedDay ? selectedDay.slice() : [false, false, false, false, false, false, false]);
    const pressRow = (rowData) => {
        setSelectedDays((prevSelectedDays) => {
            const updatedSelectedDays = [...prevSelectedDays];
            updatedSelectedDays[rowData.day] = !updatedSelectedDays[rowData.day];
            return updatedSelectedDays;
        });
    }

    const onChangeDate = (name, value) => {
        setReminderTime(value)
    };

    const _repeatText = () => {
        // const selectedDays = [];
        if (selectedDays[0] && selectedDays[1] && selectedDays[2] && selectedDays[3] && selectedDays[4] && selectedDays[5] && selectedDays[6]) {
            return Translation.lang().w_everyday;
        }

        if (!selectedDays[0] && selectedDays[1] && selectedDays[2] && selectedDays[3] && selectedDays[4] && selectedDays[5] && !selectedDays[6]) {
            return Translation.lang().w_weekdays;
        }

        if (selectedDays[0] && !selectedDays[1] && !selectedDays[2] && !selectedDays[3] && !selectedDays[4] && !selectedDays[5] && selectedDays[6]) {
            return Translation.lang().w_weekends;
        }

        if (!selectedDays[0] && !selectedDays[1] && !selectedDays[2] &&
            !selectedDays[3] && !selectedDays[4] && !selectedDays[5] &&
            !selectedDays[6]) {
            return Translation.lang().w_never;
        }

        const names = [];

        if (selectedDays[1]) names.push(DAYSHORTNAMES[1]);
        if (selectedDays[2]) names.push(DAYSHORTNAMES[2]);
        if (selectedDays[3]) names.push(DAYSHORTNAMES[3]);
        if (selectedDays[4]) names.push(DAYSHORTNAMES[4]);
        if (selectedDays[5]) names.push(DAYSHORTNAMES[5]);
        if (selectedDays[6]) names.push(DAYSHORTNAMES[6]);
        if (selectedDays[0]) names.push(DAYSHORTNAMES[0]);
        return names.join(' ');
    }

    const _selectDayNext = () => {
        setIsDay(true)
        seTitle("Select Day")
    }

    const _selectTimeNext = () => {
        if (!isDay) {
            navigate(-1)
        }
        setIsDay(false)
        seTitle("Reminder")

    }


    const saveReminder = () => {

        if (isDay) {
            setIsDay(false)
            seTitle("Reminder")
        } else {
            onSaveReminder()
        }
    }
    const onSaveReminder = async () => {
        showLoader()
        try {
            let localTime
            let timezoneOffset

            if (!selectedDays[0] && !selectedDays[1] && !selectedDays[2] && !selectedDays[3] && !selectedDays[4] && !selectedDays[5] && !selectedDays[6]) {

                alertDialog({
                    title: Translation.lang().w_Warning,
                    desc: Translation.lang().w_med_repeatDayRequired,
                    rightButtonText: Translation.lang().w_ok

                })
            } else {
                if (reminderTime) {
                    localTime = reminderTime.format('HH:mm');
                    timezoneOffset = dayjs().utcOffset();
                }
                const reminder = {
                    "id": state?.reminder ? state?.reminder.id : null,
                    "trackType": 6,
                    "timezoneOffset": timezoneOffset,
                    "localTime": localTime,
                    "day0": selectedDays[0],
                    "day1": selectedDays[1],
                    "day2": selectedDays[2],
                    "day3": selectedDays[3],
                    "day4": selectedDays[4],
                    "day5": selectedDays[5],
                    "day6": selectedDays[6]
                };
                const response = await fetchPOSTRequest("api/TrackReminder/SaveEntry", reminder);
                if (response.status === 200) {
                    navigate(-1)
                }
            }
        } catch (error) {
            console.log("🚀 ~ file: AddReminder.js:141 ~ onSaveReminder ~ error:", error)

        }
        hideLoader()
    }
    const onStopReminder = async () => {
        showLoader()
        try {
            const response = await fetchPOSTRequest("api/TrackReminder/stop", { id });
            if (response.status === 200) {
                navigate(-1)
            }
        } catch (error) {
            console.log("🚀 ~ file: AddReminder.js:190 ~ onStopReminder ~ error:", error)
        }
        hideLoader()
    }

    const stopReminder = () => {
        alertDialog({
            title: Translation.lang().w_reminder,
            desc: Translation.lang().w_confirmStopAppointment,
            leftButtonText: Translation.lang().w_cancel,
            rightButtonText: Translation.lang().w_ok,
            rightButtonFunction: onStopReminder
        })
    }
    const TimeComponent = () => <>
        <Box>
            <Stack sx={{ padding: "15px 12px", backgroundColor: "#C4CDD5" }} justifyContent={'center'} alignItems={"center"}>
                <Typography style={{ color: "#000", textAlign: 'center' }}>
                    {Translation.lang().w_what_time_do_you}
                </Typography>
            </Stack>
            <Divider color='grey' style={{ height: 2 }} />
            <Container>
                <MultiSectionDigitalClock
                    sx={{ marginTop: 6, justifyContent: "center", alignItems: "center" }}
                    ampm={false}
                    value={reminderTime}
                    onChange={(e) => onChangeDate("reminderTime", e)}
                />
            </Container>
        </Box>
        <Divider color='#666666' />
        <Stack onClick={_selectDayNext} color='white' style={{ padding: 12 }} justifyContent={"space-between"} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }}>
            <Typography color="#666666">
                {Translation.lang().w_repeat}
            </Typography>
            <Stack spacing={2} direction={{ xs: 'row', sm: 'row' }}>
                <Typography color="#666666">
                    {_repeatText()}
                </Typography>
                <CustomIcon sx={{ height: 20 }} src="assets/arrow_right.png" />
            </Stack>
        </Stack>
        <Divider color='#666666' />
    </>

    const DateComponent = () => <>
        {
            DAYS.map((rowData, index) =>
                <Stack key={index} onClick={() => pressRow(rowData)}>
                    {index === 0 ? null : <Divider color='#666666' />}
                    <Stack alignItems={"center"} direction={{ xs: 'row', sm: 'row' }} style={{ padding: 12 }} justifyContent={"space-between"} >
                        <Typography>
                            {rowData.name}
                        </Typography>
                        {selectedDays[rowData.day] ? <CustomIcon key={rowData.day} src="assets/iconCheckSmall.png" /> : null}
                    </Stack>
                </Stack>)
        }
    </>

    return (
        <>
            <Stack color='white' style={{ backgroundColor: "#123B6D", padding: 12 }} justifyContent={"space-between"} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }}>
                <Button onClick={_selectTimeNext}>
                    <ArrowBackIos sx={{ color: "#ffffff" }} />
                </Button>
                <Typography style={{ flex: 1, textAlign: 'center' }} variant='h5'>
                    {title}
                </Typography>
                <Button onClick={saveReminder}>
                    <Done sx={{ color: "#ffffff" }} />
                </Button>
            </Stack>
            {isDay ? <DateComponent /> : <TimeComponent />}
            {!isDay && id && id > 0 ?
                <Stack my={2} mx={4} justifyContent={"center"} alignItems={"center"}>
                    <CustomButton onClick={stopReminder} label={Translation.lang().w_stopReminder} />
                </Stack>
                : null
            }

        </>
    )
}


export default AddReminder