// MUI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const SingleChoice = ({ isSelected, value, onClick }) => {
  return (
    <Box
      sx={{
        borderRadius: "6px",
        padding: "12px",
        marginBottom: '20px',
        background: isSelected ? "#44a8d9" : "#eeeeee",
      }}
      onClick={onClick}
    >
      <Typography paragraph sx={{ fontWeight: 600, margin: 0, color: "#424242" }}>
        {value}
      </Typography>
    </Box>
  )
}

export default SingleChoice;