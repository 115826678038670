// Default
import { useEffect, useState } from "react";

// MUI components
import Badge from '@mui/material/Badge';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

// Redux
import { useSelector } from "react-redux";

// Custom
import HomePage from "../../pages/home-page/HomePage";
import ContactPage from "../../pages/contact-page/ContactPage";
import ProfilePage from "../../pages/account-page/ProfilePage";


// Icons 
import CustomIcon from "../CustomIcon";



const CustomBottomNavigation = () => {
    const [value, setValue] = useState(0);
    const { appStatus } = useSelector((state) => state.home);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const bottomPage = {
        0: <HomePage />,
        1: <ContactPage />,
        2: <ProfilePage />
    }
    return (
        <div>
            <div style={{ paddingBottom: 100 }}>
                {bottomPage[value]}
            </div>
            <Paper elevation={3} style={{ position: 'fixed', bottom: 0, width: '100%' }}>
                <BottomNavigation showLabels value={value} onChange={handleChange}>
                    <BottomNavigationAction label="Home" icon={
                        <Badge
                            badgeContent={appStatus?.numNewSurvey}
                            sx={{
                                '& .MuiBadge-badge': {
                                    color: '#ffffff',
                                    backgroundColor: '#ff3723',
                                },
                            }}
                        >
                            <CustomIcon src={"assets/tabTrack.png"} />
                        </Badge>
                    } />
                    <BottomNavigationAction label="Contact" icon={<CustomIcon src={"assets/tabMessage.png"} />} />
                    <BottomNavigationAction label="More" icon={<CustomIcon src={"assets/tabMore.png"} />} />
                </BottomNavigation>
            </Paper>
        </div>
    );
};

export default CustomBottomNavigation;
