import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

export const VersionDetails = () =>
    <Stack marginTop={4} justifyContent={"center"} alignItems={"center"}>
        <Typography>
            Version 1.0
        </Typography>
        <Box height={50} component="img" src="assets/logo_myownmed@3x.png" />
    </Stack>



