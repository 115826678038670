// Defualt
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI components
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { fetchAppStatus } from '../../redux/features/homeSlice';

// Custom
import { HomeCard } from '../../components/HomeCard';
import { NavBar } from '../../components/NavBar';

// utils
import { INJECTION_PAGE, SURVEYS_PAGE, REMINDERS_PAGE } from '../../utils/pageName';
import { requestForToken } from '../../components/firebase-notification/firebase';
import Translation from '../../config/Translation';
import moment from 'moment';
import { fetchPOSTRequest } from '../../utils/services';
import { setFcmToken } from '../../redux/features/authSlice';

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((data) => data.auth.user);
  const { appStatus } = useSelector((state) => state.home);
  const { fcmToken } = useSelector((state) => state.auth);


  useEffect(() => {
    const getAppStatus = () => dispatch(fetchAppStatus());
    getAppStatus();
  }, []);
  const setFCMToken = async () => {
    if (!fcmToken) {
      const token = await requestForToken();
      const payload = {
        deviceType: "ANDROID",
        deviceToken: token,
        cultureName: Translation.lang().Culture,
        utcOffsetInMinutes: moment().utcOffset(),
      }
      console.log(payload,"payload");
      try {
        const response = await fetchPOSTRequest('api/Profile/AddDevice', payload);
        if (response.status === 200) {
          dispatch(setFcmToken(token));
        }
      } catch (error) { }
    }
  }
  useEffect(() => {
    setFCMToken()
  },[])

  return (
    <>
      <NavBar />
      <div>
        <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
          <Typography style={{ marginTop: 20 }} variant="h3">
            {`Welcome back ${userData.firstName}!`}
          </Typography>
          <HomeCard>
            <Stack alignItems={'center'} onClick={() => navigate(`/${INJECTION_PAGE}`)}>
              <Box component="img" src="assets/track/Injection_NavyBlue_small.png" />
              <Typography variant="inherit">Injection</Typography>
            </Stack>
          </HomeCard>
          <HomeCard>
            <Stack alignItems={'center'} position={'relative'} onClick={() => navigate(`/${SURVEYS_PAGE}`)}>
              <Badge
                badgeContent={appStatus?.numNewSurvey}
                sx={{
                  '& .MuiBadge-badge': {
                    color: '#ffffff',
                    backgroundColor: '#ff3723',
                  },
                  position: 'absolute',
                  top: -33,
                  right: -13,
                }}
              />
              <Box component="img" src="assets/more/more_icon_survey@2x.png" />
              <Typography variant="inherit">Surveys</Typography>
            </Stack>
          </HomeCard>
          <HomeCard>
            <Stack justifyContent={'center'} onClick={() => navigate(`/${REMINDERS_PAGE}`)} alignItems={'center'}>
              <Box component="img" src="assets/track/Medication_blue.png" />
              <Typography variant="inherit">Reminders</Typography>
            </Stack>
          </HomeCard>
        </Stack>
      </div>
    </>
  );
};

export default HomePage;
