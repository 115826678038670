import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  SURVEYS_PAGE,
  CONFIRM_ID_PAGE,
  CONTACT_PAGE,
  FORGET_PASSWORD_PAGE,
  LOGIN_PAGE,
  RESET_PASSWORD_PAGE,
  RESOURCES_PAGE,
  SUMMARY_PAGE,
  SETTINGS_PAGE,
  LANGUAGE_PAGE,
  UPDATE_PROFILE_PAGE,
  UPDATE_PASSWORD_PAGE,
  UPDATE_NICKNAME_PAGE,
  REMINDERS_PAGE,
  ADD_REMINDERS_PAGE,
  SURVEYS_DETAILS_PAGE,
  FAQ_PAGE,
  FAQ_DETAILS_PAGE,
  PRIVACY_PAGE,
  TERMS_PAGE,
  UPDATE_PIN_PAGE,
  INJECTION_PAGE,
  STUDY_INSTRUCTIONS,
  STUDY_INSTRUCTIONS_DETAILS,
  INJECTION_HISTORY_PAGE,
  INJECTION_DETAILS_PAGE,
  SURVEYS_HISTORY,
  SURVEY_SUMMARY_DETAIL,
  SETUP_PAGE
} from './utils/pageName';
import LoginPage from './pages/account-page/LoginPage';
import LoginSetup from './pages/setup-page';
import ForgetPasswordPage from './pages/account-page/ForgetPasswordPage';
import ConfirmIdPage from './pages/account-page/ConfirmIdPage';
import ResetPasswordPage from './pages/account-page/ResetPasswordPage';
import CustomBottomNavigation from './components/bottom-navigation';
import ContactPage from './pages/contact-page/ContactPage';
import ResourcesPage from './pages/resources-page/ResourcesPage';

import Surveys from './pages/surveys-page/Surveys';
import SurveyDetails from './pages/surveys-page/SurveyDetails';
import Settings from './pages/settings-page/Settings';
import LangaugePage from './pages/settings-page/LangaugePage';
import UpdatePasswordPage from './pages/settings-page/UpdatePasswordPage';
import UpdateNicknamePage from './pages/settings-page/UpdateNicknamePage';
import ProfilePicturePage from './pages/settings-page/ProfilePicturePage';
import Reminders from './pages/reminders-page/Reminders';
import AddReminder from './pages/reminders-page/AddReminder';
import FAQPage from './pages/resources-page/FAQPage';
import FAQDetailPage from './pages/resources-page/FAQDetailPage';
import TermsPage from './pages/settings-page/TermsPage';
import PrivacyPage from './pages/settings-page/PrivacyPage';
import UpdatePinPage from './pages/settings-page/UpdatePinPage';
import AddInjectionPage from './pages/injections-page/AddInjectionPage';
import StudyInstructionsPage from './pages/resources-page/StudyInstructionsPage';
import StudyInstructionsDetailsPage from './pages/resources-page/StudyInstructionsDetailsPage';
import SummaryPage from './pages/summary-page/SummaryPage';
import InjectionHistoryPage from './pages/injections-page/InjectionHistoryPage';
import InjectionDetailsPage from './pages/injections-page/InjectionDetailsPage';
import SurveysHistory from './pages/surveys-page/SurveysHistory';
import SurveySummaryDetailView from './pages/surveys-page/SurveySummaryDetailView';

const ProtectedRoute = ({ element }) => {
  const { accessToken,user } = useSelector((state) => state.auth);

  if (!accessToken) {
    return <Navigate to="/login" replace />;
  }

  return element;
};


export default function Router() {
  const routes = useRoutes([
    {
      path: LOGIN_PAGE,
      element: <LoginPage />,
      index: true,
    },
    {
      path: '/',
      element: <ProtectedRoute element={<CustomBottomNavigation />}/>,
    },
    {
      path: SETUP_PAGE,
      element: <LoginSetup />,
    },
    {
      path: FORGET_PASSWORD_PAGE,
      element: <ForgetPasswordPage />,
    },
    {
      path: CONFIRM_ID_PAGE,
      element: <ConfirmIdPage />,

    },
    {
      path: RESET_PASSWORD_PAGE,
      element: <ResetPasswordPage />,
    },
    {
      path: CONTACT_PAGE,
      element: <ProtectedRoute element={<ContactPage />}/>,
    },
    {
      path: SUMMARY_PAGE,
      element: <ProtectedRoute element={<SummaryPage />}/>,
    },
    {
      path: INJECTION_HISTORY_PAGE,
      element: <ProtectedRoute element={<InjectionHistoryPage />}/>,
    },
    {
      path: INJECTION_DETAILS_PAGE,
      element: <ProtectedRoute element={<InjectionDetailsPage />}/>,
    },
    {
      path: RESOURCES_PAGE,
      element: <ProtectedRoute element={<ResourcesPage />}/>,
    },
    {
      path: SURVEYS_PAGE,
      element: <ProtectedRoute element={<Surveys />}/>,
    },
    {
      path: SURVEYS_DETAILS_PAGE,
      element: <ProtectedRoute element={<SurveyDetails />}/>,
    },
    {
      path: SETTINGS_PAGE,
      element: <ProtectedRoute element={<Settings />}/>,
    },
    {
      path: LANGUAGE_PAGE,
      element: <ProtectedRoute element={<LangaugePage />}/>,
    },
    {
      path: UPDATE_PASSWORD_PAGE,
      element: <ProtectedRoute element={<UpdatePasswordPage />}/>,
    },
    {
      path: UPDATE_NICKNAME_PAGE,
      element: <ProtectedRoute element={<UpdateNicknamePage />}/>,
    },
    {
      path: UPDATE_PROFILE_PAGE,
      element: <ProtectedRoute element={<ProfilePicturePage />}/>,
    },
    {
      path: REMINDERS_PAGE,
      element: <ProtectedRoute element={<Reminders />}/>,
    },
    {
      path: ADD_REMINDERS_PAGE,
      element: <ProtectedRoute element={<AddReminder />}/>,
    },
    {
      path: FAQ_PAGE,
      element: <ProtectedRoute element={<FAQPage />}/>,
    },
    {
      path: FAQ_DETAILS_PAGE,
      element: <ProtectedRoute element={<FAQDetailPage />}/>,
    },
    {
      path: STUDY_INSTRUCTIONS,
      element: <ProtectedRoute element={<StudyInstructionsPage />}/>,
    },
    {
      path: STUDY_INSTRUCTIONS_DETAILS,
      element: <ProtectedRoute element={<StudyInstructionsDetailsPage />}/>,
    },
    {
      path: TERMS_PAGE,
      element: <ProtectedRoute element={<TermsPage />}/>,
    },
    {
      path: PRIVACY_PAGE,
      element: <ProtectedRoute element={<PrivacyPage />}/>,
    },
    // {
    //   path: UPDATE_PIN_PAGE,
    //   element: <ProtectedRoute element={<UpdatePinPage />}/>,
    // },
    {
      path: INJECTION_PAGE,
      element: <ProtectedRoute element={<AddInjectionPage />}/>,
    },
    {
      path: SURVEYS_HISTORY,
      element: <ProtectedRoute element={<SurveysHistory />}/>,
    },
    {
      path: SURVEY_SUMMARY_DETAIL,
      element: <ProtectedRoute element={<SurveySummaryDetailView />}/>,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
