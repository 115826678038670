
// MUI components
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

// MUI styles
import { makeStyles } from '@mui/styles';

// Translation
import Translation from '../../config/Translation';


const useStyles = makeStyles({
  root: {
    display: "none"
  },
  thumb: {
    position: "absolute",
    top: -5,
    height: "25px",
    width: "25px"
  },
  text: {
    margin: 0,
    fontSize: "14px",
    maxWidth: "100px",
    textAlign: "center",
    lineHeight: "18px"
  }
});

const SliderPage = ({ onChange, defaultValue, step }) => {
  const classes = useStyles();
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
        <Slider
          aria-label="Temperature"
          sx={{
            borderTop: 10, borderLeft: 10, borderRight: 10, borderColor: "#406cdc",
            borderRadius: 0, maxWidth: 300, position: "relative"
          }}
          onChange={onChange}
          valueLabelDisplay="auto"
          step={0.2}
          marks
          value={defaultValue}
          min={0}
          max={10}
          classes={{ rail: classes.root, track: classes.root, mark: classes.root, thumb: classes.thumb }}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        marginTop={"10px"}
      >
        <Box display={"flex"} justifyContent={"space-between"} maxWidth={400} width={"100%"}>
          <Typography paragraph className={classes.text}> {Translation.lang().w_no_symptoms}</Typography>
          <Typography paragraph className={classes.text}>{Translation.lang().w_worst_possibile_symptoms}</Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems={"center"}
        marginTop={"4px"}
      >
        <Box display={"flex"} justifyContent={"space-between"} maxWidth={350} width={"100%"}>
          <Box component="img" src="assets/icon_HappyFace.png" height={30} width={30} textAlign={"center"} />
          <Box component="img" src="assets/icon_SadFace.png" height={30} width={30} textAlign={"center"} />
        </Box>
      </Box>
    </>

  );
}

export default SliderPage