import { ArrowBackIos } from '@mui/icons-material';
import { Box, IconButton, Link, Stack, Typography } from '@mui/material';

export const ResourcesNavBar = ({ onClick }) => (
    <Stack color='white' style={{ backgroundColor: "#123B6D", padding: 12 }} justifyContent={"space-between"} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }}>
        <IconButton sx={{ padding: 0 }} onClick={onClick} > <ArrowBackIos sx={{ color: "#ffffff" }} /></IconButton>
        <Typography variant='h5' style={{ color: "#fff" }}>
            Resources
        </Typography>
        <Link href='/resources'>
            <Box component="img" src="assets/icon_refresh.png" />
        </Link>
    </Stack>
);


