import { Suspense, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { useLoader } from './provider/LoaderContext';
import AlertDialogProvider from './provider/AlertDialogProvider';
import { useDispatch, useSelector } from 'react-redux';
import { onMessageListener, requestForToken } from './components/firebase-notification/firebase';
import { setFcmToken } from './redux/features/authSlice';
import { fetchPOSTRequest } from './utils/services';
import Translation from './config/Translation';
import moment from 'moment';

// ----------------------------------------------------------------------

export default function App() {
  const { isLoading } = useLoader();
  onMessageListener()
    .then((payload) => {
      console.log(payload,"Payload for Notification");
      if ('Notification' in window) {
        new Notification(payload.notification.title, { body: payload.notification.body });
      }
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ScrollToTop />
        <Suspense fallback={<div>Loading</div>}>
          <AlertDialogProvider>
            <Router />
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}>
              <CircularProgress color='success' />
            </Backdrop>
          </AlertDialogProvider>
        </Suspense>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
