// Default
import { useEffect, useState } from "react";
import { useNavigate } from "react-router"

// MUI components
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

// MUI styles
import { makeStyles } from '@mui/styles';

// Custom
import { NavBar } from "../../components/NavBar"
import CustomNavBar from "../../components/CustomNavBar"
import CustomButton from "../../forms/button";
import Dropdown from "../../forms/dropdown";
import CustomInput from "../../forms/input";

// utils
import { SURVEYS_PAGE, STUDY_INSTRUCTIONS, INJECTION_HISTORY_PAGE } from "../../utils/pageName"
import Translation from "../../config/Translation"
import { INJECTION_LOCATION, HOURS, MINS, INDICATORS } from "./data";
import { fetchGETRequest, fetchPOSTRequest } from "../../utils/services";
import useAlertDialog from "../../hooks/useAlertDialog";
import moment from "moment";
import { useLoader } from "../../provider/LoaderContext";

const useStyles = makeStyles({
  btn: {
    minWidth: '50px',
    minHeight: '35px',
    padding: 0,
    boxShadow: 'none'
  },
  radius1: {
    borderRadius: '4px 0 0 4px',
  },
  radius2: {
    borderRadius: '0 4px 4px 0',
  },
  bg1: {
    background: '#9f9f9f',
    color: '#ffffff',
    '&:hover': {
      background: '#9f9f9f',
      color: '#ffffff',
    },
  },
  bg2: {
    background: '#eeeeee',
    color: '#b6b6b6',
    '&:hover': {
      background: '#eeeeee',
      color: '#b6b6b6',
    },
  }
});

const AddInjectionPage = () => {
  const navigate = useNavigate();
  const alertDialog = useAlertDialog();
  const { showLoader, hideLoader } = useLoader();
  const classes = useStyles();
  const [isActive, setIsActive] = useState(INDICATORS[0])
  const [location, setLocation] = useState(INJECTION_LOCATION[0]);
  const [hour, setHour] = useState(HOURS[12]);
  const [mins, setMins] = useState(MINS[0]);
  const [visit, setVisit] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const [injectionDate, setInjectionDate] = useState("");
  useEffect(() => {
    async function myApi() {
      const timedVisitsRes = await fetchGETRequest(`api/Profile/GetTimedVisits`);
      if (timedVisitsRes.status === 200) {
        if (timedVisitsRes.data.length > 0) {
          setVisit(timedVisitsRes.data[0].id);
        }
      }
      const canEditRes = await fetchGETRequest(`api/TrkSpecimen/CanEdit`);
      if (canEditRes.status === 200) {
        setCanEdit(canEditRes.data.data === "1")
      }
    }
    myApi();
  }, []);
  const setAllState = async() => {
    const canEditRes = await fetchGETRequest(`api/TrkSpecimen/CanEdit`);
    if (canEditRes.status === 200) {
      setCanEdit(canEditRes.data.data === "1")
    }
    setInjectionDate(null);
    setLocation(INJECTION_LOCATION[0]);
    setIsActive(INDICATORS[0]);
    setHour(HOURS[12]);
    setMins(MINS[0]);
  }
  const saveInjectionData = async () => {
    if (injectionDate == null) {
      alertDialog({
        desc: Translation.lang().w_require_collection_date
      })
    } else {
      const iDate = new Date(injectionDate);
      // let intHour = parseInt(hour) + (isActive == "AM" ? 0 : 12);
      let intHour = parseInt(hour);
      let intMin = parseInt(mins);
      iDate.setHours(intHour, intMin, 0, 0);
      const specimenType = INJECTION_LOCATION.findIndex((item) => item === location);
      const data = {
        day: moment().format('YYYY-MM-DD'),
        utcDay: moment().utc().format(),
        timedVisitId: visit,
        specimenType,
        dateTimeCollection: moment(iDate).utc().format(),
        dateShipment: null,
        airbillNumber: "",
      }
      showLoader()

      try {
        const response = await fetchPOSTRequest(`api/TrkSpecimen/SaveEntry`, data);
        if (response.status === 200) {
          if (response.data.id <= 0) {
            alertDialog({
              title: Translation.lang().w_injection_saved,
              desc: Translation.lang().w_injection_not_allow_to_save,
              rightButtonText: Translation.lang().w_ok,
              rightButtonFunction: () => { setAllState() }
            })
          } else {
            alertDialog({
              title: Translation.lang().w_injection_saved,
              desc: Translation.lang().w_specimen_collection_saved,
              rightButtonText: Translation.lang().w_ok,
              rightButtonFunction: () => { setAllState() }
            })
          }
        }
      } catch (error) {
        console.log(error.data)
      }
      hideLoader()
    }
  }
  return (
    <>
      <NavBar />
      <CustomNavBar
        nextICon={true}
        nextClick={() => navigate(`/${SURVEYS_PAGE}`)}
        src={"assets/track/Injection_NavyBlue_small.png"}
        title={Translation.lang().w_injection}
      />

      <Box display={"flex"} justifyContent={"flex-end"} padding={"20px 12px"}>
        <Link href="" onClick={() => navigate(`/${STUDY_INSTRUCTIONS}`)} color={"#038fd4"}>{Translation.lang().w_instructions}</Link>
      </Box>

      <Box padding={"0 12px"}>
        <Typography color={"#636363"} fontWeight={600} paragraph>{Translation.lang().w_injection_detail}</Typography>
        <Typography sx={{ marginLeft: "10px" }} fontWeight={500} paragraph>{Translation.lang().w_specimen_instruction1} <Link href={`/${INJECTION_HISTORY_PAGE}`} color={"#038fd4"}>{Translation.lang().w_specimen_instruction2}</Link></Typography>
        <Typography sx={{ margin: "0 0 0 10px" }} fontWeight={500} paragraph>{Translation.lang().w_specimen_instruction3}</Typography>
      </Box>


      <Grid container spacing={3} sx={{ padding: "0 12px", marginTop: "20px" }}>
        <Grid item xs={5}>
          <Typography color={"#848484"} marginBottom={"6px"}>{Translation.lang().w_injection_location}</Typography>
          <Dropdown options={INJECTION_LOCATION} value={location} onChange={(e) => setLocation(e.target.value)} />
        </Grid>
        <Grid item xs={5}>
          <Typography color={"#848484"} marginBottom={"6px"}>{Translation.lang().w_collection_date}</Typography>
          <CustomInput
            type="date"
            sx={{ height: "35px" }}
            onChange={(event) => setInjectionDate(event.target.value)}
            value={injectionDate || ""}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ padding: "0 12px", marginTop: "10px" }}>
        <Grid item xs={12}>
          <Typography color={"#848484"}>{Translation.lang().w_collection_time}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Dropdown options={HOURS} value={hour} onChange={(e) => setHour(e.target.value)} />
        </Grid>
        <Grid item xs={3}>
          <Dropdown options={MINS} value={mins} onChange={(e) => setMins(e.target.value)} />
        </Grid>
        <Grid item xs={3}>
          <Box display={"flex"}>
            <Button className={`${classes.btn} ${classes.radius1} ${isActive === INDICATORS[0] ? classes.bg1 : classes.bg2}`} onClick={() => setIsActive(INDICATORS[0])} variant="contained">{INDICATORS[0]}</Button>
            <Button variant="contained" className={`${classes.btn} ${classes.radius2} ${isActive === INDICATORS[1] ? classes.bg1 : classes.bg2}`} onClick={() => setIsActive(INDICATORS[1])}>{INDICATORS[1]}</Button>
          </Box>
        </Grid>
      </Grid>

      <div style={{ padding: '0 25px', marginTop: '30px' }}>
        <CustomButton onClick={saveInjectionData} label={Translation.lang().w_submit} disabled={!canEdit} />
      </div>
    </>
  )
}

export default AddInjectionPage