// Default
import { useEffect, useState } from 'react'

// MUI components
import Box from '@mui/material/Box'

// Custom
import DetailNavBar from '../../components/DetailNavBar'
import ConsentSetup from './consentSetup'
import PasswordSetup from './passwordSetup'
import ProfileSetup from './profileSetup'
import NicknameSetup from './nicknameSetup'
import QuestionsSetup from './questionsSetup'

// Translation
import Translation from '../../config/Translation';
import { useDispatch, useSelector } from 'react-redux'
import { LOGIN_PAGE } from '../../utils/pageName'
import { useNavigate } from 'react-router-dom'
import { fetchPUTRequest } from '../../utils/services'
import { setUser } from '../../redux/features/authSlice'
import { useLoader } from '../../provider/LoaderContext'

const LoginSetup = () => {
  const { user } = useSelector((state) => state.auth);
  const page = user?.stage || 1;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = useLoader();
 
  const getDetailNavbar = () => {
    let navLabel = ""
    switch (page) {
      case 1:
        return navLabel = Translation.lang().w_signin;
      case 2:
        return navLabel = Translation.lang().w_updatePassword;
      case 3:
        return navLabel = Translation.lang().w_updateQuestions;
      case 4:
        return navLabel = Translation.lang().w_profilePicture;
      case 5:
        return navLabel = Translation.lang().w_youNickname;
      default:
        return null
    }
  }
  const apiFunction = async (URL, payload, dispatchPayload) => {
    showLoader()
    try {
      const response = await fetchPUTRequest(URL, payload);
      if (response.status === 200) {
        dispatch(setUser(dispatchPayload))
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader()
    }
  }
  const _saveValues = async (values) => {

    switch (page) {
      case 1:
        apiFunction(`api/Profile/Consent`, { "termAccepted": true }, { ...user, stage: page + 1, consented: true })
        break;
      case 2:
        apiFunction(`api/Profile/UpdatePassword`, values, { ...user, stage: page + 1 })
        break;
      case 3:
        apiFunction(`api/Profile/UpdateSecurityQuestions`, values, { ...user, stage: page + 1 })
        break;
      case 4:
        if (values?.avatarBase64) {
          apiFunction(`api/Profile/UpdatePhoto`, values, { ...user, stage: page + 1, avatarBase64: values.avatarBase64 })
        } else {
          dispatch(setUser({ ...user, stage: page + 1 }))
        }
        break;
      case 5:
        if (values?.nickname) {
          apiFunction(`api/Profile/UpdateNickName`, values, { ...user, nickname: values.nickname })
        }
        navigate('/');
        break;
    }
  }
  const onPrevious = () => {
    if (page == 1) {
      navigate(`/${LOGIN_PAGE}`)
    } else {
      dispatch(setUser({ ...user, stage: page - 1 }))
    }
  }
  const getContent = () => {
    switch (page) {
      case 1:
        return <ConsentSetup onSave={_saveValues} onPrevious={onPrevious} />;
      case 2:
        return <PasswordSetup onSave={_saveValues} />;
      case 3:
        return <QuestionsSetup onSave={_saveValues} />;
      case 4:
        return <ProfileSetup onSave={_saveValues} />;
      case 5:
        return <NicknameSetup onSave={_saveValues} />;
      default:
        return null
    }
  }


  return (
    <>
      <DetailNavBar onClick={onPrevious} label={getDetailNavbar()} bgColor="#f2f2f2" color="#0a95d0" />
      <Box sx={{ border: "2px solid #cdcdcd", margin: "50px 12px 0 12px", }}>
        {getContent()}
      </Box>
    </>
  )
}

export default LoginSetup;