// MUI components
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// MUI styles
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  select: {
    padding: "6px 10px !important",
    background: '#eeeeee !important',
    fontSize: '15px',
    color: "#aeaeae"
  },
  root: {
    "& fieldset": {
      border: 'none !important'
    },
  },
  menuPaper: {
    background: '#eeeeee'
  },
  itemRoot: {
    padding: '0 15px !important',
    lineHeight: '16px !important',
    fontSize: '15px',
    color: "#aeaeae",
    minHeight: '40px'
  },
});

const Dropdown = ({ options, onChange, value, disabled }) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth >
      <Select classes={{ select: classes.select }} className={classes.root}
        MenuProps={{
          classes: { paper: classes.menuPaper },
        }}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {
          options?.map((option, key) => (
            <MenuItem key={key} value={option} classes={{ root: classes.itemRoot }}>{option}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

export default Dropdown