import React from 'react'
import { Box, Container, Link, Stack, Typography } from '@mui/material'
import Logo from '../../components/logo/Logo'
import Translation from '../../config/Translation'

const ContactPage = () =>
    <div>
        <Stack color='white' style={{ backgroundColor: "#123B6D", padding: 12 }} justifyContent={"center"} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }}>
            <Typography style={{ textAlign: 'center' }} variant='h5'>
                Contact
            </Typography>
        </Stack>
        <Container maxWidth="xs" >
            <div>
                <Logo sx={{ marginTop: 2 }} />

                <Typography style={{ marginTop: 10, textAlign: 'center' }} variant='h5'>
                    {Translation.lang().w_Questions}
                </Typography>
                <Typography style={{ marginTop: 40, textAlign: 'center' }} variant='h5'>
                    {Translation.lang().w_please_contact_the_GRADUATE}
                </Typography>

                <div style={{ marginTop: 40, marginLeft: 20 }}>
                    <Stack direction={'row'} justifyContent='start' alignItems='center' spacing={3}>
                        <Box height={40} width={40}
                            component="img"
                            src={'assets/Email_Navy_small.png'}
                            sx={{ cursor: 'pointer' }} />

                        <Link href={`mailto:${Translation.lang().w_concact_email}`} underline="none">
                            {Translation.lang().w_concact_email}
                        </Link>
                    </Stack>

                    <Stack marginBottom={4} marginTop={2} spacing={3}>
                        <div style={{ height: 1, marginLeft: 60, backgroundColor: 'blue' }} />
                    </Stack>

                    <Stack direction={'row'} justifyContent='start' alignItems='center' spacing={3}>
                        <Box height={40} width={40}
                            component="img"
                            src={'assets/Phone_navy_small.png'}
                            sx={{ cursor: 'pointer' }} />

                        <Typography style={{ textAlign: 'center' }} variant='h5'>
                            {Translation.lang().w_concact_phone}
                        </Typography>
                    </Stack>
                </div>
            </div>
        </Container>
    </div>


export default ContactPage
