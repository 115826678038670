// Default
import { useNavigate } from 'react-router-dom';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Translation
import Translation from '../../config/Translation';

//Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Custom
import CustomInput from '../../forms/input';
import CustomButton from '../../forms/button';

const PasswordSetup = ({onSave}) => {
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Please enter the new password.')
      .min(5, 'New Password is too short - should be 5 chars minimum.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+[\]{};':"\\|,.<>/?]+$/,
        'Password must contain at least one lowercase letter, one uppercase letter, and one digit'
      )
  ,
    confirmPassword: Yup.string()
      .required('Please enter the confirm password.')
      .oneOf([Yup.ref('password'), null], 'Confirm Password must match.'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      pin: '',
      confirmPin: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await onSave({password:values.password , pin:values.pin});
    },
  });

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "30px", padding: "20px 20px 8px 20px" }}>

        <Box>
          <Typography paragraph sx={{ margin: "0 0 10px", fontSize: "15px", fontWeight: 600, textAlign: "center", color: "#616161" }}>{Translation.lang().w_setPassword}</Typography>
          <CustomInput
            type={'password'}
            value={formik.values.password}
            placeholder={Translation.lang().w_passwordNew}
            name={'password'}
            onChange={formik.handleChange}
            formik={formik}
          />
          <CustomInput
            type={'password'}
            value={formik.values.confirmPassword}
            placeholder={Translation.lang().w_passwordConfirmed}
            name={'confirmPassword'}
            onChange={formik.handleChange}
            formik={formik}
            sx={{ marginTop: "10px" }}
          />
        </Box>

        {/* <Box>
          <Typography paragraph sx={{ margin: "0 0 10px", fontSize: "15px", fontWeight: 600, textAlign: "center", color: "#616161" }}>{Translation.lang().w_setPin}</Typography>
          <CustomInput
            type={'number'}
            value={formik.values.pin}
            placeholder={Translation.lang().w_pinNew}
            name={'pin'}
            onChange={formik.handleChange}
            formik={formik}
          />
          <CustomInput
            type={'number'}
            value={formik.values.confirmPin}
            placeholder={Translation.lang().w_pinConfirmed}
            name={'confirmPin'}
            onChange={formik.handleChange}
            formik={formik}
            sx={{ marginTop: "10px" }}
          />
        </Box> */}

        <div style={{ padding: '0 20px' }}>
          <CustomButton  label={Translation.lang().w_next} onClick={formik.handleSubmit} />
        </div>

      </Box>
    </>
  );
};

export default PasswordSetup;
