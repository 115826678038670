// Default
import { useEffect } from 'react';

// MUI components
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

// Utils
import { useLoader } from '../../provider/LoaderContext';
import { baseURL } from '../../utils/services';

// Translation
import Translation from '../../config/Translation';

// Custom
import CustomButton from '../../forms/button';


const ConsentSetup = ({onSave,onPrevious,}) => {
  let pageUrl = baseURL + "Privacy/" + Translation.lang().Language + "/TermsOfUse.html?rnd=1";

  return (
    <>
      <Typography paragraph sx={{ margin: "20px 0 0", fontSize: '15px', textAlign: "center" }}>{Translation.lang().w_consentTermOfUse}</Typography>
      <iframe
        title={Translation.lang().w_termOfUse}
        src={pageUrl}
        width="100%"
        style={{ height: '50vh', border: "none" }}
      />
      <Box sx={{ display: 'flex', flexDirection: "column", gap: "20px", padding: "20px" }}>
        <CustomButton onClick={onSave} label={Translation.lang().w_accept} />
        <CustomButton onClick={onPrevious} label={Translation.lang().w_cancel} />
      </Box>

    </>
  )
}

export default ConsentSetup