// Default
import { useNavigate } from 'react-router-dom';

// MUI components
import Box from '@mui/material/Box';

// Translation
import Translation from '../../config/Translation';

//Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Services
import { fetchPUTRequest } from '../../utils/services';

// Custom
import DetailNavBar from '../../components/DetailNavBar';
import CustomInput from '../../forms/input';
import CustomButton from '../../forms/button';
import { useLoader } from '../../provider/LoaderContext';
import useAlertDialog from '../../hooks/useAlertDialog';
import { logout } from '../../redux/features/authSlice';
import { useDispatch } from 'react-redux';

const UpdatePasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = useLoader();
  const alertDialog = useAlertDialog();

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Please enter the new password.')
      .min(5, 'New Password is too short - should be 5 chars minimum.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+[\]{};':"\\|,.<>/?]+$/,
        'Password must contain at least one lowercase letter, one uppercase letter, and one digit'
      )
  ,
    confirmPassword: Yup.string()
      .required('Please enter the confirm password.')
      .oneOf([Yup.ref('password'), null], 'Confirm Password must match.'),
  });

    const formik = useFormik({
      initialValues: {
        password: '',
        confirmPassword: ''
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        const data = { password: values.password }
        showLoader();
        try {
          const response = await fetchPUTRequest('api/Profile/UpdatePassword', data);
          if (response?.data?.status === 'Success') {
            alertDialog({
              title: Translation.lang().w_updatePassword,
              desc: Translation.lang().w_changePasswordSuccess,
              rightButtonText: Translation.lang().w_ok,
              rightButtonFunction: () => {
                dispatch(logout())
              }
            });
            formik.resetForm()
          }
        } catch (error) {
          alertDialog({
            title: Translation.lang().w_updatePassword,
            desc: Translation.lang().w_resetFailed,
          });
        }
        hideLoader();
      },
    });
  
  return (
    <>
      <DetailNavBar label={Translation.lang().w_updatePassword} onClick={() => navigate(-1)} />
      <Box sx={{ padding: '0 20px', marginTop: '10px' }}>
        <CustomInput
          type={'password'}
          value={formik.values.password}
          placeholder={Translation.lang().w_passwordNew}
          name={'password'}
          onChange={formik.handleChange}
          formik={formik}   
        />
        <CustomInput
          type={'password'}
          value={formik.values.confirmPassword}
          placeholder={Translation.lang().w_passwordConfirmed}
          name={'confirmPassword'}
          onChange={formik.handleChange}
          formik={formik}   
          sx={{ marginTop: "10px"}}
        />
      </Box>
      <div style={{ padding: '0 20px', marginTop: '20px' }}>
        <CustomButton label={Translation.lang().w_save} onClick={formik.handleSubmit}/>
      </div>
    </>
  );
};

export default UpdatePasswordPage;
