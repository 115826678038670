// Default
import { useNavigate } from 'react-router-dom'

// MUI components
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// Icons
import CustomIcon from '../../components/CustomIcon'

//Utils
import { INJECTION_HISTORY_PAGE, SURVEYS_HISTORY } from '../../utils/pageName'
import Translation from '../../config/Translation'

// Custom
import DetailNavBar from '../../components/DetailNavBar'

const SummaryPage = () => {
  const navigate = useNavigate();


  const summaryList = [
    {
      title: Translation.lang().w_injection,
      url: `/${INJECTION_HISTORY_PAGE}`,
    },
    {
      title: Translation.lang().w_surveys,
      url: `/${SURVEYS_HISTORY}`,
    },
  ]

  return <>
    <DetailNavBar label={Translation.lang().w_summary} onClick={() => navigate(-1)} />
    <>

      {
        summaryList.map((e, i) => {
          return (
            <Stack key={i}>
              <Stack p={2} onClick={() => navigate(e.url)} justifyContent={"space-between"} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }} >
                <Typography style={{ marginLeft: 10, color: '#000' }}>
                  {e.title}
                </Typography>
                <CustomIcon src={"assets/arrow_right.png"} />
              </Stack>
              <Divider />
            </Stack>
          )
        })
      }
    </>
  </>
}

export default SummaryPage