import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const Logo = ({ sx }) => (
  <Box
    component="img"
    src="assets/graduate-logo.png"
    sx={{ cursor: 'pointer', ...sx }}
  />
);

Logo.propTypes = {
  sx: PropTypes.object,
};

export default Logo;
