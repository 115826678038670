// Redux
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

// Default
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';

// App
import App from './App';

import reportWebVitals from './reportWebVitals';
import { LoaderProvider } from './provider/LoaderContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Store
import { store } from './redux/store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
const persistor = persistStore(store);
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <LoaderProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </LoaderProvider>
    </BrowserRouter>
  </HelmetProvider>
);
serviceWorkerRegistration.register();
reportWebVitals();
