// MUI components
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

// MUI icons
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Custom
import CustomIcon from './CustomIcon';

const CustomNavBar = ({ prevICon, prevClick, src, title, nextICon, nextClick }) => {

  const matchesXs = useMediaQuery('(max-width:400px)');

  return (
    <Grid container sx={{ color: '#038fd4', padding: '15px 12px', background: '#ececec' }}>
      {
        prevICon &&
        <Grid item xs={3} display={"flex"} justifyContent={"flex-start"}>
          <IconButton sx={{ padding: 0 }} onClick={prevClick}><ArrowBackIosIcon sx={{ color: '#038fd4' }} /></IconButton>
        </Grid>
      }
      {(nextICon && !prevICon) && <Grid item xs={3}> </Grid>}

      <Grid item xs={6} display={"flex"} justifyContent={"center"}>
        <Stack alignItems={'center'} gap={'8px'} direction={{ xs: 'row', sm: 'row' }}>
          <CustomIcon sx={{ height: '30px', width: '35px' }} src={src} />
          <Typography fontSize={matchesXs ? "15px" : "18px"} fontWeight={500}>{title}</Typography>
        </Stack>
      </Grid>
      {nextICon &&
        <Grid item xs={3} display={"flex"} justifyContent={"flex-end"}>
          <IconButton sx={{ padding: 0 }} onClick={nextClick}><ArrowForwardIosIcon sx={{ color: '#038fd4' }} /></IconButton>
        </Grid>
      }
    </Grid>
  )
}

export default CustomNavBar;