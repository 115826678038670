// Defualt
import React, { useEffect, useState } from 'react';
import _ from "lodash";
import { useNavigate } from 'react-router-dom';

// MUI components
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

// MUI icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Services 
import { fetchGETRequest } from '../../utils/services';

// Custom
import { NavBar } from '../../components/NavBar';
import CustomNavBar from '../../components/CustomNavBar';

// Date Format
import { fDate } from "../../utils/formatTime";

// Translation
import Translation from '../../config/Translation';
import { useLoader } from '../../provider/LoaderContext';
import { SURVEYS_DETAILS_PAGE, INJECTION_PAGE, REMINDERS_PAGE } from '../../utils/pageName';


const Surveys = () => {
  const navigate = useNavigate();
  const [surveys, setSurveys] = useState([])
  const { showLoader, hideLoader } = useLoader()

  useEffect(() => {
    showLoader()
    async function getSurveysList() {
      try {
        const response = await fetchGETRequest("api/Quiz/GetQuizs", {})
        if (response?.status === 200 && response?.data?.length > 0) {
          setSurveys(response?.data)
        }
        hideLoader()
      } catch (error) {
        hideLoader()
      }
    }
    getSurveysList()
  }, []);

  return (
    <>
      <NavBar />
      <Box>
        <CustomNavBar
          prevICon={true}
          prevClick={() => navigate(`/${INJECTION_PAGE}`)}
          src={"assets/more/more_icon_survey.png"}
          title={Translation.lang().w_surveys}
          nextICon={true}
          nextClick={() => navigate(`/${REMINDERS_PAGE}`)}
        />
        {surveys &&
          surveys?.length > 0 ?
          _.map(surveys, (value, index) => (
            <div key={index}>
              <Typography
                variant="h6"

                fontWeight={500}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  background: '#16b358',
                  color: '#ffffff',
                  paddingRight: '12px',
                }}
              >
                {fDate(value?.dateCreated, 'dd-MM-yyyy')}
              </Typography>

              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 12px' }}
                onClick={() => navigate(`/${SURVEYS_DETAILS_PAGE}`, { state: value })}
              >
                <Typography variant="h5" fontWeight={500}>
                  {value?.title}
                </Typography>
                <Stack alignItems={'center'} gap={'3px'} direction={{ xs: 'row', sm: 'row' }}>
                  <Typography variant="h6" fontWeight={500}>
                    {value?.progress}
                  </Typography>
                  <IconButton sx={{ padding: 0 }}>
                    {' '}
                    <ArrowForwardIosIcon sx={{ color: '#038fd4' }} />
                  </IconButton>
                </Stack>
              </Box>
            </div>
          ))
          :
          <Typography position={"fixed"} top={"50%"} display={"flex"} justifyContent={"center"} variant="h6" fontWeight={500} color={"#a9a9a9"} width={"100%"}>
            {Translation.lang().w_no_pending_surveys}
          </Typography>
        }
      </Box>
    </>
  );
}
export default Surveys;
