// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyBubWCeNrN1gMGapcvcfkN8Tgfcj6a5oAs",
  authDomain: "itn-graduate.firebaseapp.com",
  projectId: "itn-graduate",
  storageBucket: "itn-graduate.appspot.com",
  messagingSenderId: "44241956847",
  appId: "1:44241956847:web:304eb40eb228bd0000d01a"
};


initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async() => {
  return getToken(messaging, { vapidKey: `BHk9RsxCp7xci1lULwuqal4gRO3mbwv23R_jv4yWeT3vN5m_JIvNUoEfb04M1SVATNoofWctoy9m7YAAjMb_BAE` })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        Notification.requestPermission().then((permission) => {
        });
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};


// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

  
