
import moment from 'moment';
import MomStorage from './MomStorage';
import English from './langs/english';
import Spanish from './langs/spanish';


let _languages = English;
const Translation = {
    lang: () => _languages,

    English: () => English,

    Spanish: () => Spanish,
    saveLanguage: function (locale) {
        MomStorage.saveLocale(locale);
        Translation.changeLocale(locale);
    },

    loadLanguage: function (params) {
        return MomStorage.loadLocale().then((locale) => {
            this.changeLocale(locale);
        });
    },

    getLocale: () => MomStorage.getLocale(),
    saveLanguageId: function (languageId) {
        if (languageId === 6 || languageId === '6') {
            this.saveLanguage(Spanish.Language);
        } else {
            this.saveLanguage(English.Language);
        }
    },

    getLanguageId: (locale) => {
        if (locale === Spanish.Language) {
            return Spanish.LanguageId;
        }
        return English.LanguageId;

    },

    changeLocale: (locale) => {
        moment.locale(locale.substring(0, 2));
        if (locale === Spanish.Language) {
            _languages = Spanish;
        }
        _languages = English;
    },

    getMonthName: (m) => {
        switch (m) {
            case 0:
                return _languages.w_month1;
            case 1:
                return _languages.w_month2;
            case 2:
                return _languages.w_month3;
            case 3:
                return _languages.w_month4;
            case 4:
                return _languages.w_month5;
            case 5:
                return _languages.w_month6;
            case 6:
                return _languages.w_month7;
            case 7:
                return _languages.w_month8;
            case 8:
                return _languages.w_month9;
            case 9:
                return _languages.w_month10;
            case 10:
                return _languages.w_month11;
            case 11:
                return _languages.w_month12;
            default:
                return ''
        }
    }
    ,
    getMonthShortName: (m) => {
        switch (m) {
            case 0:
                return _languages.w_mon1;
            case 1:
                return _languages.w_mon2;
            case 2:
                return _languages.w_mon3;
            case 3:
                return _languages.w_mon4;
            case 4:
                return _languages.w_mon5;
            case 5:
                return _languages.w_mon6;
            case 6:
                return _languages.w_mon7;
            case 7:
                return _languages.w_mon8;
            case 8:
                return _languages.w_mon9;
            case 9:
                return _languages.w_mon10;
            case 10:
                return _languages.w_mon11;
            case 11:
                return _languages.w_mon12;
            default:
                return ''
        }
    },
    getDifficultyName: (difficulty) => {
        switch (difficulty) {
            case 1:
                return _languages.w_dfficulty1;
            case 2:
                return _languages.w_dfficulty2;
            case 3:
                return _languages.w_dfficulty3;
            case 4:
                return _languages.w_dfficulty4;
            default:
                return ""
        }
    },
    getExerciseDifficultyName: (difficulty) => {
        switch (difficulty) {
            case 1:
                return _languages.w_exercise_dfficulty1;
            case 2:
                return _languages.w_exercise_dfficulty2;
            case 3:
                return _languages.w_exercise_dfficulty3;
            case 4:
                return _languages.w_exercise_dfficulty4;
            default:
                return ""
        }
    },
    getFeelingName: (feeling) => {
        switch (feeling) {
            case 1:
                return _languages.w_veryWell;
            case 2:
                return _languages.w_well;
            case 3:
                return _languages.w_poor;
            case 4:
                return _languages.w_veryPoor;
            default:
                return ""
        }
    },
    getFatigueName: (fatigue) => {
        switch (fatigue) {
            case 1:
                return _languages.w_fatigue1;
            case 2:
                return _languages.w_fatigue2;
            case 3:
                return _languages.w_fatigue3;
            case 4:
                return _languages.w_fatigue4;
            default:
                return ""
        }
    },
    getPainName: (pain) => {
        switch (pain) {
            case 1:
                return _languages.w_noPain;
            case 2:
                return _languages.w_mild;
            case 3:
                return _languages.w_moderate;
            case 4:
                return _languages.w_server;
            default:
                return ""
        }
    },
    // getJointNames: () => {
    //     return [
    //         { part: 1, code: 1, x: 35, y: 85, name: _languages.w_RightJaw },
    //         { part: 1, code: 2, x: 97, y: 85, name: _languages.w_LeftJaw },
    //         { part: 1, code: 3, x: 68, y: 160, name: _languages.w_Chest },
    //         { part: 1, code: 4, x: 0, y: 124, name: _languages.w_RightShoulder },
    //         { part: 1, code: 5, x: 134, y: 124, name: _languages.w_LeftShoulder },
    //         { part: 1, code: 6, x: -4, y: 190, name: _languages.w_RightElbow },
    //         { part: 1, code: 7, x: 138, y: 190, name: _languages.w_LeftElbow },
    //         { part: 1, code: 8, x: 2, y: 250, name: _languages.w_RightWrist },
    //         { part: 1, code: 9, x: 131, y: 250, name: _languages.w_LeftWrist },
    //         { part: 1, code: 10, x: 38, y: 235, name: _languages.w_RightHip },
    //         { part: 1, code: 11, x: 95, y: 235, name: _languages.w_LeftHip },
    //         { part: 1, code: 12, x: 43, y: 365, name: _languages.w_RightKnee },
    //         { part: 1, code: 13, x: 90, y: 365, name: _languages.w_LeftKnee },
    //         { part: 1, code: 14, x: 43, y: 405, name: _languages.w_RightAnkle },
    //         { part: 1, code: 15, x: 90, y: 405, name: _languages.w_LeftAnkle },
    //         { part: 1, code: 16, x: 34, y: 121, name: _languages.w_ExtraRightShoulder },
    //         { part: 1, code: 17, x: 100, y: 121, name: _languages.w_ExtraLeftShoulder },

    //         { part: 2, code: 18, x: 92, y: 219, name: _languages.w_RightLowerBack },
    //         { part: 2, code: 19, x: 40, y: 219, name: _languages.w_LeftLowerBack },
    //         { part: 2, code: 20, x: 67, y: 120, name: _languages.w_Neck },
    //         { part: 2, code: 21, x: 93, y: 423, name: _languages.w_RightHeel, hide: true },
    //         { part: 2, code: 22, x: 40, y: 423, name: _languages.w_LeftHeel, hide: true },

    //         { part: 3, code: 23, x: 49, y: 30, name: _languages.w_LeftHandThumb + " #1" },
    //         { part: 3, code: 24, x: 20, y: 65, name: _languages.w_LeftHandThumb + " #2" },

    //         { part: 3, code: 25, x: 50, y: 110, name: _languages.w_LeftHandForefinger + " #1" },
    //         { part: 3, code: 26, x: 35, y: 142, name: _languages.w_LeftHandForefinger + " #2" },
    //         { part: 3, code: 27, x: 21, y: 175, name: _languages.w_LeftHandForefinger + " #3" },

    //         { part: 3, code: 28, x: 76, y: 125, name: _languages.w_LeftHandMiddleFinger + " #1" },
    //         { part: 3, code: 29, x: 68, y: 162, name: _languages.w_LeftHandMiddleFinger + " #2" },
    //         { part: 3, code: 30, x: 59, y: 198, name: _languages.w_LeftHandMiddleFinger + " #3" },

    //         { part: 3, code: 31, x: 105, y: 130, name: _languages.w_LeftHandRingFinger + " #1" },
    //         { part: 3, code: 32, x: 100, y: 162, name: _languages.w_LeftHandRingFinger + " #2" },
    //         { part: 3, code: 33, x: 93, y: 193, name: _languages.w_LeftHandRingFinger + " #3" },

    //         { part: 3, code: 34, x: 134, y: 117, name: _languages.w_LeftHandPinkyFinger + " #1" },
    //         { part: 3, code: 35, x: 132, y: 147, name: _languages.w_LeftHandPinkyFinger + " #2" },
    //         { part: 3, code: 36, x: 127, y: 176, name: _languages.w_LeftHandPinkyFinger + " #3" },

    //         { part: 4, code: 37, x: 100, y: 30, name: _languages.w_RightHandThumb + " #1" },
    //         { part: 4, code: 38, x: 124, y: 65, name: _languages.w_RightHandThumb + " #2" },

    //         { part: 4, code: 39, x: 98, y: 110, name: _languages.w_RightHandForefinger + " #1" },
    //         { part: 4, code: 40, x: 112, y: 142, name: _languages.w_RightHandForefinger + " #2" },
    //         { part: 4, code: 41, x: 127, y: 175, name: _languages.w_RightHandForefinger + " #3" },

    //         { part: 4, code: 42, x: 71, y: 125, name: _languages.w_RightHandMiddleFinger + " #1" },
    //         { part: 4, code: 43, x: 80, y: 162, name: _languages.w_RightHandMiddleFinger + " #2" },
    //         { part: 4, code: 44, x: 89, y: 198, name: _languages.w_RightHandMiddleFinger + " #3" },

    //         { part: 4, code: 45, x: 42, y: 130, name: _languages.w_RightHandRingFinger + " #1" },
    //         { part: 4, code: 46, x: 50, y: 162, name: _languages.w_RightHandRingFinger + " #2" },
    //         { part: 4, code: 47, x: 55, y: 193, name: _languages.w_RightHandRingFinger + " #3" },

    //         { part: 4, code: 48, x: 14, y: 117, name: _languages.w_RightHandPinkyFinger + " #1" },
    //         { part: 4, code: 49, x: 17, y: 147, name: _languages.w_RightHandPinkyFinger + " #2" },
    //         { part: 4, code: 50, x: 21, y: 176, name: _languages.w_RightHandPinkyFinger + " #3" },

    //         { part: 5, code: 51, x: 44, y: 146, name: _languages.w_LeftFoot },

    //         { part: 5, code: 52, x: 114, y: 178, name: _languages.w_LeftToe1 + " a" },
    //         { part: 5, code: 53, x: 125, y: 207, name: _languages.w_LeftToe1 + " b" },
    //         { part: 5, code: 54, x: 131, y: 235, name: _languages.w_LeftToe1 + " c" },

    //         { part: 5, code: 55, x: 95, y: 198, name: _languages.w_LeftToe2 + " a" },
    //         { part: 5, code: 56, x: 103, y: 224, name: _languages.w_LeftToe2 + " b" },
    //         { part: 5, code: 57, x: 108, y: 250, name: _languages.w_LeftToe2 + " c" },

    //         { part: 5, code: 58, x: 68, y: 210, name: _languages.w_LeftToe3 + " a" },
    //         { part: 5, code: 59, x: 78, y: 237, name: _languages.w_LeftToe3 + " b" },
    //         { part: 5, code: 60, x: 85, y: 265, name: _languages.w_LeftToe3 + " c" },

    //         { part: 5, code: 61, x: 39, y: 215, name: _languages.w_LeftToe4 + " a" },
    //         { part: 5, code: 62, x: 49, y: 242, name: _languages.w_LeftToe4 + " b" },
    //         { part: 5, code: 63, x: 58, y: 272, name: _languages.w_LeftToe4 + " c" },

    //         { part: 5, code: 64, x: 5, y: 220, name: _languages.w_LeftToe5 + " a" },
    //         { part: 5, code: 65, x: 12, y: 255, name: _languages.w_LeftToe5 + " b" },

    //         { part: 6, code: 66, x: 70, y: 146, name: _languages.w_RightFoot },

    //         { part: 6, code: 67, x: 1, y: 178, name: _languages.w_RightToe1 + " a" },
    //         { part: 6, code: 68, x: -7, y: 207, name: _languages.w_RightToe1 + " b" },
    //         { part: 6, code: 69, x: -16, y: 235, name: _languages.w_RightToe1 + " c" },

    //         { part: 6, code: 70, x: 20, y: 198, name: _languages.w_RightToe2 + " a" },
    //         { part: 6, code: 71, x: 13, y: 224, name: _languages.w_RightToe2 + " b" },
    //         { part: 6, code: 72, x: 7, y: 250, name: _languages.w_RightToe2 + " c" },

    //         { part: 6, code: 73, x: 46, y: 210, name: _languages.w_RightToe3 + " a" },
    //         { part: 6, code: 74, x: 36, y: 237, name: _languages.w_RightToe3 + " b" },
    //         { part: 6, code: 75, x: 30, y: 265, name: _languages.w_RightToe3 + " c" },

    //         { part: 6, code: 76, x: 75, y: 215, name: _languages.w_RightToe4 + " a" },
    //         { part: 6, code: 77, x: 65, y: 242, name: _languages.w_RightToe4 + " b" },
    //         { part: 6, code: 78, x: 57, y: 272, name: _languages.w_RightToe4 + " c" },

    //         { part: 6, code: 79, x: 110, y: 220, name: _languages.w_RightToe5 + " a" },
    //         { part: 6, code: 80, x: 102, y: 255, name: _languages.w_RightToe5 + " b" },

    //         { part: 1, code: 91, x: 120, y: 285, name: _languages.w_LeftWrist },
    //         { part: 1, code: 92, x: 10, y: 285, name: _languages.w_RightWrist },
    //         { part: 1, code: 93, x: 95, y: 440, name: _languages.w_LeftKnee },
    //         { part: 1, code: 94, x: 39, y: 440, name: _languages.w_RightKnee },
    //     ];
    // },
    getBoardName: (board) => {
        switch (board) {
            case 1:
                return _languages.w_wiki1;
            case 2:
                return _languages.w_wiki2;
            case 3:
                return _languages.w_wiki3;
            case 4:
                return _languages.w_wiki4;
            default:
                return ""
        }
    }
}


export default Translation;