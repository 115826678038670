// MUI components
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

// Translation
import Translation from '../../config/Translation';

const RadioButton = ({ data, onChange, value }) => {
  const isENLocal = Translation.getLocale() === 'en';
  return (
    <Box display={"flex"} justifyContent={"center"}>
      {data?.map((item, i) => {
        return (
          <FormControlLabel
            key={i}
            value={item.quizPossibleAnswerID}
            control={<Radio onChange={onChange} checked={Number(value) === Number(item.quizPossibleAnswerID)} />}
            label={
              <Typography variant="subtitle1" sx={{ display: 'block', textAlign: 'center' }}>
                {isENLocal ? item.answerValue : item.answerValueES}
              </Typography>
            }
            labelPlacement="top"
          />
        )
      })}

    </Box>
  )
}
export default RadioButton;