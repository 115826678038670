import { createSlice } from "@reduxjs/toolkit"

// initial states
const initialState = {
    accessToken: null,
    fcmToken : null,
    user: { accessToken: null }
}

const reducers = {
    setAccessToken: (state, { payload }) => {
        state.accessToken = payload;
    },
    setUser: (state, { payload }) => {
        state.user = payload;
    },
    setFcmToken: (state, { payload }) => {
        state.fcmToken = payload;
    },
    logout: () => initialState,
}

// creating auth slice
const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers,
})

// exporting actions
export const { setAccessToken, logout, setUser,setFcmToken } = AuthSlice.actions

export default AuthSlice.reducer
