

let _locale = 'en';
const AsyncStorage = window.localStorage;
const MomStorage = {
    saveLocale: (locale) => {
        _locale = locale;
        return AsyncStorage.setItem("MOM_Locale", locale);
    },

    loadLocale: () => AsyncStorage.getItem("MOM_Locale").then((value) => {
        value = value || 'en';
        _locale = value;
        return value;
    }
    ),
    getLocale: () => _locale,
};

export default MomStorage
