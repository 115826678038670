import { Box, Link, Stack } from '@mui/material';



export const NavBar = () => (
    <Stack color='white' style={{ backgroundColor: "#123B6D", padding: 12 }} justifyContent={"space-between"} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }}>
        <Link href='/'>
            <Box component="img" src="assets/track/home.png" />
        </Link>
        <Link href='/summary'>
            <Box component="img" src="assets/track/dashboard.png" />
        </Link>
    </Stack>
);


