import React from 'react';
import { useNavigate } from 'react-router';
import { Divider, Stack, Typography } from '@mui/material';
import CustomIcon from '../../components/CustomIcon';
import Translation from '../../config/Translation';
import DetailNavBar from '../../components/DetailNavBar';
import { fetchGETRequest } from '../../utils/services';
import { useLoader } from '../../provider/LoaderContext';
import { AttachFile } from '@mui/icons-material';

const StudyInstructionsPage = () => {
    const { showLoader, hideLoader } = useLoader();

    const navigate = useNavigate();

    const [studyInstructionList, setStudyInstructionList] = React.useState([]);

    const clickRow = (rowData) => {
        navigate("/study-instructions-details", { state: rowData })
    }

    const getStudyInstructions = async () => {
        showLoader()
        try {
            const response = await fetchGETRequest("api/Wiki/Search?pageSize=20&board=40&page=1");
            const data = response?.data?.wikis;
            setStudyInstructionList(data)
            console.log("🚀 ~ file: StudyInstructionsPage.js:60 ~ getStudyInstructions ~ response:", response)

        } catch (error) {
            console.log("🚀 ~ file: StudyInstructionsPage.js:63 ~ getStudyInstructions ~ error:", error)

        }
        hideLoader()
    }

    React.useEffect(() => {
        getStudyInstructions()
    }, []);

    return (
        <>
            <DetailNavBar label={Translation.lang().w_resources_contact} onClick={() => navigate(-1)} />
            {studyInstructionList.map((rowData, index) =>
                <Stack key={index} onClick={() => clickRow(rowData)} >
                    <Stack alignItems={"center"} justifyContent={"space-between"} direction={{ xs: 'row', sm: 'row' }}>
                        <Stack spacing={2} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }} style={{ padding: 12 }}>
                            <AttachFile color='info' />
                            <Typography>
                                {rowData.subject}
                            </Typography>
                        </Stack>
                        <CustomIcon sx={{ marginRight: 2 }} src={"assets/arrow_right.png"} />
                    </Stack>
                    <Divider color='#666666' />
                </Stack>)
            }
        </>
    );
};

export default StudyInstructionsPage;
