// Default
import { useState } from "react";
import { useNavigate, useLocation } from "react-router"

// MUI components
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

// MUI styles
import { makeStyles } from '@mui/styles';

// Custom
import { NavBar } from "../../components/NavBar"
import CustomNavBar from "../../components/CustomNavBar"
import Dropdown from "../../forms/dropdown";
import CustomInput from "../../forms/input";

// utils
import { SURVEYS_PAGE, STUDY_INSTRUCTIONS } from "../../utils/pageName"
import Translation from "../../config/Translation"
import { INJECTION_LOCATION, HOURS, MINS, INDICATORS } from "./data";
import useAlertDialog from "../../hooks/useAlertDialog";
import { useLoader } from "../../provider/LoaderContext";
import DetailNavBar from "../../components/DetailNavBar";
import moment from "moment";

const useStyles = makeStyles({
    btn: {
        minWidth: '50px',
        minHeight: '35px',
        padding: 0,
        boxShadow: 'none'
    },
    radius1: {
        borderRadius: '4px 0 0 4px',
    },
    radius2: {
        borderRadius: '0 4px 4px 0',
    },
    bg1: {
        background: '#9f9f9f',
        color: '#ffffff',
        '&:hover': {
            background: '#9f9f9f',
            color: '#ffffff',
        },
    },
    bg2: {
        background: '#eeeeee',
        color: '#b6b6b6',
        '&:hover': {
            background: '#eeeeee',
            color: '#b6b6b6',
        },
    }
});

const InjectionDetailsPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    return (
        <>
            <DetailNavBar label={Translation.lang().w_injection} onClick={() => navigate(-1)} />
            <Grid container spacing={3} sx={{ padding: "0 12px", marginTop: "20px" }}>
                <Grid item xs={5}>
                    <Typography color={"#848484"} marginBottom={"6px"}>{Translation.lang().w_injection_location}</Typography>
                    <Dropdown disabled options={INJECTION_LOCATION} value={INJECTION_LOCATION[state?.specimenType]} />
                </Grid>
                <Grid item xs={5}>
                    <Typography color={"#848484"} marginBottom={"6px"}>{Translation.lang().w_collection_date}</Typography>
                    <CustomInput
                        type="date"
                        sx={{ height: "35px" }}
                        disabled
                        value={moment(moment.utc(state.dateTimeCollection).format()).format("YYYY-MM-DD")}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ padding: "0 12px", marginTop: "10px" }}>
                <Grid item xs={12}>
                    <Typography color={"#848484"}>{Translation.lang().w_collection_time}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Dropdown disabled options={HOURS} value={moment.utc(state.dateTimeCollection).hour()} />
                </Grid>
                <Grid item xs={3}>
                    <Dropdown disabled options={MINS} value={moment.utc(state.dateTimeCollection).minute()} />
                </Grid>
            </Grid>
        </>
    )
}

export default InjectionDetailsPage