// Default
import { useState } from 'react';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

// MUI icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Custom
import CustomInput from "../../forms/input"
import CustomButton from '../../forms/button';

// Translation
import Translation from '../../config/Translation';
import useAlertDialog from '../../hooks/useAlertDialog';

// #01b64d 
const QuestionsSetup = ({onSave}) => {
  const [open, setOpen] = useState(false);
  const alertDialog = useAlertDialog();
  const [state, setState] = useState({
    question1: null,
    question2: null,
    answer1: null,
    answer2: null,
    queType: null
  })
  console.log(state,"state");
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#ffffff',
    boxShadow: 24,
    padding: "0 20px",
    borderRadius: "8px",
    maxHeight: '70vh',
    overflowY: 'auto',
  };

  const questions = [
    Translation.lang().w_question1,
    Translation.lang().w_question2,
    Translation.lang().w_question3,
    Translation.lang().w_question4,
    Translation.lang().w_question5,
    Translation.lang().w_question6,
    Translation.lang().w_question7,
    Translation.lang().w_question8,
    Translation.lang().w_question9,
    Translation.lang().w_question10,
    Translation.lang().w_question11,
    Translation.lang().w_question12,
    Translation.lang().w_question13,
    Translation.lang().w_question14,
    Translation.lang().w_question15,
  ]
  const onSaveValue = () => {
    const { question1, question2, answer1, answer2 } = state;
    console.log({ question1, question2, answer1, answer2 },"Aasasnansajssa");
    if (question1 == null || question2 == null) {
      alertDialog({
        title: Translation.lang().w_question,
        desc: Translation.lang().w_questionRequired
      })
      return;
    } else if (question1 == question2) {
      alertDialog({
        title: Translation.lang().w_question,
        desc: Translation.lang().w_questionDifferent
      });
      return;
    }
    else if (!answer1 || !answer2) {
      alertDialog({
        title: Translation.lang().w_answer,
        desc: Translation.lang().w_answerRequired
      })
      return;
    }else{
      onSave(state)
    }
  }

  const getQuestions = (label, type) => (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 15px", background: "#01b64d", borderRadius: "8px 8px 0 0" }} onClick={() => {
      setState({ ...state, queType: type });
      setOpen(true);
    }}>
      <Typography paragraph sx={{ fontSize: "14px", margin: 0, color: "#ffffff" }}>{label}</Typography>
      <ExpandMoreIcon sx={{ color: "#ffffff" }} />
    </Box>
  )
  const setValue = (type,value) => {
    setState({ ...state,[type] : value });
    setOpen(false)
  }
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", padding: "40px 20px 30px 20px" }}>
        <Box>
          {getQuestions(questions[state.question1] || Translation.lang().w_selectQuestion, 1)}
          <CustomInput
            type={'text'}
            value={state.answer1}
            placeholder={Translation.lang().w_questionAnswer1}
            name={'question1'}
            sx={{ borderRadius: "0 0 8px 8px" }}
            onChange={(event)=> {
              setValue("answer1",event.target.value)
            }}
          />
        </Box>
        <Box>
          {getQuestions(questions[state.question2] || Translation.lang().w_selectQuestion, 2)}
          <CustomInput
            type={'text'}
            value={state.answer2}
            placeholder={Translation.lang().w_questionAnswer2}
            name={'question2'}
            sx={{ borderRadius: "0 0 8px 8px" }}
            onChange={(event)=> {
              setValue("answer2",event.target.value)
            }}
          />
        </Box>

        <CustomButton onClick={onSaveValue} label={Translation.lang().w_next} />
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          {questions.map((item,index) => <Typography onClick={() => {
            const type = state.queType == 1 ? "question1" : "question2"
            setValue(type,index);
          }} sx={{ padding: "8px 0", color: "#9a9a9a", borderBottom: "1px solid #e8e8e8" }}>{item}</Typography>)}
        </Box>
      </Modal>
    </>
  )
}

export default QuestionsSetup