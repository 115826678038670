const English = {
  w_Warning: "Warning",
  w_underConstruction: "Under Construction",
  w_welcome1: "Welcome! The reuma2go will help you track and document your JIA",
  w_welcomeTitle2: "Personalize for you",
  w_welcome2:
    "Welke activiteiten je gedaan hebt en welke medicijnen je gebruikt.",
  w_welcomeTitle3: "Track your progress",
  w_welcome3:
    "Heb je gegevens ingevuld, dan kan je een overzicht maken en deze delen met anderen.",
  w_skip: "Skip",
  w_or: "or",
  w_email: "Email",
  w_refresh: "Refresh",

  w_password: "Password",
  w_updatePassword: "Update Password",
  w_setPassword: "Set Password",
  w_passwordNew: "New Password",
  w_passwordConfirmed: "Confirm Password",
  w_passwordRequired: "Password is required.",
  w_passwordTooShort: "Password is too short.",
  w_passwordNotMatch: "Password does not match.",
  w_passwordUpdated: "Password is updated.",
  w_passwordNotMatchRule:
    'Passwords must have at least one non letter or digit character.\nPasswords must have at least one lowercase ("a"-"z").\nPasswords must have at least one uppercase ("A"-"Z").',

  w_pin: "PIN",
  w_updatePin: "Update PIN",
  w_setPin: "Set PIN",
  w_pinNew: "New PIN",
  w_pinConfirmed: "Confirm PIN",
  w_pinTooShort: "PIN is too short.",
  w_pinNotMatch: "PIN does not match.",

  w_updateName: "Update Name",
  w_updateGender: "Update Gender",
  w_updateDOB: "Update Date Of Birth",
  w_updateNickname: "Update Nickname",
  w_updateProfilePicture: "Update Profile Picture",

  w_invalidLoginTitle: "Invalid Log In",
  w_invalidLoginContent:
    "Your attempt to log in is invalid. Please contact: clientsupport@myownmed.com",
  w_loginFailed: "Login Failed",
  w_loginFailed3Times:
    "Your login attempt failed. Please contact: clientsupport@myownmed.com",
  w_usernamePasswordIncorrect: "The username or password is incorrect.",
  w_failed: "Failed",
  w_serverFailure: "Server failure.",
  w_signin: "Sign In",
  w_signout: "Sign Out",
  w_signinRequired: "Email and password are required.",
  w_passwordNotCorrect: "The user name or password is incorrect.",
  w_pinNotCorrect: "The pin is incorrect.",
  w_forgotPassword: "Forgot password",
  w_forgotPin: "Forgot PIN",

  w_reset: "Reset",
  w_resetFailed: "Reset failed",
  w_emailIncorrect: "The Email is incorrect.",
  w_resetPassword: "Reset Password",
  w_answerQuestions: "Answer Security Questions",
  w_enterTokenBelow: "Please enter the token below.",
  w_token: "TOKEN",
  w_validate: "Validate",
  w_validateToken: "Validate Token",
  w_validationSuccess: "Validation Success",
  w_validationFailed: "Validation Failed",
  w_invalidToken: "The token is invalid.",
  w_incorrectAnswers: "The answers are incorrect.",
  w_resetYourPassword: "Please reset your password.",
  w_back: "Back",
  w_accept: "I Accept",
  w_emailResetPwd:
    "Please enter your registered email, we will send you an instruction to reset your password",
  w_Enter_your_email_address: "Enter your email address",
  w_track: "Home",
  w_calendar: "Calendar",
  w_shareCalendar: "Share",
  w_sendCalendar: "Send Calendar",
  w_calendarSentSuccess: "Appointment was shared",
  w_calendarSentFailure: "Failed to share appointment",
  w_shareAppointment: "Share Appointment",
  w_message: "Message",
  w_noMessage: "You have no messages.",
  w_newChat: "New Chat",
  w_tapToConversation: "Tap on the icon to start a new conversation.",
  w_searchPeopleMessage: "Search for people and message",
  w_doYouDeleteMessages: "Are you sure to delete messages?",
  w_imageMessage: "IMAGE",
  w_typeAMessage: "Type a message...",
  w_more: "More",
  w_settings: "Settings",
  w_next: "Next",
  w_whatName: "What’s your name",
  w_name: "Name",
  w_firstName: "First name",
  w_lastName: "Last name",
  w_nameRequired: "First name and last name are required",
  w_dateOfBirth: "Date of Birth",
  w_month1: "January",
  w_month2: "February",
  w_month3: "March",
  w_month4: "April",
  w_month5: "May",
  w_month6: "June",
  w_month7: "July",
  w_month8: "August",
  w_month9: "September",
  w_month10: "October",
  w_month11: "November",
  w_month12: "December",
  w_mon1: "Jan",
  w_mon2: "Feb",
  w_mon3: "Mar",
  w_mon4: "Apr",
  w_mon5: "May",
  w_mon6: "Jun",
  w_mon7: "Jul",
  w_mon8: "Aug",
  w_mon9: "Sep",
  w_mon10: "Oct",
  w_mon11: "Nov",
  w_mon12: "Dec",
  w_everyDay0: "Every Sunday",
  w_everyDay1: "Every Monday",
  w_everyDay2: "Every Tuesday",
  w_everyDay3: "Every Wednesday",
  w_everyDay4: "Every Thursday",
  w_everyDay5: "Every Friday",
  w_everyDay6: "Every Saturday",
  w_dayShort0: "Sun",
  w_dayShort1: "Mon",
  w_dayShort2: "Tue",
  w_dayShort3: "Wed",
  w_dayShort4: "Thu",
  w_dayShort5: "Fri",
  w_dayShort6: "Sat",
  w_weekdays: "Weekdays",
  w_weekends: "Weekends",
  w_never: "Never",
  w_gender: "Gender",
  w_boy: "Boy",
  w_girl: "Girl",
  w_profile: "Profile",
  w_loadProfileFail: "Failed to load profile. Please sign in again.",
  w_profilePicture: "Profile Picture",
  w_uploadProfilePhoto:
    "Upload your profile picture to help staff recognize you.",
  w_takePhoto: "Take a photo",
  w_fromAlbum: "From album",
  w_nickname: "Nickname",
  w_youNickname: "Your Nickname",
  w_nicknameUsedFor: "The nickname will be used when you send a message.",
  w_dashboard: "Summary",
  w_hadnodata: "You have no data yet",
  w_dashboardIntroduction: "Return to the Home page to record your injection.",
  w_averageMorningStiffness: "Average time morning stiffness",
  w_startTracking: "Start Tracking Now",
  w_veryWell: "Very well",
  w_well: "Well",
  w_poor: "Poor",
  w_veryPoor: "Very poor",
  w_lastWeek: "Last week",
  w_lastMonth: "Last month",
  w_selectHistory: "Select history",
  w_select90days: "Last 3 months",
  w_jointPain: "Joint Pain",
  w_noPain: "No pain",
  w_mild: "Mild",
  w_moderate: "Moderate",
  w_server: "Severe",
  w_jointPainGraph: "Joint Pain Graph",
  w_painFree: "Pain free",
  w_painFreeDay: "pain free day",
  w_painFreeDays: "pain free days",

  w_fatigueLevel: "Fatigue level",
  w_fatigue1: "Not tired at all",
  w_fatigue2: "A bit tired",
  w_fatigue3: "Much fatigued",
  w_fatigue4: "Very fatigued",
  w_tired1: "No fatique",
  w_tired2: "A bit tired",
  w_tired3: "Sluggish",
  w_tired4: "Exhausted",
  w_morningStiffness: "Morning Stiffness",
  w_noMorningStiffness: "No morning stiffness",
  w_howTiredTodayAverage: "How tired are you on average?",
  w_overall: "Overall",
  w_activity: "Activity",
  w_fatigue: "Fatigue",
  w_medication: "Medication",
  w_createReport: "Create Report",
  w_recipientEmail: "Recipent e-mail",
  w_emailRequired: "Email is required",
  w_reportItemRequired: "Please select report items",
  w_reportSentSuccess: "Report was sent",
  w_reportSentFailure: "Failed to send report",
  w_noValidEmail: "Email is not valid",
  w_medication_reminders: "GRAZAX®/Placebo Tablet",
  w_medication_reminders_text1:
    'To add a reminder for your\nGRAZAX®/Placebo Tablet, select the "Add a reminder" link below.',
  w_what_time_do_you:
    "What time do you want to be reminded\n to take your tablet?",
  w_needYourHelp: "I need your help",
  w_howFeelingToday: "How are you feeling today?",
  w_howFeelingOverall: "How are you feeling overall?",
  w_doYouHaveJointPain: "Do you have joint pain?",
  w_update: "Update",
  w_select: "Please Select",
  w_enterHere: "Enter Here",
  w_selectDateToUpdate: "Select the date you want to update?",
  w_selectDate: "Select date",
  w_addNewReminder: "Add Reminder",
  w_reminders: "Tablet Reminders",
  w_reminder: "Reminder",
  w_stopReminder: "Stop Reminder",
  w_confirmStopAppointment: "Do you want to stop this reminder?",
  w_everyday: "Everyday",
  w_repeat: "Repeat",
  w_AMOrPM: "AM or PM",
  w_newNote: "New note",
  w_addNote: "Add Notes",
  w_deleteNote: "Delete Note",
  w_pressHold: "Press and hold to record",
  w_micophoneAccessNeeded: "Please enable microphone access for this app",
  w_uploadNewPhoto: "Upload a new photo",
  w_cancel: "Cancel",
  w_delete: "Delete",
  w_close: "Close",
  w_share: "Share",
  w_confirmation: "Confirmation",
  w_deleteTextNote: "Delete Text Note",
  w_deletePhotoNote: "Delete Photo Note",
  w_selectPhoto: "Select Photo",

  w_deleteAudioNote: "Delete Audio Note",
  w_audioNoteFailed: "Failed to upload audio note",
  w_uploading: "uploading...",
  w_edit: "Edit",
  w_ok: "OK",
  w_add: "Add",
  w_today: "Today",
  w_yesterday: "Yesterday",
  w_typeInActivityName: "Type Activity Name",
  w_activityName: "Activity name",
  w_addActivity: "Add an Activity",
  w_activityNameRequired: "Activity name is required.",
  w_bathing: "Bathing",
  w_gettingDressed: "Getting dressed",
  w_cooking: "Cooking",
  w_walking: "Walking",
  w_biking: "Biking",
  w_dailyLife: "Daily life",
  w_washing: "Washing",
  w_school: "School",
  w_goingOut: "Going out",
  w_playingWithFriend: "Playing with friends",
  w_playingOutdoor: "Playing outdoor",
  w_traveling: "Traveling",
  w_paintingOrDrawing: "Painting or drawing",
  w_dfficulty1: "No difficulty",
  w_dfficulty2: "Some difficulty",
  w_dfficulty3: "Much difficulty",
  w_dfficulty4: "Unable to do",
  w_done: "Done",
  w_save: "Save",
  w_pleaseSave: "Please save tracking entries",
  w_howLongMorningStiffLast: "How long did your morning stiffness last?",
  w_hour: "Hour",
  w_min: "Minute",
  w_minutes: "Minutes",
  w_addMedication: "Add a medicine",
  w_typeInMedicationName: "Please type in medication name",
  w_selectMedication: "Select Medication",
  w_medNameRequired: "Medication name is required.",
  w_strength: "Strength",
  w_form: "Form",
  w_dateStarted: "Date started",
  w_dateStopped: "Date stopped",
  w_pickDate: "Pick a date",
  w_singleDose: "Single Dose Amount",
  w_schedule: "Schedule",
  w_frequency: "Frequency",
  w_note: "Note",
  w_notes: "Notes",
  w_reason: "Reason",
  w_reasonRequired: "Please provide reason to cancel appointment.",
  w_writeNote: "Write down your note",
  w_review: "Review",
  w_reviews: "Reviews",
  w_addReview: "Add a review",
  w_writeComment: "Write down your comment",
  w_postAsAnonymous: "Post as anonymous",

  w_stopTakingMedicine: "Stop taking this medicine",
  w_stopMedicine: "Stop Medicine",
  w_doYouStopMedicine: "Do you want to stop this medicine?",
  w_dateMedDeleted: "Select date to stop medication",
  w_ReasonMedDeleted: "Enter reason to stop medication",

  w_Questions: "Questions?",
  w_question: "Security Question",
  w_selectQuestion: "Please select question",
  w_updateQuestions: "Update Security Questions",
  w_setQuestions: "Answer Security Questions",
  w_questionAnswer1: "Question One Answer",
  w_questionAnswer2: "Question Two Answer",
  w_questionRequired: "Security questions are required.",
  w_questionDifferent: "Different questions are required.",
  w_answer: "Answer",
  w_answerRequired: "Answers are required.",

  w_question1: "What city were you born in?",
  w_question2: "What is your youngest sibling’s name?",
  w_question3: "What is your favorite color?",
  w_question4: "What is the name of your first pet?",
  w_question5: "What is the middle name of your oldest child?",
  w_question6: "What is your childhood nickname?",
  w_question7: "What is your favorite team?",
  w_question8: "What is your favorite movie?",
  w_question9: "What is your favorite food as a child?",
  w_question10: "What was the make and model of your first car?",
  w_question11: "What is the name of the hospital where you were born?",
  w_question12: "What school did you attend for 6th grade?",
  w_question13: "In what town was your first job?",
  w_question14:
    "What was the name of the company where you had your first job?",
  w_question15: "In what city or town did your parents meet?",
  w_deleteActivity: "Delete Activity",
  w_doYouStopTrackingActivity: "Do you want to stop tracking this activity?",
  w_Chest: "Chest",
  w_LeftElbow: "Left Elbow",
  w_LeftHip: "Left Hip",
  w_LeftJaw: "Left Jaw",
  w_LeftKnee: "Left Knee",
  w_LeftShoulder: "Left Shoulder",
  w_LeftWrist: "Left Wrist",
  w_Neck: "Neck",
  w_RightElbow: "Right Elbow",
  w_RightHip: "Right Hip",
  w_RightJaw: "Right Jaw",
  w_RightKnee: "Right Knee",
  w_RightShoulder: "Right Shoulder",
  w_RightWrist: "Right Wrist",
  w_LeftLowerBack: "Left Lower Back",
  w_RightLowerBack: "Right Lower Back",
  w_LeftHandForefinger: "Left Hand Forefinger",
  w_LeftHandMiddleFinger: "Left Hand Middle Finger",
  w_LeftHandPinkyFinger: "Left Hand Pinky Finger",
  w_LeftHandRingFinger: "Left Hand Ring Finger",
  w_LeftHandThumb: "Left Hand Thumb",
  w_RightHandForefinger: "Right Hand Forefinger",
  w_RightHandMiddleFinger: "Right Hand Middle Finger",
  w_RightHandPinkyFinger: "Right Hand Pinky Finger",
  w_RightHandRingFinger: "Right Hand Ring Finger",
  w_RightHandThumb: "Right Hand Thumb",
  w_ExtraRightShoulder: "Rechter AC gewricht",
  w_ExtraLeftShoulder: "Linker AC gewricht",
  w_LeftAnkle: "Left Ankle",
  w_LeftHeel: "Left Heel",
  w_LeftFoot: "Linker voet",
  w_LeftToe1: "Left Toe #1",
  w_LeftToe2: "Left Toe #2",
  w_LeftToe3: "Left Toe #3",
  w_LeftToe4: "Left Toe #4",
  w_LeftToe5: "Left Toe #5",
  w_RightAnkle: "Right Ankle",
  w_RightHeel: "Right Heel",
  w_RightFoot: "Right Foot",
  w_RightToe1: "Right Toe #1",
  w_RightToe2: "Right Toe #2",
  w_RightToe3: "Right Toe #3",
  w_RightToe4: "Right Toe #4",
  w_RightToe5: "Right Toe #5",

  w_strengthUnit: "Strength Unit",
  w_strength1: "g",
  w_strength2: "mg",
  w_strength3: "mcg",
  w_strength4: "mL",
  w_strength5: "mg/mL",
  w_strength6: "mg/L",
  w_strength7: "mcg/mL",
  w_strength8: "tsp",

  w_dosageAERO: "Aerosol",
  w_dosageBALM: "Balm",
  w_dosageCAPS: "Capsule",
  w_dosageCONC: "Concentrate",
  w_dosageCREA: "Cream",
  w_dosageINJ: "Injection",
  w_dosageLOTN: "Lotion",
  w_dosageOINT: "Ointment",
  w_dosagePOWD: "Powder",
  w_dosageSOLN: "Solution",
  w_dosageSTRP: "Strip",
  w_dosageSUSP: "Suspension",
  w_dosageSYRP: "Syrup",
  w_dosageTABS: "Tablet",

  w_time: "Time",
  w_times: "times",
  w_daily: "Daily",
  w_weekly: "Weekly",
  w_biWeekly: "Bi-weekly",
  w_monthly: "Monthly",

  w_export: "Export",

  w_appointment: "Appointment",
  w_appointmentType: "Appointment Type",

  w_general: "General",
  w_followUp: "Follow-up",

  w_appointmentWith: "meet",

  w_medicalStaff: "Medical Staff",
  w_appointmentFieldsRequired: "Appointment type and time are required.",
  w_cancelAppointment: "Cancel the appointment",
  w_confirmCancelAppointment: "Do you want to cancel this appointment?",

  w_appointStatus0: "New Requested",
  w_appointStatus10: "Confirmed",
  w_appointStatus20: "Reschedule Requested",
  w_appointStatus30: "Rescheduled",
  w_appointStatus40: "Request Declined",
  w_appointStatus50: "Cancelled",

  w_appointmentDate: "Appointment Date",
  w_appointmentLocked:
    "Cannot cancel or reschedule appointment less than 48 hrs prior.",
  w_appointmentSent: "The request has been sent to the medical staff.",

  w_language: "Language",
  w_termOfUse: "Terms of Use",
  w_consentTermOfUse: "Terms of Use",
  w_privacyPolicy: "Privacy Policy",
  w_conditions: "Conditions",

  w_educationMaterial: "Education Material",
  w_rateThisApp: "Rate this app",
  w_communityForum: "Community Forum",
  w_commonQuestions: "Common Questions",
  w_faq: "FAQ",
  w_login: "Login",
  w_version: "Version ",

  w_changePasswordSuccess: "You have successfully reset your password.",
  w_changePinSuccess: "You have successfully reset your PIN.",
  w_changeNameSuccess: "Your name has been successfully updated.",
  w_changeGenderSuccess: "Your gender has been successfully updated.",
  w_changeDOBSuccess: "Your date of birth has been successfully updated.",
  w_changeNicknameSuccess: "Your nickname has been successfully changed.",
  w_changeLanguageSuccess: "Your language has been successfully changed.",
  w_changeAvatarSuccess: "Your profile picture has been successfully changed.",
  w_noCameraAccess:
    "This app does not have access to your camera. You can enable access in Privacy Settings.",
  w_noImageLibraryAccess:
    "This app does not have access to your photos or videos. You can enable access in Privacy Settings.",

  w_tap1: "Tap once for mild pain",
  w_tap2: "Tap twice for moderate pain",
  w_tap3: "Tap three times for severe pain",
  w_tap4: "Tap “No Pain” to reset all joints with no pain",
  w_getStarted: "Get started",

  w_wiki: "Announcements",
  w_wiki1: "School",
  w_wiki2: "Young & Rheumatism",
  w_wiki3: "Parents",
  w_wiki4: "Reuma@umcutrech.nl",
  w_searchWiki: "Search",
  w_post: "Post",
  w_noResultsFor: "No results for",
  w_noWikisFound: "No wikis found",

  w_english: "English",
  w_dutch: "Dutch",
  w_spanish: "Spanish",

  w_networkError: "Network or server request failed",

  w_privacy: "Privacy",

  w_nutrition: "Nutrition",
  w_exercise: "Exercise",
  w_exerciseTime: "Exercise",
  w_drink: "Drink Consumption",
  w_cleansing: "Cleansing",
  w_alcohol: "Alcohol Consumption",
  w_goals: "Goals",
  w_sleep: "Sleep",
  w_symptoms: "Symptoms",
  w_distressScale: "Distress Scale",
  w_mood: "Mood",
  w_specimen: "Specimens",
  w_injection_updated: "Injection Updated",
  w_your_specimen_collection_has_been_saved: "Your injection has been saved.",
  w_injection: "Injection",
  w_surveys: "Surveys",

  w_reported_symptoms: "Reported Symptoms",
  w_reported_symptoms_summary: "Reported Symptoms Summary",
  w_portionsOfFruit: "portions of fruit",
  w_portionsOfVegetable: "portions of vegetable",
  w_onATypicalDay: "on a typical day",
  w_day: "day",

  w_typeInExerciseName: "Type exercise",
  w_exerciseName: "Exercise name",
  w_addExercise: "Add Exercise",
  w_exerciseNameRequired: "Exercise name is required.",
  w_deleteExercise: "Delete Exercise",
  w_doYouStopTrackingExercise: "Do you want to stop tracking this exercise?",
  w_exercise_Aerobics: "Aerobics",
  w_exercise_Baseball: "Baseball",
  w_exercise_Basketball: "Basketball",
  w_exercise_Biking: "Biking",
  w_exercise_Bowling: "Bowling",
  w_exercise_FieldHockey: "Field hockey",
  w_exercise_Football: "Football",
  w_exercise_Golfing: "Golfing",
  w_exercise_IceHockey: "Ice hockey",
  w_exercise_Jogging: "Jogging",
  w_exercise_Running: "Running",
  w_exercise_Skateboarding: "Skateboarding",
  w_exercise_dfficulty1: "Did not do",
  w_exercise_dfficulty2: "Mild",
  w_exercise_dfficulty3: "Moderate",
  w_exercise_dfficulty4: "Strenuous",

  w_drink_howMuchDrink: "How much did you drink?",
  w_drink_unit: "OZ",
  w_drink_quickAdd: "QUICK ADD",
  w_drink_1glass: "1 glass",
  w_drink_1bottle: "1 bottle",
  w_drink_1lgBottle: "1 lg bottle",
  w_drink_1glass_unit: "(8 fl oz)",
  w_drink_1bottle_unit: "(16 fl oz)",
  w_drink_1lgBottle_unit: "(24 fl oz)",
  w_drink_unit_fl_oz: "fl oz",
  w_averageDrink: "Average # fl. oz. drank per day",
  w_averageNutritionFruit: "Average portions of fruit",
  w_averageNutritionVegetables: "Average portions of vegetables",

  w_press_again_exit_app: "Press again to exit the application",
  w_patientInstructions: "Patient Instructions",

  w_cleansing_body: "Cleansing Body",
  w_cleansing_hand: "Cleansing Hands",
  w_cleansing_tip:
    "Tap on the ‘Cleansing-Hands’ checkbox each time you wash your hands.\r\n\r\nTap on the ‘Cleansing-Body’ checkbox each time you wash your body.",
  w_averageCleansingBody: "Average wash body",
  w_averageCleansingHands: "Average wash hands",

  w_setting_security: "SECURITY",
  w_setting_profile: "PROFILE",
  w_setting_legal: "LEGAL",

  w_did_you_drink: "Did you drink an alcoholic beverage in the last 24 hours? ",
  w_register_drink_num:
    "Please register the number of drinks of each beverage below.",
  w_register_offpasses_num:
    "Please register the number of off-passes used below.",
  w_offpasses_used: "# of passes used",
  w_beer: "350 ml / 12 oz\nBeer or Cider",
  w_wine: "150 ml / 5 oz\nWine",
  w_liquor: "45 ml / 1.5 oz\nLiquor",
  w_yes: "Yes",
  w_no: "No",

  w_journal: "Journal",
  w_play: "Play",
  w_playing: "Playing...",
  w_pause: "Pause",
  w_you: "You",
  w_delete_journal_confim:
    "Are you sure you want to remove this journal entry?",
  w_no_journal: "No journal entries",
  w_no_journal_desc:
    "Record observations about how you feel, side effects, health events or anything you would like to remember that has happened to you.",
  w_add_journal: "Add a new entry",
  w_takeVideo: "Take a video",
  w_fromVideoAlbum: "From video album",
  w_date_format: "DD-MM-YYYY",
  w_time_format: "h:mm",
  w_datetime_format: "DD-MM-YYYY h:mm",
  w_picker_select: "Select",
  w_loading: "loading...",
  w_survey_completed: "Thank you for completing the {0} survey.",
  w_choose_profile_tip:
    "Changes profiles here when you manage multiple profiles. If you see only ONE profile listed below, then you are the only user of the app.",

  w_Report_Symptoms: "Report Symptoms",
  w_Triage: "Contact",
  w_checkin_now: "Check-In Now",
  w_select_recipients:
    "Select one of the recipients below to receive your message.",

  w_resources: "Resources",
  w_resources_news: "News",
  w_resources_education: "Study Information",
  w_resources_faq: "FAQ",
  w_resources_contact: "Study Instructions",
  w_resources_tip:
    "Select a board below to get the latest news, answers to questions, or connect with your care team.",

  w_checkin_tip:
    "If you need help or want to check-in with the study staff, please use one of the buttons below.",
  w_checkin_tip_2:
    "Please call 911 if you have an emergency or urgent medical question.",
  w_non_urgent: "NON - URGENT",
  w_urgent: "URGENT",
  w_non_urgent_tip: "Problems that can wait\nan hour for a return call",
  w_urgent_tip: "Problems that need a return\ncall as soon as possible",
  w_call: "Call",
  w_send_email: "Send Email",
  w_let_the_study_know:
    "Let the study staff know that you are here. Press the ",
  w_please_contact_the_GRADUATE: "Please contact the GRADUATE study site.",
  w_button: " button.",
  w_med_nameRequired: "Name Required !",
  w_med_strengthRequired: "Strength Required !",
  w_med_strengthUnitRequired: "Strength Unit Required !",
  w_med_dosageFormRequired: "Form Required !",
  w_med_doseAmountRequired: "Singel Dose Amount Required !",
  w_med_scheduledTimesRequired: "Scheduled Times Required !",
  w_med_doseQuantityPeriodRequired: "How Often Required !",
  w_med_doseTimesPerPeriodRequired: "Frequency Required !",
  w_med_name: "Name",
  w_med_strength: "Strength",
  w_med_strengthUnit: "Strength Unit",
  w_med_doseForm: "Form",
  w_med_doseAmount: "Single Dose Amount",
  w_med_dateStarted: "Date Started",
  w_med_schedule: "Schedule",
  w_med_scheduled: "Planned",
  w_med_asNeed: "As Need",
  w_med_asNeeded: "As Needed",
  w_med_doseQuantityPeriod: "How Often",
  w_med_doseTimesPerPeriod: "Frequency",
  w_med_scheduledTimes: "Scheduled Times",
  w_med_Reminder: "Reminder",
  w_med_addAReminder: "Add a reminder",
  w_med_refills: "Refills",
  w_med_refillReminder: "Refill Reminder",
  w_med_optionalData: "Optional Data",
  w_med_rxNumber: "Rx Number",
  w_med_takenFor: "Taken For",
  w_med_notities: "Notes",
  w_med_stopMedication: "Stop Mediciation",
  w_med_newMedication: "New Medication",
  w_med_typeSelectionTip: "How would you like to add your medication?",
  w_med_searchByName: "Search by Name",
  w_med_customMedication: "Custom Medication",
  w_med_searchByNamePlaceholder: "Enter medication name",
  w_med_selectTakenForTitle: "Select Taken For",
  w_med_dateStop: "Stop Date",
  w_med_stopReason: "Stop Reason",
  w_med_selectDoseTimeWarning: "You must enter a dose time.",
  w_med_selectDayofWeekWarning: "Please select day of week !",
  w_med_selectDayofMonthWarning: "Please select day of month !",
  w_med_dayOfWeek: "Day Of Week",
  w_med_dayOfMonth: "Day Of Month",
  w_med_notitiesTip:
    "Add or edit notes related to instructions or side effects you may be experiencing while taking this medication.",
  w_med_takenForTip: "What are you taking this for?",
  w_med_repeatDayRequired: "Please select repeat day !",
  w_med_takenOn: "Taken On {0}",
  w_med_skippedOn: "Skipped On {0}",
  w_med_scheduledFor: "Scheduled for",
  w_med_addMedication: "Add Medication",
  w_med_editMedication: "Edit Medication",
  w_med_scheduledTimesTip: "What times would you like to take your medication?",
  w_med_stopReasonRequired: "Stop Reason Required !",
  w_med_noSearchResultTitle: "No Search Results",
  w_med_noSearchResultContent:
    "No medicines were found that matched the search criteria you have entered. Do you want to add your medication manually?",
  w_med_noSearchResultTryAgain: "Try again",
  w_med_noSearchResultAddCustom: "Add Custom Medication",
  w_med_pickerStrengthUnitTip: " ",
  w_med_pickerDoseFormTip: " ",
  w_med_pickerDoseAmountTip:
    "Select the amount of medicine you take at one time (e.g. 2 tablets)",
  w_med_pickerDoseQuantityPeriodTip:
    "How often do you need to take your medication?",
  w_med_pickerDoseTimesPerPeriodTip:
    "How many times do you need to take your medication?",
  w_med_NA: "N/A",
  w_med_off: "Off",
  w_med_selectDay: "Select Day",
  w_med_activeMedications: "Active Medications",
  w_med_inActiveMedications: "Inactive Medications",
  w_med_refillReminderTip:
    "When would you like to be reminded to refill your prescription?",
  w_med_refillReminderStartDate: "Reminder Start Date",
  w_med_refillReminderRepeatEvery: "Repeat Every",
  w_med_refillReminderFormat: "Every {0} days",
  w_med_none: "None",
  w_med_day: "Day",
  w_med_days: "days",
  w_med_information: "Information",
  w_med_takeReasonForPlacehold: "Type reason for",
  w_med_doseTimeFormat: "{0} Dose Time",
  w_hourOfSleep: "hours of sleep",

  w_share_appointment: "Share Appointment",
  w_summary: "Summary",
  w_specimen_summary: "Specimen Summary",
  w_injection_summary: "Injection Summary",
  w_survey_summary: "Survey Summary",
  w_summary_no_data_title: "You have no data yet",
  w_summary_no_data_message:
    "You can use this app as easy as a touch of a screen, providing a simple, easy way to manage your health today, tomorrow, or last week. Get started now.",
  w_summary_start_tracking: "Start Tracking Now",
  w_injection_location: "Injection Location",

  w_specimen_shipment_detail: "Shipment Detail",
  w_collection_time_hour: "Hour",
  w_collection_time_minutes: "Mins",
  w_update_success: "Update Success!",
  w_welcome_initial: "Welcome to the GRADUATE Study!",
  w_welcome_back: "Welcome back ",

  w_checkin_title: "You Have Checked-In",
  w_checkin_content:
    "Thank you! A study staff member will contact you shortly.",

  w_checkin_send_email_success:
    "Email successfully sent. One of our study staff will be in touch with you within 48 business hours. Thank you for contacting us.",
  w_checkin_send_email_failed: "Email failed to send!",
  w_going_to_hospital: "Going To Hospital",
  w_delivery: "Delivery",
  w_concact_email: "hayfevertrial@imperial.ac.uk",
  w_concact_phone: "07707 280143",
  w_contact_study_staff: "Contact Study Staff",
  w_non_urgent_questions_tip:
    "For non-urgent questions only.? The study staff will be in touch with you within 48 business hours.",

  w_visit: "Visit",
  w_select_topic: "Select what your topic is about:",
  w_please_select_a_topic: "Please select a topic!",
  w_topic1: "Health Issues",
  w_topic2: "Sample Collections",
  w_topic3: "Upcoming visit",
  w_topic4: "General",
  w_question_submitted_successfully:
    "Your question has been submitted successfully!",
  w_please_select_a_question: "Please select a question!",
  w_no_questions_for_topic: "No questions for this topic",
  w_select_your_question: "Select your question: ",

  w_tap_on_the: "Tap on the ",
  w_new_message: "New Message",
  w_start_new_conversation: " button to start a new conversation",

  w_no_complete_survey: "No completed surveys",
  w_no_pending_surveys: "No pending surveys",
  w_no_survey_results: "No survey results",
  w_pending_surveys: "PENDING SURVEYS",
  w_continue_complete_survey:
    "Are you sure you do not want to continue and complete the survey?",
  w_answers_saved: "Your answers have been saved.",
  w_thank_you_for_completing_survey: `Thank you for completing the ${0} survey. The study team will follow-up with you if they have any questions.`,
  w_confirm: "Confirm",
  w_survey_progress: `You are ${0}% complete`,
  w_results: "Results",
  w_prev: "Prev",
  w_begin: "Begin",

  Loss_of_Taste: "Loss of Taste/Smell",
  Loss_of_Smell: "Nasal Congestion",
  RunnyNose_green: "Runny Nose",
  Shortness_of_Breath: "Shortness of Breath",
  Cough: "Cough",
  Headache: "Headache",
  Body_Aches: "Body Aches",
  Sore_Throat: "Sore Throat",
  Fatigue: "Severe Fatigue",
  Fever: "Fever",
  Vomiting: "Vomiting",
  Diarrhea: "Vomiting / Diarrhea",
  Chills: "Chills",

  w_success: "Success",
  w_specimen_collection_saved: "Your injection has been saved.",
  w_injection_saved: "Injection Saved",
  w_injection_not_saved: "Injection Not Saved",
  w_injection_not_allow_to_save: "Injection does not allow to save",
  w_specimen_types1: "Infant's stool",
  w_specimen_types2: "Mother's stool",
  w_specimen_types3: "Breast milk",
  w_nasal_swab: "Nasal Swab",
  w_saliva: "Saliva",

  w_right_arm: "Right Arm",
  w_left_arm: "Left Arm",
  w_right_thigh: "Right Thigh",
  w_left_thigh: "Left Thigh",
  w_left_abdomen: "Left Abdomen",
  w_right_abdomen: "Right Abdomen",
  w_abdomen: "Abdomen",

  w_instructions: "Instructions",
  w_specimen_collection_detail: "Specimen Collection Detail",
  w_injection_detail: "Injection Detail",
  w_collection_date: "Injection Date",
  w_select_date: "Select Date",
  w_collection_time: "Injection Time",
  w_mins: "Mins",
  w_am: "AM",
  w_pm: "PM",
  w_shipment_detail: "Shipment Detail",
  w_shipment_date: "Shipment Date",
  w_airbill_number: "Airbill Number",
  w_enter_number: "Enter Number",
  w_specimen_tip:
    "\nPlease refer to the information sheet in the RESOURCES page of the app for more detail.",
  w_specimen_instruction1:
    "\nBefore you proceed with your injection, please review your previous injection(s) location in the ",
  w_specimen_instruction2: "Injection History.\n\n",
  w_specimen_instruction3:
    "For injection Instructions, tap on the Instructions link above.\n\n",

  w_submit: "Submit",

  w_fever_question:
    "Fever higher than 100.4 degrees Fahrenheit or 38 degrees Celsius?",

  w_askquestion: "Ask a Question",
  w_require_collection_date: "Please Select Collection Date",
  w_require_collection_time: "Please Select Collection Time",
  w_symptoms_page_header:
    "Report your current symptoms. Select all that apply.",

  w_no_symptoms: "No symptoms\n ",
  w_worst_possibile_symptoms: "Worst possible\nsymptoms",
  w_symptoms_value: "Current Value",

  Language: "en",
  Culture: "en-US",
  LanguageId: "1",
  momentLocal: "moment/locale/en",
  w_symptom_submit_title: "Symptoms Reported",
  w_syptom_submit_content:
    "Thank you! A study staff member will contact you shortly",

  w_openFailed: "Open failed",
  w_unSupportFile: "Unsupported file type",
};

export default English;