// Default
import { useNavigate } from 'react-router-dom';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Translation
import Translation from '../../config/Translation';

// Custom
import CustomInput from '../../forms/input';
import CustomButton from '../../forms/button';

//Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

const NicknameSetup = ({onSave}) => {

  const formik = useFormik({
    initialValues: {
      nickname: '',
    },
    onSubmit: async (values) => {
      onSave(values)
    },
  });


  return (
    <>
      <Typography paragraph sx={{ fontSize: '14px', padding: '30px', margin: 0, color: '#a9a9a9' }}>
        {Translation.lang().w_nicknameUsedFor}
      </Typography>
      <Box sx={{ padding: '0 20px' }}>
        <CustomInput
          type={'text'}
          value={formik.values.nickname}
          placeholder={Translation.lang().w_nickname}
          name={'nickname'}
          onChange={formik.handleChange}
          formik={formik}
        />
      </Box>
      <div style={{ padding: '0 20px', marginTop: '30px', marginBottom: "10px" }}>
        <CustomButton label={Translation.lang().w_next} onClick={formik.handleSubmit} />
      </div>
    </>
  );
};

export default NicknameSetup;
