// ConfirmIdPage.js
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Stack, Typography, Box } from '@mui/material';
import { BorderCard } from '../../components/BorderCard';
import Logo from '../../components/logo/Logo';
import Translation from '../../config/Translation';
import { fetchPOSTRequest } from '../../utils/services';
import { VersionDetails } from '../../components/VersionDetails';
import useAlertDialog from '../../hooks/useAlertDialog';
import { useLoader } from '../../provider/LoaderContext';
import CustomButton from '../../forms/button';
import CustomInput from '../../forms/input';

const ConfirmIdPage = () => {
    const alertDialog = useAlertDialog();
    const { showLoader, hideLoader } = useLoader();

    const navigate = useNavigate();
    const location = useLocation();

    const { question1, question2, email } = location.state;
    const initialBody = {
        email,
        answer1: "",
        answer2: ""
    }
    const [body, setBody] = useState(initialBody);
    const onChangeHandle = (e) => setBody({ ...body, [e.target.name]: e.target.value });

    const QUESTIONS = [
        Translation.lang().w_question1,
        Translation.lang().w_question2,
        Translation.lang().w_question3,
        Translation.lang().w_question4,
        Translation.lang().w_question5,
        Translation.lang().w_question6,
        Translation.lang().w_question7,
        Translation.lang().w_question8,
        Translation.lang().w_question9,
        Translation.lang().w_question10,
        Translation.lang().w_question11,
        Translation.lang().w_question12,
        Translation.lang().w_question13,
        Translation.lang().w_question14,
        Translation.lang().w_question15,
    ];

    const _submitAnswer = async () => {
        showLoader()
        try {
            const response = await fetchPOSTRequest(`api/ResetPassword/VerifySecurityQuestions`, body);
            if (response.status === 200) {
                const result = response.data;
                if (result && result.status === 'Success') {
                    navigate('/reset-password', { state: { ...result, email } });
                }
                else {
                    alertDialog({
                        title: Translation.lang().w_validationFailed,
                        desc: Translation.lang().w_incorrectAnswers
                    })
                }
            }
        } catch (error) {
            console.log("🚀 ~ file: ConfirmIdPage.js:52 ~ const_submitAnswer= ~ error:", error.response)

        }
        hideLoader()
    }
    const _handleConfirm = () => {
        if (!body.email || !body.answer1 || !body.answer2) {
            alertDialog({
                desc: "Please insert answer"
            })
            return
        }
        _submitAnswer()
    }

    const _previousPage = () => {
        navigate('/forget-password', { replace: true });
    }
    return (
        <Container maxWidth="xs" >
            <div>
                <form>
                    <Logo sx={{ marginTop: 10 }} />
                    <BorderCard >

                        <Stack spacing={2} justifyContent={"center"} >
                            <Typography style={{ alignSelf: 'center', marginBottom: 2, }}>
                                {Translation.lang().w_setQuestions}
                            </Typography>
                            <Typography variant='body2'>
                                {QUESTIONS[question1]}
                            </Typography>
                            <CustomInput
                                placeholder={Translation.lang().w_questionAnswer1}
                                name="answer1"
                                onChange={onChangeHandle}
                            />
                            <Typography variant='body2'>
                                {QUESTIONS[question2]}
                            </Typography>
                            <CustomInput
                                placeholder={Translation.lang().w_questionAnswer2}
                                name="answer2"
                                onChange={onChangeHandle}
                            />
                            <Stack spacing={3} direction={{ xs: 'row', sm: 'row' }}>
                                <CustomButton onClick={_previousPage} label={Translation.lang().w_cancel} textColor='#666666' color='#eeeeee' />
                                <CustomButton onClick={_handleConfirm} label={Translation.lang().w_next} />
                            </Stack>
                        </Stack>
                    </BorderCard>
                </form>
            </div>
            <VersionDetails />
        </Container>
    );
};

export default ConfirmIdPage;

