
const Spanish = {
  w_login: "Iniciar Sesión",
  w_version: "Versión ",
  w_signin: "Iniciar Sesión",
  w_pin: "PIN",
  w_or: "o",
  w_email: "Correo electrónico",
  w_password: "Contraseña",
  w_forgotPassword: "¿Olvidó su contraseña",
  w_forgotPin: "¿Código PIN olvidado",
  w_back: "Atrás",
  w_skip: "Saltar",
  w_accept: "Aceptar",
  w_question: "Pregunta de seguridad",
  w_selectQuestion: "Por favor seleccione la pregunta",
  w_updateQuestions: "Actualizar preguntas de seguridad",
  w_answer: "Respuesta",
  w_questionAnswer1: "Respuesta de la primera pregunta",
  w_questionAnswer2: "Respuesta de la segunda pregunta",
  w_question1: "¿En qué ciudad nació?",
  w_question2: "¿Cuál es el nombre de su hermano menor?",
  w_question3: "¿Cuál es su color favorito?",
  w_question4: "¿Cuál es el nombre de su primera mascota?",
  w_question5: "¿Cuál es el segundo nombre de su hijo mayor?",
  w_question6: "¿Cuál es el apodo de su niñez?",
  w_question7: "¿Cuál es su equipo favorito?",
  w_question8: "¿Cuál es su película favorita?",
  w_question9: "¿Cuál era su comida favorita de niño?",
  w_question10: "¿Cuál era la marca y el modelo de su primer auto?",
  w_question11: "¿Cuál es el nombre del hospital donde nació?",
  w_question12: "¿A qué escuela asistió en sexto grado?",
  w_question13: "¿En qué ciudad desempeñó su primer trabajo?",
  w_question14:
    "¿Cuál era el nombre de la empresa donde obtuvo su primer trabajo?",
  w_question15: "¿En qué ciudad o pueblo se conocieron sus padres?",
  w_questionRequired: "Las preguntas de seguridad se requieren.",
  w_questionDifferent: "Las preguntas diferentes se requieren.",
  w_passwordRequired: "La contraseña és obligatoria",
  w_passwordTooShort: "La contraseña es demasiado corta.",
  w_passwordNotMatch: "El usuario y la contraseña no coinciden.",
  w_pinNotCorrect: "El PIN es incorrecto.",
  w_loginFailed: "Error de inicio de sesión",
  w_passwordNotCorrect: "El nombre de usuario o la contraseña son incorrectos.",
  w_invalidLoginTitle: "Entrada al sistema inválida",
  w_invalidLoginContent:
    "El intento de iniciar sesión no es válido. Póngase en contacto con: clientsupport@myownmed.com.",
  w_failed: "Fallido",
  w_loginFailed3Times:
    "El intento de iniciar sesión no es válido. Póngase en contacto con: clientsupport@myownmed.com.",
  w_signinRequired: "El correo electrónico y la contraseña se requieren.",
  w_Warning: "Aviso",
  w_networkError: "Error de solicitud de red o servidor",
  w_serverFailure: "Fallo del servidor",
  w_signout: "Fichar al salir",
  w_reset: "Restablecer",
  w_resetFailed: "Restablecer falló",
  w_emailIncorrect: "El correo electrónico es incorrecto.",
  w_validationFailed: "Error de validación",
  w_incorrectAnswers: "Las respuestas son incorrectas..",
  w_resetPassword: "Restablecer contraseña",
  w_track: "Inicio",
  w_next: "Siguiente",
  w_done: "Hecho",
  w_save: "Guardar",
  w_add: "Añadir",
  w_refresh: "Actualizar",
  w_uploading: "Subir…",
  w_success: "Éxito",
  w_delete: "Borrar",
  w_close: "Cerrar",
  w_ok: "OK",
  w_injection: "Muestras",
  w_injection_updated: "Muestra actualizada",
  w_your_specimen_collection_has_been_saved:
    "Su colección de muestras se ha guardado.",
  w_Report_Symptoms: "Report Symptoms",
  w_Enter_your_email_address: "Enter your email address",
  w_welcome_back: "¡Bienvenida, ",
  w_rateThisApp: "Puntuación de esta APP",
  w_resources: "Recursos",
  w_resources_tip:
    "Seleccione un tablero a continuación para conocer las últimas novedades, responder preguntas o comunicarse con su equipo de atención.",
  w_resources_faq: "Preguntas frecuentes",
  w_resources_contact: "Instrucciones del estudio",
  w_settings: "Configuración",
  w_language: "Idioma",
  w_spanish: "Español",
  w_setPassword: "Imponer Contraseña",
  w_updatePassword: "Actualizar Contraseña",
  w_passwordNew: "Nuevo Contraseña",
  w_passwordConfirmed: "Confirmar Contraseña",
  w_passwordUpdated: "La contraseña se actualiza.",
  w_passwordNotMatchRule:
    'Las contraseñas deben tener al menos un carácter que no sea letra o dígito. Las contraseñas deben tener al menos una minúscula ("a"-"Z"). Las contraseñas deben tener al menos una letra en mayúscula ("A"-"Z").',
  w_setPin: "Fijar PIN",
  w_updatePin: "Actualizar el PIN",
  w_pinNew: "Nuevo PIN",
  w_pinConfirmed: "Confirmar el PIN",
  w_pinTooShort: "El PIN es demasiado corto.",
  w_pinNotMatch: "El PIN y la contraseña no coinciden.",
  w_profile: "Perfil",
  w_name: "Nombre",
  w_firstName: "Nombre de pila",
  w_lastName: "Apellidos",
  w_gender: "Sexo",
  w_profilePicture: "Foto de perfil",
  w_takePhoto: "Hacer foto",
  w_fromAlbum: "Seleccionar foto",
  w_youNickname: "Su apodo",
  w_nickname: "Apodo",
  w_dateOfBirth: "Fecha de nacimiento",
  w_updateNickname: "Apodo de actualización",
  w_nicknameUsedFor: "El apodo será utilizada al enviar un mensaje.",
  w_changeNicknameSuccess: "Su apodo ha sido cambiada con éxito.",
  w_changeLanguageSuccess: "Su idioma ha sido cambiado exitosamente.",
  w_changeAvatarSuccess: "Su foto de perfil ha sido cambiada con éxito.",
  w_uploadProfilePhoto:
    "Suba to foto de perfil para ayudar al personal a reconocerlo.",
  w_noCameraAccess:
    "Esta aplicación no tiene acceso a la cámara. Puede habilitar el acceso a configuración de privacidad.",
  w_noImageLibraryAccess:
    "Esta aplicación no tiene acceso a tus fotos o vídeos. Puede habilitar el acceso a configuración de privacidad.",
  w_termOfUse: "Términos de servicio",
  w_privacyPolicy: "Política de privacidad",
  w_privacy: "Privacidad",
  w_Triage: "Contacto",
  Loss_of_Taste: "Loss of Taste/Smell",
  Loss_of_Smell: "Nasal Congestion",
  RunnyNose_green: "Runny Nose",
  Shortness_of_Breath: "Shortness of Breath",
  Cough: "Cough",
  Headache: "Headache",
  Body_Aches: "Body Aches",
  Sore_Throat: "Sore Throat",
  Fatigue: "Severe Fatigue",
  Fever: "Fever",
  Vomiting: "Vomiting",
  Diarrhea: "Vomiting / Diarrhea",
  Chills: "Chills",
  w_checkin_title: "You Have Checked-In",
  w_checkin_content:
    "Thank you! A study staff member will contact you shortly.",
  w_checkin_tip:
    "Si necesita ayuda o quiere comunicarse con el personal del estudio, utilice uno de los botones que se encuentran a continuación.",
  w_checkin_tip_2:
    "Llame al 911 si tiene una emergencia o pregunta médica urgente.",
  w_call: "Llamar",
  w_send_email: "Enviar correo electrónico",
  w_let_the_study_know:
    "Let the study staff know that you are here. Press the Check-In Now button",
  w_please_contact_the_GRADUATE: "Please contact the GRADUATE study site.",
  w_going_to_hospital: "Ir al Hospital",
  w_delivery: "Parto",
  w_contact_study_staff: "Comunicarse con el personal del estudio",
  w_checkin_send_email_success:
    "El correo electrónico se envió correctamente. Un miembro de nuestro personal del estudio se comunicará con usted en las próximas 48 horas hábiles. Gracias por comunicarse con nosotros.",
  w_checkin_send_email_failed: "El correo electrónico no se envió.",
  w_non_urgent_questions_tip:
    "Únicamente para preguntas no urgentes. El personal del estudio se comunicará con usted en las próximas 48 horas hábiles.",
  w_instructions: "Instrucciones",
  w_injection_detail: "Detalle de obtención de las muestras",
  w_injection_location: "Tipo de muestra",
  w_specimen_types1: "Materia fecal del bebé",
  w_specimen_types2: "Materia fecal de la madre",
  w_specimen_types3: "Leche materna",
  w_nasal_swab: "Nasal Swab",
  w_collection_date: "Fecha de obtención",
  w_select_date: "Seleccione la fecha",
  w_collection_time: "Hora de obtención",
  w_hour: "Horas",
  w_mins: "Minutos",
  w_shipment_detail: "Detalles del envío",
  w_shipment_date: "Fecha del envío",
  w_airbill_number: "Número del conocimiento de embarque",
  w_enter_number: "Ingrese el número",
  w_specimen_tip:
    "\nRecuerde colocar los geles refrigerantes en el freezer a la noche durante al menos 12 horas antes de obtener la muestra.  Las muestras deben ENVIARSE ÚNICAMENTE DE LUNES A JUEVES.",
  w_specimen_collection_saved: "Se ha guardado la obtención de la muestra.",
  w_injection_saved: "Muestra guardado",
  w_injection_not_saved: "Injection Not Saved",
  w_injection_not_allow_to_save: "Injection does not allow to save",
  w_calendar: "Calendario",
  w_appointment: "Evento",
  w_appointmentType: "Tipo de evento",
  w_appointmentDate: "Fecha de evento",
  w_appointStatus10: "Confirmar",
  w_appointStatus30: "Reprogramar",
  w_appointStatus50: "Cancelar",
  w_shareCalendar: "Compartido",
  w_confirmation: "Confirmación",
  w_shareAppointment: "Compartir evento",
  w_today: "Hoy",
  w_yesterday: "Ayer",
  w_never: "Nunca",
  w_calendarSentSuccess: "El nombramiento fue compartida",
  w_calendarSentFailure: "No se ha podido compartir el nombramient",
  w_message: "Mensaje",
  w_edit: "Editar",
  w_noMessage: "No tiene mensajes.",
  w_new_message: "Nuevo mensaje",
  w_select_topic: "Seleccione el tema:",
  w_topic1: "Problemas de salud",
  w_topic2: "Obtenciones de muestras",
  w_topic3: "Próxima visita",
  w_topic4: "Información general",
  w_select_your_question: "Seleccione su pregunta:",
  w_no_questions_for_topic: "No hay preguntas para este tema.",
  w_please_select_a_question: "Seleccione una pregunta.",
  w_submit: "Enviar",
  w_question_submitted_successfully: "Su pregunta se ha enviado correctamente.",
  w_changePinSuccess: "Su PIN ha sido cambiado con éxito",
  w_answerQuestions: "Responder preguntas de seguridad",
  w_answers_saved: "Tus respuestas han sido guardadas.",

  w_visit: "Visit",

  w_am: "AM",
  w_appointmentFieldsRequired: "Se requiere el tipo de cita y el tiempo.",
  w_appointmentLocked:
    "No se puede cancelar o reprogramar la cita con menos de 48 horas antes.",
  w_appointmentSent: "La solicitud ha sido enviada al personal médico.",
  w_appointStatus0: "Nuevo solicitado",
  w_appointStatus20: "Solicitud de reprogramación",
  w_appointStatus40: "Solicitud rechazada",
  w_begin: "Empezar",
  w_boy: "Niño",
  w_cancel: "Cancelar",
  w_cancelAppointment: "Cancelar el evento",
  w_changeDOBSuccess: "Su fecha de nacimiento ha sido cambiada con éxito.",
  w_changeGenderSuccess: "Su género ha sido cambiada con éxito.",
  w_changeNameSuccess: "Su nombre ha sido cambiada con éxito.",
  w_changePasswordSuccess: "Has restablecido con éxito tu contraseña.",
  w_collection_time_hour: "Hora",
  w_collection_time_minutes: "Min",
  w_confirm: "Confirmar",
  w_confirmCancelAppointment: "¿Quieres cancelar esta cita?",
  w_consentTermOfUse: "Términos de Uso",
  w_continue_complete_survey:
    "¿Está seguro de que no desea continuar y completar la encuesta?",
  w_dashboard: "Resumen",
  w_injection_summary: "Specimen Summary",
  w_survey_summary: "Survey Summary",
  w_date_format: "MM-DD-YYYY",
  w_datetime_format: "MM-DD-YYYY h:mm A",
  w_day: "día",
  w_doYouDeleteMessages: "¿Estás seguro de que quieres borrar mensajes?",
  w_emailResetPwd:
    "Ingrese su correo electrónico registrado, le enviaremos una instrucción para restablecer su contraseña.",
  w_enterTokenBelow: "Por favor ingrese el token abajo.",
  w_faq: "Preguntas frecuentes",
  w_girl: "Niña",
  w_imageMessage: "La Imagen",
  w_invalidToken: "El token no es válido.",
  w_loadProfileFail:
    "Error al cargar el perfil. Por favor, vuelva a iniciar sesión.",
  w_more: "Más",
  w_nameRequired: "Se requiere nombre y apellido",
  w_newChat: "Nueva conversación",
  w_no: "No",
  w_no_complete_survey: "No completed surveys",
  w_no_pending_surveys: "No hay encuestas pendientes",
  w_no_survey_results: "No hay resultados de la encuesta",
  w_non_urgent: "NO URGENTE",
  w_non_urgent_tip:
    "Problemas que pueden esperar  una hora para una llamada de vuelta",
  w_pending_surveys: "ENCUESTAS PENDIENTES",
  w_please_select_a_topic: "Debe seleccionar un tema.",
  w_pm: "PM",
  w_prev: "Anterior",
  w_reasonRequired: "Por favor proporcione una razón para cancelar la cita.",
  w_resetYourPassword: "Por favor, cambie su contraseña.",
  w_resources_education: "Información del estudio",
  w_resources_news: "Novedades",
  w_results: "Resultados",
  w_searchWiki: "Buscar",
  w_selectPhoto: "Seleccione Foto",
  w_sendCalendar: "Enviar calendario",
  w_setQuestions: "Responder preguntas de seguridad",
  w_share: "Compartido",
  w_specimen_shipment_detail: "Detalles del envío",
  w_start_new_conversation: " botón para iniciar una nueva conversación",
  w_survey_progress: `Esta ${0}% completos`,
  w_surveys: "Encuestas",
  w_tap_on_the: "Toque en el ",
  w_thank_you_for_completing_survey:
    `Gracias por completar la encuesta de ${0} El equipo de estudio hará un seguimiento con usted si tiene alguna pregunta.`,
  w_time_format: "h:mm A",
  w_token: "La Ficha",
  w_typeAMessage: "Escribe un mensaje ...",
  w_update: "Actualizar",
  w_update_success: "¡Éxito de actualización!",
  w_updateDOB: "Fecha de nacimiento de actualización",
  w_updateGender: "Actualizar género",
  w_updateName: "Actualizar Nombre",
  w_updateProfilePicture: "Cuadro del perfil de actualización",
  w_urgent: "URGENTE",
  w_urgent_tip:
    "Problemas que necesitan una devolución  nLlame lo antes posible.",
  w_usernamePasswordIncorrect:
    "El nombre de usuario o contraseña son incorrectos.",
  w_validate: "Validar",
  w_validateToken: "Validar La Ficha",
  w_validationSuccess: "Éxito de validación",
  w_welcome_initial: "¡Bienvenida al estudio ACTIVATE!",
  w_whatName: "¿Cuál es su nombre?",
  w_wiki: "Anuncios",
  w_yes: "Sí",
  w_hadnodata: "Aún no tienes datos",
  w_dashboardIntroduction:
    "Puede usar esta aplicación tan fácil como tocar la pantalla, lo que proporciona una forma simple y fácil de administrar su salud hoy, mañana o la semana pasada. Empieza ahora.",
  w_startTracking: "Comience a rastrear ahora",
  w_reported_symptoms: "Reported Symptoms",
  w_reported_symptoms_summary: "Reported Symptoms Summary",
  w_english: "Inglés",
  w_danish: "danés",
  w_dutch: "holandés",
  w_spanish_argentina: "Español - Argentina",
  w_spanish_spain: "Español - España",
  w_catalan_spain: "Catalán - España",

  w_setting_security: "Seguridad",
  w_setting_profile: "Perfil",
  w_setting_legal: "Legal",

  w_fever_question:
    "Fever higher than 100.4  degrees Fahrenheit or 38 degrees Celsius?",

  w_askquestion: "Hacer una pregunta",
  w_require_collection_date: "Por favor seleccione la fecha de recolección",
  w_require_collection_time: "Por favor seleccione el tiempo de recolección",

  Language: "es",
  Culture: "es",
  LanguageId: "6",
  momentLocal: "moment/locale/es",
  w_symptom_submit_title: "Symptoms Reported",
  w_syptom_submit_content:
    "Thank you!A study staff member will contact you shortly",
};

export default Spanish;