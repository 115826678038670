const populateQuestions = (questionsAnswers) => {
  if (!questionsAnswers || questionsAnswers.length === 0) {
    return {};
  }
  let currentQuestionIndex = -1;

  questionsAnswers.forEach((item, index) => {
    const { quizQuestionTypeID, quizAnswers, quizTriggers, quizPossibleAnswers } = item;

    if (![8, 9, 10].includes(quizQuestionTypeID)) {
      item.skiped = false;

      if (quizAnswers.length > 0) {
        currentQuestionIndex = index;
      } else if (quizTriggers && quizTriggers.length > 0) {
        let skipped = true;

        quizTriggers.forEach((trigger) => {
          questionsAnswers.forEach((tempQ) => {
            tempQ.quizAnswers.forEach((tempA) => {
              tempQ.quizPossibleAnswers.forEach((tempP) => {
                if (
                  tempA.quizPossibleAnswerID === tempP.quizPossibleAnswerID &&
                  tempP.quizPossibleAnswerSeqID === trigger.quizPossibleAnswerSeqID
                ) {
                  skipped = false;
                }
              });
            });
          });
        });

        item.skiped = skipped;
      }
    }
  });
  return {
    currentQuestionIndex,
    surveyQuestions: questionsAnswers,
  };
};


export { populateQuestions };
