
// utils 
import Translation from "../../config/Translation"

export const INJECTION_LOCATION = [
    Translation.lang().w_left_arm, Translation.lang().w_right_arm,
    Translation.lang().w_left_thigh, Translation.lang().w_right_thigh,
    Translation.lang().w_left_abdomen, Translation.lang().w_right_abdomen
]

export const INDICATORS = [Translation.lang().w_am, Translation.lang().w_pm]

export const HOURS = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
];

export const MINS = ["00", "15", "30", "45"];