import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Stack, Typography, Link } from '@mui/material';
import CustomIcon from '../../components/CustomIcon';
import { RESOURCES_PAGE, SETTINGS_PAGE } from '../../utils/pageName';
import { logout } from '../../redux/features/authSlice';
import CustomButton from '../../forms/button';
import profilePicture from '../../'

const ProfilePage = () => {
    const navigate = useNavigate();
    const userData = useSelector((data) => data.auth.user);
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout())
        navigate('/login', { replace: true });
    }

    return <div>
        <Stack sx={{ backgroundColor: "#123B6D", padding: "20px 0" }} justifyContent={"center"} alignItems={"center"}>
            <Box style={{ borderRadius: 100, borderWidth: 4, borderColor: "white", borderStyle: 'solid', width: "120px", height: "120px" }} component="img"
                src={userData?.avatarBase64 ? `data:image/jpeg;base64,${userData?.avatarBase64}` : `assets/profileImage_default@3x.png`}
            />
            <Typography sx={{ marginTop: "15px" }} variant='h4' color="#fff">
                {userData?.name}
            </Typography>
        </Stack>

        <Stack style={{ backgroundColor: '#fff', padding: 10 }} spacing={2} divider={<Divider />}>
            <Link href={RESOURCES_PAGE} style={{ textDecoration: 'none' }} >
                <Stack justifyContent={"space-between"} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }} >
                    <Stack alignItems={"center"} direction={{ xs: 'row', sm: 'row' }}>
                        <CustomIcon src={"assets/more/more_icon_educational.png"} />
                        <Typography style={{ marginLeft: 10, color: '#000' }}>
                            Resources
                        </Typography>
                    </Stack>
                    <CustomIcon src={"assets/arrow_right.png"} />
                </Stack>
            </Link>
            <Link href={SETTINGS_PAGE} style={{ textDecoration: 'none' }} >
                <Stack justifyContent={"space-between"} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }} >
                    <Stack alignItems={"center"} direction={{ xs: 'row', sm: 'row' }}>
                        <CustomIcon src={"assets/more/more_icon_setting.png"} />
                        <Typography style={{ marginLeft: 10, color: '#000' }}>
                            Settings
                        </Typography>
                    </Stack>
                    <CustomIcon src={"assets/arrow_right.png"} />
                </Stack>
            </Link>
        </Stack>

        <Stack padding={4}>
            <CustomButton onClick={handleLogout} label={"Sign Out"} />
        </Stack>
    </div>
}



export default ProfilePage;