// Default
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// MUI styles
import { makeStyles } from '@mui/styles';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Custom
import DetailNavBar from '../../components/DetailNavBar';
import CustomButton from '../../forms/button';
import { useLoader } from '../../provider/LoaderContext';
import useAlertDialog from '../../hooks/useAlertDialog';

// Services
import { fetchPUTRequest, fetchGETRequest } from '../../utils/services';
import { setUser } from '../../redux/features/authSlice'

// Translation
import Translation from '../../config/Translation';

const useStyles = makeStyles({
  title: {
    fontSize: '16px',
    padding: '12px',
    margin: 0,
    marginBottom: '5px',
    color: '#9d9d9d',
  },
  formLabel: {
    cursor: 'pointer',
    display: 'block',
    position: 'relative',
    background: '#f1f1f1',
    padding: '25px 5px',
    textAlign: 'center',
    borderRadius: '6px',
  },
  formPadding: {
    padding: '10px 8px !important',
  },
  formInput: {
    display: 'none',
    position: 'absolute',
  },
  formText: {
    color: '#b2b2b2',
    marginTop: '8px',
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    objectPosition: 'top',
  },
});

const ProfilePicturePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { showLoader, hideLoader } = useLoader();
  const userData = useSelector((data) => data.auth.user);
  const alertDialog = useAlertDialog();
  const [base64Image, setBase64Image] = useState('');

  useEffect(() => {
    if (userData?.avatarBase64) {
      setBase64Image(`data:image/jpeg;base64,${userData?.avatarBase64}`);
    } else {
      setBase64Image('');
    }
  }, [userData]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64Image(base64String);
      };

      reader.readAsDataURL(file);
    }
  };

  const updateProfile = async () => {
    const data = { avatarBase64: base64Image.split(',')[1] };
    showLoader();
    try {
      const response = await fetchPUTRequest('api/Profile/UpdatePhoto', data);
      if (response?.data?.status === 'Success') {
        alertDialog({
          title: Translation.lang().w_updateProfilePicture,
          desc: Translation.lang().w_changeAvatarSuccess,
        });
        const userData = await fetchGETRequest(`api/Profile/GetMine`);
        dispatch(setUser(userData?.data))
      }
    } catch (error) {
      alertDialog({
        title: Translation.lang().w_updateProfilePicture,
        desc: Translation.lang().w_resetFailed,
      });
    }
    hideLoader();
  };

  return (
    <>
      <DetailNavBar label={Translation.lang().w_updateProfilePicture} onClick={() => navigate(-1)} />
      <Typography paragraph className={classes.title}>
        {Translation.lang().w_uploadProfilePhoto}
      </Typography>

      <Grid container spacing={3} sx={{ padding: base64Image ? '0 40px' : '0 20px' }}>
        {base64Image && <Grid item xs={2}></Grid>}
        {base64Image && (
          <Grid
            item
            xs={5}
            sx={{
              height: '150px',
              width: '100%',
            }}
          >
            <img src={base64Image} className={classes.img} />
          </Grid>
        )}

        <Grid item xs={base64Image ? 3 : 12}>
          <Grid container spacing={1}>
            <Grid item xs={base64Image ? 12 : 6}>
              <label htmlFor="camera-input" className={`${classes.formLabel} ${base64Image && classes.formPadding}`}>
                <input
                  className={classes.formInput}
                  type="file"
                  accept="image/*"
                  id="camera-input"
                  capture="environment"
                  onChange={handleFileChange}
                />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box component="img" src="assets/photo/introductionPhoto.png" />
                </div>
                {!base64Image && <Typography className={classes.formText}>{Translation.lang().w_takePhoto}</Typography>}
              </label>
            </Grid>

            <Grid item xs={base64Image ? 12 : 6}>
              <label htmlFor="gallery-input" className={`${classes.formLabel} ${base64Image && classes.formPadding}`}>
                <input
                  className={classes.formInput}
                  type="file"
                  accept="image/*"
                  id="gallery-input"
                  onChange={handleFileChange}
                  multiple={false}
                />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box component="img" src="assets/photo/introductionAlbum.png" />
                </div>
                {!base64Image && <Typography className={classes.formText}>{Translation.lang().w_fromAlbum}</Typography>}
              </label>
            </Grid>
          </Grid>
        </Grid>
        {base64Image && <Grid item xs={2}></Grid>}
      </Grid>

      <div style={{ padding: '0 20px', marginTop: '30px' }}>
        <CustomButton label={Translation.lang().w_save} onClick={updateProfile} />
      </div>
    </>
  );
};

export default ProfilePicturePage;
