

export const LOGIN_PAGE = "login";
export const FORGET_PASSWORD_PAGE = "forget-password";
export const CONFIRM_ID_PAGE = "confirm-id";
export const RESET_PASSWORD_PAGE = "reset-password";


export const CONTACT_PAGE = "contact";
export const RESOURCES_PAGE = "resources";
export const SUMMARY_PAGE = "summary";
export const INJECTION_HISTORY_PAGE = "injection-history";
export const INJECTION_DETAILS_PAGE = "injection-details";
export const SURVEY_PAGE = "survey-summary";

export const HOME_PAGE = "/";

export const INJECTION_PAGE = "injection";
export const SURVEYS_PAGE = "surveys";
export const REMINDERS_PAGE = "reminders";
export const ADD_REMINDERS_PAGE = "add-reminders";

export const SURVEYS_DETAILS_PAGE = "survey-details";
export const SETTINGS_PAGE = "settings";
export const LANGUAGE_PAGE = "change-language"
export const UPDATE_PASSWORD_PAGE = "update-password"
export const UPDATE_NICKNAME_PAGE = "update-nickname"
export const UPDATE_PROFILE_PAGE = "update-profile"
export const UPDATE_PIN_PAGE = "update-pin"
export const TERMS_PAGE = "terms-conditions"
export const PRIVACY_PAGE = "privacy-policy"

export const FAQ_PAGE = "faq";
export const FAQ_DETAILS_PAGE = "faq-details";

export const STUDY_INSTRUCTIONS = "study-instructions";
export const STUDY_INSTRUCTIONS_DETAILS = "study-instructions-details";

export const SURVEYS_HISTORY = "surveys-history";
export const SURVEY_SUMMARY_DETAIL = "survey-summary-detail";

export const SETUP_PAGE = "setup"


