// Default
import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// MUI components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

// MUI styles
import { makeStyles } from '@mui/styles';

// Utils
import Translation from '../../config/Translation';
import { useLoader } from '../../provider/LoaderContext';
import { fetchGETRequest } from '../../utils/services';

// Custom
import DetailNavBar from '../../components/DetailNavBar';
import RadioButton from '../../components/surveys/Radio';
import SingleChoice from '../../components/surveys/SingleChoice';
import Slider from '../../components/surveys/Slider';
import CustomInput from '../../forms/input';

const useStyles = makeStyles({
  root: {
    padding: "0 25px",
    border: "none",
    borderRadius: "6px",
    height: "33px",
    background: "#19b051",
    color: "#ffffff",
    "&:hover": {
      background: "#19b051",
      color: "#ffffff"
    }
  },
});
const SurveySummaryDetailView = () => {
  const isENLocal = Translation.getLocale() === 'en';
  const navigate = useNavigate();
  const classes = useStyles();
  const { showLoader, hideLoader, isLoading } = useLoader();
  const { state } = useLocation();
  const [completedSurveyDetails, setCompletedSurveyDetails] = useState({
    surveyQuestions: [],
    currentQuestionIndex: -1
  });

  useEffect(() => {
    showLoader()
    async function fetchSurveyQuestions() {
      try {
        const response = await fetchGETRequest("api/Quiz/GetQuizParticipantQuestions", {
          quizID: state.quizId,
          quizParticipantID: state.quizzParticipantId
        })
        if (response?.status === 200 && response?.data?.length > 0) {
          setCompletedSurveyDetails({ ...completedSurveyDetails, surveyQuestions: [...response?.data] })
        }
        hideLoader()
      } catch (error) {
        hideLoader()
      }
    }
    fetchSurveyQuestions()
  }, [state]);

  const renderQuestionTitle = useCallback(() => {
    const { surveyQuestions, currentQuestionIndex } = completedSurveyDetails;
    let questionTitle = '';

    if (completedSurveyDetails.currentQuestionIndex < 0) {
      questionTitle = isENLocal ? state.description : state.descriptionES;
    } else {
      questionTitle = isENLocal ? surveyQuestions[currentQuestionIndex]?.questionText : surveyQuestions[currentQuestionIndex]?.questionTextES;
    }

    return <Typography paragraph fontWeight={600} textAlign={"center"}>
      {questionTitle}
    </Typography>

  }, [completedSurveyDetails]);

  const prevQuestion = (index) => {
    setCompletedSurveyDetails({
      ...completedSurveyDetails,
      currentQuestionIndex: index - 1
    })
  }

  const nextQuestion = (index) => {
    setCompletedSurveyDetails({
      ...completedSurveyDetails,
      currentQuestionIndex: index?.currentQuestionIndex + 1
    })
  }

  const renderFooter = useCallback(() => {
    const { currentQuestionIndex, surveyQuestions } = completedSurveyDetails
    const isDone = surveyQuestions?.length === currentQuestionIndex + 1
    return (
      <Paper style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <BottomNavigation showLabels sx={{ display: "flex", justifyContent: `${currentQuestionIndex < 0 ? "center" : currentQuestionIndex === 0 ? "flex-end" : "space-between"}`, padding: "0 15px 27px" }}>
          {currentQuestionIndex < 0 ?

            <Button className={`${classes.root}`} onClick={() => setCompletedSurveyDetails({
              ...completedSurveyDetails,
              currentQuestionIndex: 0,
            })} >{Translation.lang().w_begin}</Button>
            :
            <>
              {currentQuestionIndex !== 0 && <Button className={`${classes.root}`} onClick={() => prevQuestion(currentQuestionIndex)}>{Translation.lang().w_prev}</Button>}

              {isDone ?
                <Button className={`${classes.root}`} onClick={() => navigate(-1)}>{Translation.lang().w_done}</Button>
                :
                <Button className={`${classes.root}`} onClick={() => nextQuestion({ currentQuestionIndex })}>{Translation.lang().w_next}</Button>
              }
            </>
          }
        </BottomNavigation>
      </Paper>
    )

  }, [completedSurveyDetails]);


  const renderContent = useCallback(() => {
    const { currentQuestionIndex, surveyQuestions } = completedSurveyDetails
    const question = surveyQuestions[currentQuestionIndex];
    switch (question?.quizQuestionTypeID) {
      case 1:
        return (
          <RadioButton data={question?.quizPossibleAnswers} value={question?.quizAnswers[0]?.quizPossibleAnswerID} />
        );
      case 2:
        return (
          <CustomInput readOnly={true} value={question?.quizAnswers[0]?.answerValue} />
        )
      case 4:
        return (
          <>
            {question?.quizPossibleAnswers && question?.quizPossibleAnswers?.map((item, index) => {
              return <SingleChoice
                key={index}
                value={item?.answerValue}
                isSelected={item?.quizPossibleAnswerID === question?.quizAnswers[0]?.quizPossibleAnswerID}
              />
            })}
          </>
        );
      case 6:
        return (
          <CustomInput readOnly={true} value={question?.quizAnswers[0]?.answerValue} />
        )
      case 11:
        return (
          <Slider defaultValue={question?.quizAnswers?.[0]?.answerValue} />
        );
      default:
        return null;
    }
  }, [completedSurveyDetails]);

  return (
    <>
      <DetailNavBar label={Translation.lang().w_surveys} onClick={() => navigate(-1)} />
      <Box sx={{ padding: "20px", borderBottom: "2px solid #b5dff7" }}>
        <Typography textAlign={"center"}>{isENLocal ? state.title : state.titleES}</Typography>
      </Box>
      {!isLoading &&
        <>
          <Box sx={{ padding: '12px 12px 80px 12px' }}>
            {renderQuestionTitle()}
            {renderContent()}
          </Box>
          {renderFooter()}
        </>
      }
    </>

  )
}

export default SurveySummaryDetailView;