// Default
import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import BottomNavigation from '@mui/material/BottomNavigation';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

// MUI styles
import { makeStyles } from '@mui/styles';

// Custom
import DetailNavBar from '../../components/DetailNavBar';
import SingleChoice from '../../components/surveys/SingleChoice';
import Slider from '../../components/surveys/Slider';
import useAlertDialog from '../../hooks/useAlertDialog';
import RadioButton from '../../components/surveys/Radio';

// Services
import { fetchGETRequest, fetchPOSTRequest } from '../../utils/services';

// Utils
import { useLoader } from '../../provider/LoaderContext';
import { populateQuestions } from '../../utils/helper/surveysHelper';

// Translation
import Translation from '../../config/Translation';
import CustomInput from '../../forms/input';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    padding: "0 25px",
    border: "none",
    borderRadius: "6px",
    height: "33px",
  },
  disabled: {
    background: "#ededed",
    color: "#cccccc",
    "&:hover": {
      background: "#ededed",
      color: "#cccccc"
    }
  },
  notDisabled: {
    background: "#19b051",
    color: "#ffffff",
    "&:hover": {
      background: "#19b051",
      color: "#ffffff"
    }
  }
});

const SurveyDetails = () => {
  const isENLocal = Translation.getLocale() === 'en';
  const classes = useStyles();
  const [surveyDetails, setSurveyDetails] = useState({
    surveyQuestions: [],
    currentQuestionIndex: -1
  });
  const navigate = useNavigate()
  const location = useLocation();
  const alertDialog = useAlertDialog();
  const { state } = location;
  const { showLoader, hideLoader, isLoading } = useLoader();
  const { currentQuestionIndex, surveyQuestions } = surveyDetails;
  const question = surveyQuestions[currentQuestionIndex];
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    showLoader()
    async function fetchSurveyQuestions() {
      try {
        const response = await fetchGETRequest("api/Quiz/GetQuizParticipantQuestions", {
          quizID: state.quizId,
          quizParticipantID: state.quizzParticipantId
        })
        if (response?.status === 200 && response?.data?.length > 0) {
          const { surveyQuestions, currentQuestionIndex } = populateQuestions(response.data);
          setSurveyDetails({ surveyQuestions, currentQuestionIndex })
        }
        hideLoader()
      } catch (error) {
        hideLoader()
      }
    }
    fetchSurveyQuestions()
  }, [state]);
  /* -------------------------------------------------------------------------- */
  const nextQuestion = (currentIndex) => {
    const nextQuestionIndex = currentIndex + 1;
    if (nextQuestionIndex < surveyQuestions.length) {
      const question = surveyQuestions[nextQuestionIndex];
      if (question.quizTriggers && question.quizTriggers.length > 0) {
        const existedPossibleAnswerID = question.quizTriggers.some((trigger) => {
          const quizPossibleAnswerSeqID = trigger.quizPossibleAnswerSeqID;
          return surveyQuestions.some((tempQ) =>
            tempQ.quizAnswers.some((tempA) =>
              tempQ.quizPossibleAnswers.some((tempP) =>
                tempA.quizPossibleAnswerID === tempP.quizPossibleAnswerID &&
                tempP.quizPossibleAnswerSeqID === quizPossibleAnswerSeqID
              )
            )
          );
        });
        question.skiped = !existedPossibleAnswerID;
        if (!existedPossibleAnswerID) {
          nextQuestion(nextQuestionIndex);
        }
      }
      if (nextQuestionIndex >= surveyQuestions.length) {
        saveSurvey(100)
      } else {
        setSurveyDetails({
          ...surveyDetails,
          currentQuestionIndex: nextQuestionIndex,
        });
      }
    } else {
      saveSurvey(100)
    }
  };
  /* -------------------------------------------------------------------------- */
  const prevQuestion = (currentIndex) => {
    let prevQuestionIndex = currentIndex - 1;
    if (prevQuestionIndex > 0) {
      let prevQuestion = surveyQuestions[prevQuestionIndex];
      if (prevQuestion && prevQuestion.skiped) {
        prevQuestion(prevQuestionIndex);
        return false;
      }
    }
    setSurveyDetails({
      ...surveyDetails,
      currentQuestionIndex: prevQuestionIndex,
    });
  };
  /* -------------------------------------------------------------------------- */
  const saveAlert = () => {
    alertDialog({
      title: Translation.lang().w_confirmation,
      desc: Translation.lang().w_continue_complete_survey,
      leftButtonText: Translation.lang().w_confirm,
      rightButtonText: Translation.lang().w_cancel,
      leftButtonFunction: () => saveSurvey("")
    })
  };
  /* -------------------------------------------------------------------------- */
  const saveSurvey = async (isPercentage) => {
    let answers = [];
    surveyQuestions.map((question) => {
      question.quizAnswers.map((answer) => {
        answers.push({
          answerValue: answer.answerValue,
          quizPossibleAnswerID: answer.quizPossibleAnswerID,
          quizQuestionID: question.quizQuestionID,
          quizParticipantId: state.quizzParticipantId,
        });
      });
    });
    const percentage = isPercentage ? isPercentage : populatePercentage();
    showLoader()
    try {
      const response = await fetchPOSTRequest(`api/Quiz/SaveQuizAnswersWithPercentage`, { answers, percentage: percentage.toFixed(0) });
      if (response.status === 200) {
        alertDialog({
          title: Translation.lang().w_confirmation,
          desc: !isPercentage ? Translation.lang().w_continue_complete_survey : Translation.lang().w_thank_you_for_completing_survey.replace(
            `${0}`,
            isENLocal ? state?.title
              : state?.titleES
          ),
          rightButtonText: Translation.lang().w_ok,
          rightButtonFunction: () => navigate(-1)
        })
      }
    } catch (error) {
    }
    hideLoader()
  };
  /* -------------------------------------------------------------------------- */
  const updateQuestionAnswerForSelect = (possibleAnswerId) => {
    const updatedQuestions = surveyDetails.surveyQuestions.map((item) => {
      if (question.quizQuestionID === item.quizQuestionID) {
        if ([4, 1, 11].includes(question.quizQuestionTypeID)) {
          item.quizAnswers = [{ quizPossibleAnswerID: possibleAnswerId }];
        } else if (question.quizQuestionTypeID === 5) {
          const existIndex = item.quizAnswers.findIndex((answer) => answer.quizPossibleAnswerID === possibleAnswerId);
          if (existIndex !== -1) {
            item.quizAnswers.splice(existIndex, 1);
          } else {
            item.quizAnswers.push({ quizPossibleAnswerID: possibleAnswerId });
          }
        }
      }
      return item;
    });
    const { surveyQuestions } = populateQuestions(updatedQuestions);
    setSurveyDetails({ ...surveyDetails, surveyQuestions });
  };
  /* -------------------------------------------------------------------------- */
  const updateQuestionAnswer = (answer) => {
    const updatedSurveyQuestions = surveyDetails.surveyQuestions.map((item) => {
      if (question.quizQuestionID === item.quizQuestionID) {
        const shouldSetAnswer = answer !== '' && (question.quizQuestionTypeID === 3 || question.quizQuestionTypeID === 11);
        item.quizAnswers = shouldSetAnswer ? [{ answerValue: answer }] : (answer !== '' || answer === 0) ? [{ answerValue: answer }] : [];
      }
      return item;
    });
    const { surveyQuestions, } = populateQuestions(updatedSurveyQuestions);
    setSurveyDetails({ ...surveyDetails, surveyQuestions });
  };
  /* -------------------------------------------------------------------------- */
  const populatePercentage = () => {
    const skippableTypes = [8, 9, 10];

    const totalNeedAnswerQuestionNum = surveyQuestions.reduce((count, item) => {
      if (!skippableTypes.includes(item.quizQuestionTypeID)) {
        if (item.quizAnswers.length > 0) {
          if (item.quizAnswers.some(answer => answer.quizPossibleAnswerID > 0 || answer.answerValue.trim().length > 0)) {
            return count + 1;
          }
        } else if (!item.skiped) {
          return count + 1;
        }
      }
      return count;
    }, 0);

    const answered = surveyQuestions.reduce((count, item) => {
      if (!skippableTypes.includes(item.quizQuestionTypeID) && item.quizAnswers.length > 0) {
        if (item.quizAnswers.some(answer => answer.quizPossibleAnswerID > 0 || answer.answerValue.trim().length > 0)) {
          return count + 1;
        }
      }
      return count;
    }, 0);

    const progress = totalNeedAnswerQuestionNum > 0 ? (100 * answered) / totalNeedAnswerQuestionNum : 0;

    return progress;
  };
  /* -------------------------------------------------------------------------- */
  const renderQuestionTitle = () => {
    let questionTitle = '';
    if (currentQuestionIndex < 0) {
      questionTitle = isENLocal ? state.description : state.descriptionES;
    } else {
      questionTitle = isENLocal ? question?.questionText : question?.questionTextES;
    }
    return <Typography paragraph fontWeight={600} textAlign={"center"}>
      {questionTitle}
    </Typography>
  };
  /* -------------------------------------------------------------------------- */
  const renderContent = useCallback(() => {
    const questionPossibleAnswer = question?.quizPossibleAnswers;
    const answerValueForSelect = question?.quizAnswers?.[0]?.quizPossibleAnswerID;
    const answerValue = question?.quizAnswers?.[0]?.answerValue;
    const step = question?.quizPossibleAnswers?.[2]?.answerValue - question?.quizPossibleAnswers?.[1]?.answerValue;
    console.log(question,"question");
    switch (question?.quizQuestionTypeID) {
      case 1:
        return (
          <RadioButton data={question?.quizPossibleAnswers} value={answerValueForSelect} onChange={(e) => updateQuestionAnswerForSelect(e.target.value)} />
        );
        case 2:
          return (
            <div style={{margin : "0px 25px"}}>
              <CustomInput
                type="text"
                sx={{ height: "42px"}}
                onChange={(event) => updateQuestionAnswer(event.target.value)}
                value={answerValue || ""}
              />
            </div>
            );  
      case 4:
        return (
          <>
            {questionPossibleAnswer.map((item, index) => {
              const quizAnswers = answerValueForSelect === item.quizPossibleAnswerID;
              return <SingleChoice
                key={index}
                value={item.answerValue}
                isSelected={quizAnswers}
                onClick={() => updateQuestionAnswerForSelect(item.quizPossibleAnswerID)}
              />
            })}
          </>
        );
      case 6:
        const answer = moment().format('YYYY-MM-DD');
        if(!answerValue){
          updateQuestionAnswer(answer);
        }
        return (
          <div style={{margin : "0px 25px"}}>
            <CustomInput
              type="date"
              sx={{ height: "42px"}}
              onChange={(event) => updateQuestionAnswer(moment(event.target.value).format('YYYY-MM-DD'))}
              value={answerValue ? moment(answerValue).format('YYYY-MM-DD') : answer}
            />
          </div>
          );
      case 11:
        return (
          <Slider onChange={(e) => updateQuestionAnswer(eval(e.target.value).toFixed(1))} defaultValue={answerValue} step={step} />
        );  
      default:
        return null;
    }
  }, [surveyDetails]);
  /* -------------------------------------------------------------------------- */
  const renderFooter = () => {
    const isDisable = question?.quizAnswers?.length > 0 ||
      question?.quizQuestionTypeID == 8 ||
      question?.quizQuestionTypeID == 9 ||
      question?.quizQuestionTypeID == 10;
    const isDone = currentQuestionIndex == surveyQuestions.length - 1
    return (
      <Paper style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <BottomNavigation showLabels sx={{ display: "flex", justifyContent: `${currentQuestionIndex < 0 ? "center" : "space-between"}`, padding: "0 15px 27px" }}>
          {currentQuestionIndex < 0 ?

            <Button className={`${classes.root} ${classes.notDisabled}`} onClick={() => setSurveyDetails({
              ...surveyDetails,
              currentQuestionIndex: 0,
            })} >{Translation.lang().w_begin}</Button>
            :
            <>
              {currentQuestionIndex !== 0 && <Button className={`${classes.root} ${classes.notDisabled}`} onClick={() => prevQuestion(currentQuestionIndex)}>{Translation.lang().w_prev}</Button>}
              {currentQuestionIndex !== surveyQuestions.length - 1 && <Button onClick={saveAlert} className={`${classes.root} ${classes.notDisabled}`}>{Translation.lang().w_save}</Button>}
              <Button disabled={!isDisable} className={`${classes.root} ${!isDisable ? classes.disabled : classes.notDisabled}`} onClick={() => nextQuestion(currentQuestionIndex)}>{isDone
                ? Translation.lang().w_done
                : Translation.lang().w_next}</Button>
            </>}
        </BottomNavigation>
      </Paper>
    )
  };
  /* -------------------------------------------------------------------------- */
  const progressBar = () => {
    const progress = populatePercentage();
    const progressText = Translation.lang().w_survey_progress.replace(
      `${0}`,
      progress.toFixed(0)
    );

    return (
      <Chip
        label={progressText}
        variant="outlined"
        sx={{
          width: '100%',
          borderColor: '#19b051',
          borderRadius: 0,
          fontSize: '16px',
          marginTop: '10px',
          color: 'black',
          fontWeight: 600,
          backgroundImage: `linear-gradient(to right, #19b051 ${progress}%, rgba(0,0,0,0) ${progress}%)`,
        }}
      />
    )
  };
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <DetailNavBar label={Translation.lang().w_surveys} onClick={() => navigate(-1)} />
      <Box>
        <Box style={{ padding: '10px 20px' }}>
          <Typography variant="h5" textAlign={'center'} fontWeight={500}>
            {isENLocal ? state?.title : state?.titleES}
          </Typography>
          {progressBar()}
        </Box>

        <Divider sx={{ backgroundColor: '#c9e6f7', marginTop: '5px' }} />
        {!isLoading &&
          <>
            <Box sx={{ padding: '12px 12px 80px 12px' }}>
              {renderQuestionTitle()}
              {renderContent()}
            </Box>
            {renderFooter()}
          </>
        }
      </Box>
    </>
  );
};

export default SurveyDetails;
