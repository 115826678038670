import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Button, Paper, Stack } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { baseURL } from '../../utils/services';
import { useLoader } from '../../provider/LoaderContext';
import DetailNavBar from '../../components/DetailNavBar';

const FAQDetailPage = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { showLoader, hideLoader, isLoading } = useLoader();
    const handleLoad = () => {
        hideLoader(false);
    };

    React.useEffect(() => {
        showLoader()
    }, []);
    const pageUrl = baseURL + state.detailUrl.replace("./", "");
    console.log(pageUrl,"pageUrl");
    return (
        <Paper>
            <DetailNavBar label={state?.title} onClick={() => navigate(-1)} />
            <iframe
                title={state?.title}
                src={pageUrl}
                width="100%"
                height="1000px"
                onLoad={handleLoad}
                style={{ display: isLoading ? 'none' : 'flex', border: 'none' }}
            >
            </iframe>

        </Paper>
    );
};

export default FAQDetailPage;
