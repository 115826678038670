// Default
import { useNavigate } from 'react-router-dom';

// MUI components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

// MUI icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Custom
import DetailNavBar from '../../components/DetailNavBar';

// Translation
import Translation from '../../config/Translation';

// Constants
import { LANGUAGE_PAGE, UPDATE_PASSWORD_PAGE, UPDATE_NICKNAME_PAGE, UPDATE_PROFILE_PAGE, TERMS_PAGE, PRIVACY_PAGE, UPDATE_PIN_PAGE } from '../../utils/pageName';

const Settings = () => {
  const navigate = useNavigate();

  const SETTINGS = [
    {
      title: Translation.lang().w_language,
      list: [{ name: Translation.lang().w_language, route: `/${LANGUAGE_PAGE}` }],
    },
    {
      title: Translation.lang().w_setting_security,
      list: [
        { name: Translation.lang().w_updatePassword, route: `/${UPDATE_PASSWORD_PAGE}` },
        // { name: Translation.lang().w_updatePin, route: `/${UPDATE_PIN_PAGE}` }
      ],
    },
    {
      title: Translation.lang().w_profile,
      list: [
        { name: Translation.lang().w_nickname, route: `/${UPDATE_NICKNAME_PAGE}` },
        { name: Translation.lang().w_profilePicture, route: `/${UPDATE_PROFILE_PAGE}` },
      ],
    },
    {
      title: Translation.lang().w_setting_legal,
      list: [
        { name: Translation.lang().w_consentTermOfUse, route: `/${TERMS_PAGE}` },
        { name: Translation.lang().w_privacyPolicy, route: `/${PRIVACY_PAGE}` },
      ],
    },
  ];

  const getHeading = (title) => (
    <Typography
      sx={{
        background: '#dddddd',
        color: '#4a4a4a',
        fontWeight: 700,
        padding: '12px',
        fontSize: '14px',
        margin: 0,
        textTransform: 'uppercase',
      }}
      paragraph
    >
      {title}
    </Typography>
  );

  const getRoutes = (label, routes) => (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', padding: '12px', alignItems: 'center' }}
      onClick={() => navigate(routes)}
    >
      <Typography paragraph sx={{ margin: 0, fontSize: '15px' }}>
        {label}
      </Typography>
      <IconButton sx={{ padding: 0 }}>
        <ArrowForwardIosIcon sx={{ color: '#038fd4' }} fontSize="small" />
      </IconButton>
    </Box>
  );

  return (
    <>
      <DetailNavBar label={Translation.lang().w_settings} onClick={() => navigate(-1)}/>

      {SETTINGS.map((setting, index) => (
        <div key={index}>
          {getHeading(setting.title)}
          {setting.list.map((list) => (
            <>
              {getRoutes(list.name, list.route)}
              {setting.list.length > 1 && <Divider sx={{ background: '#e8e8e8', margin: '0 12px' }} />}
            </>
          ))}
        </div>
      ))}
    </>
  );
};

export default Settings;
