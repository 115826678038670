import { useNavigate } from 'react-router-dom'
import { Box, Button, Divider, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { INJECTION_DETAILS_PAGE } from '../../utils/pageName'
import CustomIcon from '../../components/CustomIcon'
import Translation from '../../config/Translation'
import DetailNavBar from '../../components/DetailNavBar'
import { fetchPOSTRequest } from '../../utils/services'
import { INJECTION_LOCATION } from '../injections-page/data'
import moment from 'moment'
import { useLoader } from '../../provider/LoaderContext'
import AlertNoData from '../../components/AlertNoData'

const InjectionHistoryPage = () => {
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useLoader();

    const [injectionSummaryList, setInjectionSummaryList] = React.useState([]);
    const [isAlert, setIsAlert] = React.useState(false);


    const openAlertEmpty = () => {
        setIsAlert(true)
    }
    const closeAlertEmpty = () => {
        setIsAlert(false)
        navigate('/')
    }

    const getInjectionSummary = async () => {
        showLoader()
        try {
            const fromDay = new Date();
            fromDay.setDate(fromDay.getDate() - 30);
            const toDay = new Date();
            const body = {
                "fromDay": fromDay.toISOString(),
                "toDay": toDay.toISOString()
            }
            const overview = await fetchPOSTRequest('api/Dashboard/Overview', body);
            console.log("🚀 ~ file: InjectionHistoryPage.js:23 ~ getInjectionSummary ~ overview:", overview)

            if (overview.status === 200) {
                const { specimens } = overview.data;
                console.log("🚀 ~ file: InjectionHistoryPage.js:27 ~ getInjectionSummary ~ specimens:", specimens)

                if (specimens?.length > 0) {
                    setInjectionSummaryList(specimens);
                } else {
                    openAlertEmpty()
                }
            }
        } catch (error) {
            console.log("🚀 ~ file: InjectionHistoryPage.js:18 ~ getInjectionSummary ~ error:", error)
        }
        hideLoader()
    }

    React.useEffect(() => {
        getInjectionSummary();
    }, [])


    return <>
        <DetailNavBar label={Translation.lang().w_injection_summary} onClick={() => navigate(-1)} />
        <>
            <Stack sx={{ padding: '15px 12px', background: '#ececec' }} spacing={2} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }}>
                <CustomIcon sx={{ height: '30px' }} src={"assets/track/Injection_NavyBlue_small.png"} />
                <Typography variant='button' sx={{ color: '#666666', fontSize: 18 }}>
                    {Translation.lang().w_injection}
                </Typography>
            </Stack>
            {
                injectionSummaryList.map((e, i) => {
                    return (
                        <Stack key={i}>
                            <Stack p={2} onClick={() => navigate(`/${INJECTION_DETAILS_PAGE}`, { state: e })} justifyContent={"space-between"} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }} >
                                <Typography style={{ marginLeft: 10, color: '#000' }}>
                                    {INJECTION_LOCATION[e.specimenType]}
                                </Typography>
                                <Stack spacing={2} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }} >
                                    <Typography>
                                        {moment(moment.utc(e.dateTimeCollection).format()).format(Translation.lang().w_date_format)}
                                    </Typography>
                                    <CustomIcon src={"assets/arrow_right.png"} />
                                </Stack>
                            </Stack>
                            <Divider />
                        </Stack>

                    )
                })
            }
            <AlertNoData isOpen={isAlert} closeAlert={closeAlertEmpty} />
        </>
    </>
}

export default InjectionHistoryPage
