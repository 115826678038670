import { Box, CardContent } from '@mui/material';
import PropTypes from 'prop-types';


export const HomeCard = ({ children }) => (
    <Box style={{ borderRadius: 2, paddingTop: 20, height: 160, width: 160, paddingBottom: 20, borderWidth: 6, borderColor: "grey", borderStyle: 'solid' }} >
        <CardContent>
            {children}
        </CardContent>
    </Box>
);


HomeCard.propTypes = {
    children: PropTypes.node.isRequired,
};
