// ForgetPasswordPage.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Stack, Typography } from '@mui/material';
import { BorderCard } from '../../components/BorderCard';
import Logo from '../../components/logo';
import { useLoader } from '../../provider/LoaderContext';
import { fetchPOSTRequest } from '../../utils/services';
import { VersionDetails } from '../../components/VersionDetails';
import useAlertDialog from '../../hooks/useAlertDialog';
import Translation from '../../config/Translation';
import CustomButton from '../../forms/button';
import CustomInput from '../../forms/input';

const ForgetPasswordPage = () => {
    const alertDialog = useAlertDialog();
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useLoader();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);

    const validateEmail = (inputEmail) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(inputEmail);
    };

    const handleEmailChange = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);
        setEmailError(!validateEmail(inputEmail));
    };


    const _handleEmailVerificationResult = (result) => {
        if (result && result.isEmailValid) {
            if (result?.question1 != null && result?.question2 != null) {
                navigate('/confirm-id', { state: result });
            } else {
                alertDialog({
                    title: Translation.lang().w_resetFailed,
                    desc: "You will not be able to reset your password until you have successfully logged into the application at least once. Please contact: clientsupport@myownmed.com"
                })
            }
        }
        else {
            alertDialog({
                title: Translation.lang().w_resetFailed,
                desc: Translation.lang().w_emailIncorrect
            })
        }
    }

    const _verifyAccount = async () => {
        showLoader()
        try {
            const response = await fetchPOSTRequest(`api/ResetPassword/GetMySecurityQuestions`, { email });
            if (response.status === 200) {
                _handleEmailVerificationResult(response?.data);
            }
        } catch (error) {
        }
        hideLoader()
    }

    const handleReset = () => {
        if (emailError || !email) {
            alertDialog({
                desc: "Invalid email format"
            })
            return;
        }
        _verifyAccount()
    };

    const _previousPage = () => {
        navigate('/login', { replace: true });
    }
    return (
        <Container maxWidth="xs" >
            <div>
                <form>
                    <Logo sx={{ marginTop: 10 }} />
                    <BorderCard>

                        <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
                            <Typography>
                                {Translation.lang().w_Enter_your_email_address}
                            </Typography>
                            {/* <TextField
                                size="small"
                                fullWidth
                                label="Email"
                                variant="filled"
                                margin="normal"
                                value={email}
                                error={emailError}
                                helperText={emailError ? 'Invalid email format' : ''}
                                onChange={handleEmailChange}
                            /> */}
                            <CustomInput
                                value={email}
                                placeholder={Translation.lang().w_email}
                                onChange={handleEmailChange}

                            />
                            <Stack spacing={3} direction={{ xs: 'row', sm: 'row' }}>
                                <CustomButton label={Translation.lang().w_cancel} onClick={_previousPage} textColor='#666666' color='#eeeeee' />
                                <CustomButton label={Translation.lang().w_reset} onClick={handleReset} />
                            </Stack>
                        </Stack>
                    </BorderCard>
                </form>
            </div>
            <VersionDetails />
        </Container>
    );
};

export default ForgetPasswordPage;
