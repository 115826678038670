// Default
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI components
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery';

// MUI icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Utils
import Translation from '../../config/Translation'
import { fetchGETRequest } from '../../utils/services';
import { useLoader } from '../../provider/LoaderContext';
import { fDate } from "../../utils/formatTime";
import { SURVEY_SUMMARY_DETAIL } from "../../utils/pageName";

// Custom
import DetailNavBar from '../../components/DetailNavBar'

const SurveysHistory = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoader()
  const [completedQuizs, setCompletedQuizs] = useState([]);
  const matchesXs = useMediaQuery('(max-width:400px)');

  useEffect(() => {
    showLoader()
    async function getCompletedQuizs() {
      try {
        const response = await fetchGETRequest("api/Quiz/GetCompletedQuizs", {})
        if (response?.status === 200 && response?.data?.length > 0) {
          setCompletedQuizs([...response?.data])
        }
        hideLoader()
      } catch (error) {
        hideLoader()
      }
    }
    getCompletedQuizs()
  }, []);

  return (
    <>
      <DetailNavBar label={Translation.lang().w_survey_summary} onClick={() => navigate(-1)} />
      {
        completedQuizs && completedQuizs?.length > 0 &&
        completedQuizs.map((quiz) => {
          return (
            <>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 12px' }}
                key={quiz?.$id}
                onClick={() => navigate(`/${SURVEY_SUMMARY_DETAIL}`, { state: quiz })}
              >
                <Typography variant="h5" fontWeight={500} fontSize={matchesXs ? "15px" : "16px"}>
                  {quiz?.title}
                </Typography>
                <Stack alignItems={'center'} gap={'3px'} direction={{ xs: 'row', sm: 'row' }}>
                  <Typography variant="h6" fontWeight={500} fontSize={matchesXs ? "15px" : "16px"}>
                    {fDate(quiz?.dateCreated, 'dd-MM-yyyy')}
                  </Typography>
                  <IconButton sx={{ padding: 0 }}>
                    {' '}
                    <ArrowForwardIosIcon sx={{ color: '#038fd4' }} fontSize={matchesXs ? "small" : "medium"} />
                  </IconButton>
                </Stack>
              </Box>
              <Divider />
            </>
          )
        })
      }
    </>
  )
}

export default SurveysHistory;