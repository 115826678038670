// Default
import { useNavigate } from 'react-router-dom';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Translation
import Translation from '../../config/Translation';

// Custom
import DetailNavBar from '../../components/DetailNavBar';
import CustomInput from '../../forms/input';
import CustomButton from '../../forms/button';
import { useLoader } from '../../provider/LoaderContext';
import useAlertDialog from '../../hooks/useAlertDialog';

//Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Services
import { fetchPUTRequest } from '../../utils/services';

const UpdateNicknamePage = () => {
  const navigate = useNavigate();
  const alertDialog = useAlertDialog();
  const { showLoader, hideLoader } = useLoader();

  const validationSchema = Yup.object({
    nickname: Yup.string().required('Please enter a nickname.'),
  });

  const formik = useFormik({
    initialValues: {
      nickname: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      showLoader();
      try {
        const response = await fetchPUTRequest('api/Profile/UpdateNickName', values);
        if (response?.data?.status === 'Success') {
          alertDialog({
            title: Translation.lang().w_updateNickname,
            desc: Translation.lang().w_changeNicknameSuccess,
          });
          formik.resetForm()
        }
      } catch (error) {
        alertDialog({
          title: Translation.lang().w_updateNickname,
          desc: Translation.lang().w_resetFailed,
        });
      }
      hideLoader();
    },
  });


  return (
    <>
      <DetailNavBar label={Translation.lang().w_updateNickname} onClick={() => navigate(-1)} />
      <Typography paragraph sx={{ fontSize: '14px', padding: '12px', margin: 0, color: '#a9a9a9' }}>
        {Translation.lang().w_nicknameUsedFor}
      </Typography>
      <Box sx={{ padding: '0 20px', marginTop: '30px' }}>
        <CustomInput
          type={'text'}
          value={formik.values.nickname}
          placeholder={Translation.lang().w_nickname}
          name={'nickname'}
          onChange={formik.handleChange}
          formik={formik}
        />
      </Box>
      <div style={{ padding: '0 20px', marginTop: '20px' }}>
        <CustomButton label={Translation.lang().w_save} onClick={formik.handleSubmit} />
      </div>
    </>
  );
};

export default UpdateNicknamePage;
