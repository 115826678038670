// Default
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

// MUI icons
import CheckIcon from '@mui/icons-material/Check';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/features/authSlice';

// Services
import { fetchPUTRequest } from '../../utils/services';

// Custom
import DetailNavBar from '../../components/DetailNavBar';
import CustomButton from '../../forms/button';
import { useLoader } from '../../provider/LoaderContext';
import useAlertDialog from '../../hooks/useAlertDialog';

// Translation
import Translation from '../../config/Translation';

const languages = [
  {
    name: Translation.lang().w_english,
    locale: Translation.English().Language,
  },
  {
    name: Translation.lang().w_spanish,
    locale: Translation.Spanish().Language,
  },
];

const LangaugePage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showLoader, hideLoader } = useLoader();
  const alertDialog = useAlertDialog();

  useEffect(() => {
    user.cultureName === 'en-US' ? setSelectedLanguage(languages[0]) : setSelectedLanguage(languages[1]);
  }, [user]);


  const changeLanguage = async () => {
    const data = { culture: selectedLanguage.locale }
    showLoader();
      try {
        const response = await fetchPUTRequest('api/Profile/UpdateCulture', data);
        if (response?.data?.status === 'Success') {
          alertDialog({
            title: Translation.lang().w_language,
            desc: Translation.lang().w_changeLanguageSuccess,
            rightButtonFunction: () => {
              dispatch(logout())
              navigate('/login', { replace: true });
            }
          });
        }
      } catch (error) {
        alertDialog({
          title: Translation.lang().w_language,
          desc: Translation.lang().w_resetFailed,
        });
      }
      hideLoader();
  }

  return (
    <>
      <DetailNavBar label={Translation.lang().w_language} onClick={() => navigate(-1)} />
      {languages?.map((language, index) => (
        <>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', padding: '12px', alignItems: 'center' }}
            onClick={() => setSelectedLanguage(language)}
            key={index}
          >
            <Typography paragraph sx={{ margin: 0, fontSize: '15px' }}>
              {language.name}
            </Typography>
            {selectedLanguage?.name === language.name && (
              <IconButton sx={{ padding: 0 }}>
                <CheckIcon sx={{ color: '#038fd4' }} fontSize="small" />
              </IconButton>
            )}
          </Box>
          <Divider sx={{ background: '#e8e8e8', margin: '0 12px' }} />
        </>
      ))}
      <div style={{ padding: '0 20px', marginTop: '20px' }}>
        <CustomButton label={Translation.lang().w_save} onClick={changeLanguage}/>
      </div>
    </>
  );
};

export default LangaugePage;
