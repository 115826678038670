import React from 'react';
import { useNavigate } from 'react-router';
import { ArrowBackIos } from '@mui/icons-material';
import { Button, Divider, Stack, Typography } from '@mui/material';
import CustomIcon from '../../components/CustomIcon';
import Translation from '../../config/Translation';
import MomStorage from '../../config/MomStorage';
import DetailNavBar from '../../components/DetailNavBar';

const FAQPage = () => {
    const navigate = useNavigate();

    const getLoginUrl = () => {
        if (MomStorage.getLocale() == 'en') {
            return './faq/en/FAQLogin.html'
        }
        if (MomStorage.getLocale() == 'es') {
            return './faq/es/FAQLogin_ITN-ACT_ES.html'
        }
        return './faq/nl/FAQLogin.html'

    }
    const getMoreUrl = () => {
        if (MomStorage.getLocale() == 'en') {
            return './faq/en/FAQMore.html';
        }
        if (MomStorage.getLocale() == 'es') {
            return './faq/es/FAQMore_ITN-ACT_ES.html'
        } else {
            return './faq/nl/FAQMore.html'
        }
    }

    const FAQ_LIST = [
        {
            title: Translation.lang().w_login,
            type: "LOGIN",
            imageUrl: "assets/resources/LoginFAQ.png",
            detailUrl: getLoginUrl()
        },
        {
            title: Translation.lang().w_more,
            type: "MORE",
            imageUrl: "assets/resources/MoreFAQ.png",
            detailUrl: getMoreUrl()
        },
    ]

    const clickRow = (rowData) => {
        navigate("/faq-details", { state: rowData })
    }

    return (
        <>
            <DetailNavBar label={Translation.lang().w_faq} onClick={() => navigate(-1)} />
            {FAQ_LIST.map((rowData, index) =>
                <Stack key={index} onClick={() => clickRow(rowData)}>
                    {index === 0 ? null : <Divider color='#666666' />}
                    <Stack spacing={2} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }} style={{ padding: 12 }}>
                        <CustomIcon sx={{ height: 30 }} src={rowData.imageUrl} />
                        <Typography>
                            {rowData.title}
                        </Typography>
                    </Stack>
                </Stack>)
            }
        </>
    );
};

export default FAQPage;
