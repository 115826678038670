// MUI components
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const DetailNavBar = ({ label, onClick, bgColor="#123B6D", color="#ffffff" }) => (
    <Stack color={color} style={{ backgroundColor: bgColor , padding: 12 }} justifyContent={"start"} alignItems={"center"} direction={{ xs: 'row', sm: 'row' }}>
      <IconButton sx={{ padding: 0 }} onClick={onClick} > <ArrowBackIosIcon sx={{ color: color }}/></IconButton>
      <Typography style={{ flex: 1, textAlign: 'center', fontWeight: 400 }} variant='h5'>{label}</Typography>
    </Stack>
  )

export default DetailNavBar;