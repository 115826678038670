import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const CustomIcon = ({ sx, src }) => (
    <Box
        component="img"
        src={src}
        sx={{ cursor: 'pointer', ...sx }}
    />
);

CustomIcon.propTypes = {
    sx: PropTypes.object,
    src: PropTypes.string
};

export default CustomIcon;
