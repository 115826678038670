import React from 'react';

// hooks
import { useNavigate } from 'react-router-dom';

// MUI components
import { Button, Container, Box, Stack, Typography, Divider } from '@mui/material';

// Custom
import { NavBar } from '../../components/NavBar';
import CustomIcon from '../../components/CustomIcon';
import CustomNavBar from '../../components/CustomNavBar';

// utils
import Translation from '../../config/Translation';
import { SURVEYS_PAGE } from '../../utils/pageName';

// custom hooks
import useAlertDialog from '../../hooks/useAlertDialog';
import { useLoader } from '../../provider/LoaderContext';
import { fetchPOSTRequest } from '../../utils/services';

const Reminders = () => {

    const DAYSHORTNAMES = [
        Translation.lang().w_dayShort0,
        Translation.lang().w_dayShort1,
        Translation.lang().w_dayShort2,
        Translation.lang().w_dayShort3,
        Translation.lang().w_dayShort4,
        Translation.lang().w_dayShort5,
        Translation.lang().w_dayShort6,
    ];

    const navigate = useNavigate();
    const alertDialog = useAlertDialog();
    const { showLoader, hideLoader } = useLoader();

    const [reminderList, setReminderList] = React.useState([])

    const _getReminderList = async () => {
        showLoader()
        try {
            const response = await fetchPOSTRequest("api/TrackReminder/QueryEntries", { "status": 1 });
            if (response.status === 200) {
                setReminderList(response?.data)
            }
        } catch (error) {
            console.log("🚀 ~ file: Reminders.js:39 ~ const_getReminderList= ~ error:", error)
        }
        hideLoader()
    }

    const _editReminderPage = (rowData) => {
        navigate("/add-reminders", {
            state: {
                trackType: 6,
                reminder: rowData
            }
        })
    }
    const _goToAddReminderPage = () => {
        navigate("/add-reminders")
    }

    const _repeatText = (reminder) => {
        if (reminder.day0 && reminder.day1 && reminder.day2 &&
            reminder.day3 && reminder.day4 && reminder.day5 &&
            reminder.day6) {
            return Translation.lang().w_everyday;
        }
        if (!reminder.day0 && reminder.day1 && reminder.day2 &&
            reminder.day3 && reminder.day4 && reminder.day5 &&
            !reminder.day6) {
            return Translation.lang().w_weekdays;
        }
        if (reminder.day0 && !reminder.day1 && !reminder.day2 &&
            !reminder.day3 && !reminder.day4 && !reminder.day5 &&
            reminder.day6) {
            return Translation.lang().w_weekends;
        }
        if (!reminder.day0 && !reminder.day1 && !reminder.day2 &&
            !reminder.day3 && !reminder.day4 && !reminder.day5 &&
            !reminder.day6) {
            return Translation.lang().w_never;
        }
        const names = [];
        if (reminder.day1) names.push(DAYSHORTNAMES[1]);
        if (reminder.day2) names.push(DAYSHORTNAMES[2]);
        if (reminder.day3) names.push(DAYSHORTNAMES[3]);
        if (reminder.day4) names.push(DAYSHORTNAMES[4]);
        if (reminder.day5) names.push(DAYSHORTNAMES[5]);
        if (reminder.day6) names.push(DAYSHORTNAMES[6]);
        if (reminder.day0) names.push(DAYSHORTNAMES[0]);
        return names.join(' ');

    }

    React.useEffect(() => {
        _getReminderList()
    }, []);

    return (

        <div>
            <NavBar />
            <Box >
                <CustomNavBar
                    prevICon={true}
                    prevClick={() => navigate(`/${SURVEYS_PAGE}`)}
                    src={"assets/track/Medication_blue.png"}
                    title={Translation.lang().w_reminders}
                />

                <Container>
                    <Stack marginTop={2}>
                        <Typography variant="h6" fontWeight={600} style={{ color: "#000" }}>
                            {Translation.lang().w_medication_reminders}
                        </Typography>
                        <Typography style={{ color: "#000" }}>
                            {Translation.lang().w_medication_reminders_text1}
                        </Typography>
                    </Stack>
                    {
                        reminderList.map((rowData, index) =>
                            <Stack key={index} onClick={() => _editReminderPage(rowData)} >
                                <Stack alignItems={"center"} direction={{ xs: 'row', sm: 'row' }} style={{ padding: 12 }} justifyContent={"space-between"} >
                                    <Typography>
                                        {rowData?.localTime} {_repeatText(rowData)}
                                    </Typography>
                                    <CustomIcon sx={{ height: 20 }} src="assets/arrow_right.png" />
                                </Stack>
                                <Divider color='#666666' />
                            </Stack>)
                    }
                    <Button onClick={_goToAddReminderPage} style={{ marginTop: 20 }}>
                        {Translation.lang().w_med_addAReminder}
                    </Button>
                </Container>

            </Box>
        </div>
    )
}

export default Reminders