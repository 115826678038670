import React from 'react'
import { Container, Divider, Stack, Typography } from '@mui/material'
import { ResourcesNavBar } from '../../components/ResourcesNavBar'
import CustomIcon from '../../components/CustomIcon'
import { useNavigate } from 'react-router'
import Translation from '../../config/Translation'

const ResourcesPage = () => {

    const navigate = useNavigate();

    return <div>
        <ResourcesNavBar onClick={() => navigate(-1)} />
        <div>
            <Container maxWidth={"sm"}>
                <Typography style={{ margin: 20, color: 'grey' }} variant='h6'>
                    {Translation.lang().w_resources_tip}
                </Typography>
                <Stack direction={"row"} spacing={3}>
                    <Stack alignItems={"center"} onClick={() => navigate("/faq")}>
                        <CustomIcon src={"assets/resources/FAQResources.png"} />
                        <Typography style={{ marginTop: 20, color: 'grey' }}>
                            {Translation.lang().w_resources_faq}
                        </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                    <Stack alignItems={"center"} onClick={() => navigate("/study-instructions")} >
                        <CustomIcon src={"assets/resources/ContactResources.png"} />
                        <Typography style={{ marginTop: 20, color: 'grey' }}>
                            {Translation.lang().w_resources_contact}
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
        </div>
    </div>
}


export default ResourcesPage
