

export const config = {
  production: {
    apiUrl: process.env.REACT_APP_PRODUCTION_URL,
  },
  staging: {
    apiUrl: process.env.REACT_APP_STAGING_URL,
  },
  test: {
    apiUrl: process.env.REACT_APP_TEST_URL,
  },
  development: {
    apiUrl: process.env.REACT_APP_DEVELOPMENT_URL,
  },
};

