import { Box, CardContent } from '@mui/material';
import PropTypes from 'prop-types';


export const BorderCard = ({ children }) => (
    <Box style={{ borderRadius: 0, paddingTop: 20, paddingBottom: 20, borderWidth: 2, borderColor: "grey", borderStyle: 'solid' }} >
        <CardContent>
            {children}
        </CardContent>
    </Box>
);


BorderCard.propTypes = {
    children: PropTypes.node.isRequired,
};
