import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Button, Container, Paper, Stack } from '@mui/material';
import DetailNavBar from '../../components/DetailNavBar';
import { useLoader } from '../../provider/LoaderContext';
import { baseURL, fetchGETRequest } from '../../utils/services';
import CustomButton from '../../forms/button';

const StudyInstructionsDetailsPage = () => {
    const { showLoader, hideLoader, isLoading } = useLoader();
    const { state } = useLocation();
    const navigate = useNavigate();

    const pageUrl = baseURL + `WebApi/Media/WikiWithFont/${state?.id}`;

    const downloadPDF = async (attachments) => {
        showLoader();
        try {
            const response = await fetchGETRequest(`api/Wiki/GetAttachmentBase64String?attachmentId=${attachments?.attachmentId}`);
            const decodedData = atob(response?.data);
            const uint8Array = new Uint8Array(decodedData.length);
            for (let i = 0; i < decodedData.length; i++) {
                uint8Array[i] = decodedData.charCodeAt(i);
            }
            const blob = new Blob([uint8Array], { type: 'application/pdf' });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = attachments.attachmentName;
            downloadLink.click();
        } catch (error) {
            console.log("🚀 ~ file: StudyInstructionsDetailsPage.js:31 ~ downloadPDF ~ error:", error)

        }
        hideLoader();
    }

    const handleLoad = () => {
        hideLoader(false);
    };

    React.useEffect(() => {
        showLoader()
    }, []);

    return (
        <Paper>

            <DetailNavBar label={state?.subject} onClick={() => navigate(-1)} />
            <iframe
                title={state?.subject}
                src={pageUrl}
                width="100%"
                height="500px"
                onLoad={handleLoad}
                style={{ display: 'flex', border: 'none' }}
            ></iframe>
            <Stack alignItems={"center"} justifyContent={"center"} padding={2}>
                <CustomButton label={state?.subject} onClick={() => downloadPDF(state.attachments[0])} />
            </Stack>
        </Paper>
    );
};

export default StudyInstructionsDetailsPage;
