import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchGETRequest } from '../../utils/services';

// initial states
const initialState = {
  loading: false,
  appStatus: null,
};

const reducers = {};

export const fetchAppStatus = createAsyncThunk('status', async () => {
  const response = await fetchGETRequest('api/Profile/GetAppStatus', {});
  return response.data;
});

// creating home slice
const HomeSlice = createSlice({
  name: 'home',
  initialState,
  reducers,
  extraReducers: (builder) => {
    // Fetch Surveys List

    builder.addCase(fetchAppStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAppStatus.fulfilled, (state, action) => {
      if (action.payload) {
        state.appStatus = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(fetchAppStatus.rejected, (state, action) => {
      if (action.error) {
        state.loading = false;
        state.appStatus = null;
      }
    });
  },
});

// exporting actions
// export const {  } = HomeSlice.actions

export default HomeSlice.reducer;
